import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import fr from "./fr.json";
import manager from "./manager.json";

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  manager: {
    translation: manager,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "fr",
  compatibilityJSON: "v3",
  interpolation: {
    escapeValue: false,
  },
});
