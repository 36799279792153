export default function sortGalleryEfforts(efforts) {
  return efforts.sort((a, b) => {
    if (a.organization_id && !b.organization_id) return -1;
    if (!a.organization_id && b.organization_id) return 1;

    if (a.organization_id && b.organization_id) {
      if (a.variety === 6 && b.variety !== 6) return 1;
      if (a.variety !== 6 && b.variety === 6) return -1;

      if (
        (a.variety === 6 && b.variety === 6) ||
        (a.variety !== 6 && b.variety !== 6)
      ) {
        return new Date(b.start_date) - new Date(a.start_date);
      }
    }

    return new Date(b.start_date) - new Date(a.start_date);
  });
}
