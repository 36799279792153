import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Group,
  MantineProvider,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { GalleryEffortList, OrgGallery } from "@components/MicrositeGallery";
import { ImageSlider } from "@components/shared";
import sortGalleryEfforts from "@util/sortGalleryEfforts";
export default function GalleryContainer() {
  const [codeData, setCodeData] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const siteSettings = codeData ? codeData.settings.settings : null;
  const settingsCode = codeData ? codeData.settings_code : null;
  const theme = useMantineTheme();
  const matches = useMediaQuery("(min-width: 900px)");

  if (!id) return null;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/verify-code/${id}/`)
      .then(({ data }) => {
        setCodeData(data.response[0]);
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Title order={1} p="xl" align="center">
          {error}
        </Title>
      </div>
    );
  }

  if (!codeData) return null;

  const { logo_url: logo } = codeData;
  const isStockImage = logo.indexOf("mixer-default") > -1;
  const isOrgGallery = codeData?.settings?.organization_id ? true : false;
  const sortedEfforts = sortGalleryEfforts(codeData.campaign_efforts);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      defaultColorScheme="dark"
      theme={{
        fontFamily: theme.fontFamily,
        fontFamilyMonospace: theme.fontFamilyMonospace,
        headings: {
          ...theme.headings,
          fontFamily: theme.fontFamily,
        },
        colorScheme: "dark",
        // primaryColor: siteSettings ? siteSettings.color : theme.primaryColor,
        primaryColor:
          siteSettings && siteSettings.color
            ? theme.colors[siteSettings.color]
              ? siteSettings.color
              : theme.primaryColor
            : theme.primaryColor,
      }}
    >
      <Box
        style={(theme) => ({
          // background: theme.colors.dark[7],
          // display: "flex",
          // justifyContent: "center",
          // height: "100%",
          // alignItems: "center",
          padding: "1em",
          width: "95%",
          maxWidth: "1600px",
          margin: "0 auto",
        })}
      >
        {logo && !isStockImage && (
          <Group justify="center" mb="xl">
            <img
              src={logo}
              style={{
                width: "175px",
                maxHeight: "175px",
                objectFit: "contain",
              }}
            />
          </Group>
        )}
        <Box mb="lg" mt={isStockImage ? "lg" : "0"}>
          <Title align="center" order={3}>
            {codeData.entity_name}
          </Title>
          <Text align="center">
            Welcome to the {codeData.entity_name} Campaign Gallery!
          </Text>
          {isOrgGallery && codeData.live_effort_count > 0 && (
            <Text align="center">
              We have {codeData.participating_locations_count} location
              {codeData.participating_locations_count === 1 ? "" : "s"} waiting
              for you to interact with and win!
            </Text>
          )}
        </Box>
        {codeData.banner_images && codeData.banner_images.length > 0 && (
          <Box mb="lg">
            <ImageSlider
              sliderProps={{
                withControls: false,
                withIndicators: false,
                draggable: false,
              }}
              items={codeData.banner_images.map((m) => ({
                src: m.filename_url,
                external_link_url: m.external_link_url,
              }))}
              imageFormatter={(d) => (
                <img
                  src={d.src}
                  style={{
                    maxHeight: "300px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            />
          </Box>
        )}
        {codeData.location && (
          <Flex
            gap="xs"
            align="center"
            mb="xl"
            justify="center"
            direction={{ base: "column", sm: "row" }}
          >
            {siteSettings && siteSettings.stream_url && (
              <Group justify="center">
                <audio
                  controls
                  autoPlay={siteSettings.autoplay_stream === true}
                >
                  <source src={siteSettings.stream_url} />
                  Your browser doesn't support radio streaming
                </audio>
              </Group>
            )}
            {settingsCode && codeData.location && codeData.variety === 1 && (
              <Group justify="center">
                <Button component="a" href={`/user-login/${settingsCode}`}>
                  Member Login
                </Button>
              </Group>
            )}
          </Flex>
        )}
        {isOrgGallery ? (
          <div>
            <Box mt="xl">
              {codeData.live_effort_count === 0 ? (
                <Text align="center" fw={600}>
                  Check back soon for content!
                </Text>
              ) : (
                <OrgGallery orgId={codeData.settings.organization_id} />
              )}
            </Box>
          </div>
        ) : (
          <GalleryEffortList items={sortedEfforts} />
        )}
      </Box>
    </MantineProvider>
  );
}
