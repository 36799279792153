import React from "react";
import { Group } from "@mantine/core";
import { shallow } from "zustand/shallow";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import { CustomTooltip } from "@components/shared";
import useAppStore from "./contest-settings-store";

export default function Keywords({ beginDate, endDate, onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const counterFields = [
    {
      title: "Use Total",
      keyName: "keyword_use_total",
      tooltipText:
        "Use Total: How many times can any given customer enter a specific keyword for this contest? If your keyword is 'Torch' and Use Total is set to 1, every customer can enter Torch once for this contest. This setting applies to every keyword attached to this contest.",
    },
    {
      title: "Value",
      keyName: "keyword_value",
      unlimited: false,
      tooltipText:
        "Value: How many entries is each keyword worth for this contest? If Value is set to 1, each keyword successfully redeemed will grant the user 1 entry for this contest. This setting applies to every keyword attached to this contest.",
    },
    {
      title: "Total Entries Allowed",
      keyName: "total_entries_allowed",
      tooltipText:
        "Total Entries Allowed: How many total entries can a user earn by redeeming keywords? If this number is set to 10, a user can earn up to 10 total entries by redeeming keywords.",
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: settings.keywords[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={c.unlimited}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <CustomTooltip label={c.tooltipText}>
            <h3 style={{ margin: "0" }}>{c.title}</h3>
          </CustomTooltip>
        </Group>
      ))}
      <Intervals
        beginDate={beginDate}
        endDate={endDate}
        items={settings.keywords.intervals}
        title="Keyword Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
