import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import useAppStore from "./ride-challenge-store";

import { views } from "./RideChallengeDashboard";

export default function RideChallengeAlerts({ activeView }) {
  const {
    alerts,
    fetchAlerts,
    dismissAlerts,
    user,
    effortId,
    coords,
    entityInfo,
  } = useAppStore(
    (state) => ({
      coords: state.coords,
      alerts: state.alerts,
      entityInfo: state.entityInfo,

      fetchAlerts: state.fetchAlerts,
      dismissAlerts: state.dismissAlerts,
      user: state.user,
      effortId: state.effortId,
    }),
    shallow
  );

  const reqData = {
    ...coords,
    campaign_effort_id: effortId,
    location_id: entityInfo.location?.id,
    user_id: user.id,
  };

  useEffect(() => {
    findAlertsToFetch();
  }, [activeView]);

  useEffect(() => {
    findAlertsToDismiss();
  }, [activeView]);

  function findAlertsToFetch() {
    if (activeView === views.dashboard) {
      fetchAlerts({ ...reqData, context: getContext(activeView) });
    }
  }

  function findAlertsToDismiss() {
    let alertIds = [];
    if (activeView === views.milestones) {
      alertIds = alertIds.concat(
        alerts.filter((alert) => alert.milestone_id).map((m) => m.id)
      );
    }

    if (alertIds.length > 0) {
      dismissAlerts({ ...reqData, alert_ids: alertIds });
    }
  }
  return <></>;

  function getContext(activeView) {
    switch (activeView) {
      case views.milestones:
      // return "milestones";
      case views.contest:
      // return "contest";
      case views.history:
      // return "history";
      default:
        return ["dashboard", "milestone"];
    }
  }
}
