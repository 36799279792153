import React, { useState } from "react";
import {
  Button,
  Group,
  Image,
  Modal,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import styled from "styled-components";
import { IconHandClick } from "@tabler/icons-react";

import { size } from "@util/breakpoints";
import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";
import LinkDisplay from "./LinkDisplay";

const bgImageButtonStyle = {
  color: "#fff",
  textShadow: "1px 1px 1px #000",
  fontSize: "1.05em",
  background: "rgba(0, 0, 0, 0.85)",
  borderRadius: "8px",
};

const bgColorButtonStyle = {
  ...bgImageButtonStyle,
};

export default function IntroModal({ info }) {
  const [isOpen, setOpen] = useState(false);

  const name = info.title;
  const image = getPlacementImage(
    info.app_configuration,
    info.assets,
    placementDefs.featured
  );
  const appConfig = info.app_configuration;

  if (!appConfig || !appConfig.text) return null;

  const buttonStyle =
    appConfig &&
    appConfig.assets &&
    appConfig.assets.find((f) => f.placement === placementDefs.background)
      ? bgImageButtonStyle
      : bgColorButtonStyle;

  return (
    <StyledInfo>
      <Group justify="center">
        <Button
          onClick={() => setOpen(true)}
          // variant="subtle"
          variant="filled"
          color="dark"
          size="xs"
          radius="lg"
          leftSection={<IconHandClick size={16} />}
          // style={buttonStyle}
        >
          {appConfig.text.info_button || name}
        </Button>
      </Group>
      <Modal opened={isOpen} onClose={() => setOpen(false)}>
        {image && (
          <Group justify="center" mb="sm">
            <Image src={image} width="100%" />
          </Group>
        )}
        <Title align="center" mb="sm" order={5}>
          {appConfig.text.title}
        </Title>
        {appConfig.text.intro && (
          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: appConfig.text.intro }} />
          </TypographyStylesProvider>
        )}
        <LinkDisplay links={appConfig.links} />
      </Modal>
    </StyledInfo>
  );
}

const StyledInfo = styled.div`
  img {
    width: 135px;
    margin: 0 auto;
    display: block;
    cursor: pointer;

    @media (max-width: ${size.tablet}) {
      width: 100px;
    }
  }
`;
