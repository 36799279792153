import React, { useEffect } from "react";
import { Tabs } from "@mantine/core";
import axios from "axios";
import { shallow } from "zustand/shallow";

import Keywords from "./Keywords";
import Checkins from "./Checkins";
import GeneralSettings from "./GeneralSettings";
import InitialEntry from "./InitialEntry";
import WinnerRules from "./WinnerRules";

import useAppStore from "./contest-settings-store";

const tabDefs = {
  general: "general",
  keywords: "keywords",
  initial_entry: "initial entry",
  check_ins: "check-ins",
  winner_rules: "winner rules",
};

export default function ContestSettings({
  beginDate,
  canEditWinnerRules,
  contestId,
  endDate,
  fetchContest,
  wizardSettings,
  contestVariety = null,
}) {
  const { settings, setSettings, reset } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
      reset: state.reset,
    }),
    shallow
  );

  useEffect(() => {
    reset();
    fetchSettings();

    return () => {
      reset();
    };
  }, [contestId]);

  function fetchSettings() {
    axios
      .get(`/contests/${contestId}/settings/`)
      .then(({ data }) => {
        setSettings({
          ...settings,
          ...data.response[0].settings,
          contestDates: {
            begin: beginDate,
            end: endDate,
          },
        });
      })
      .catch((err) => {
        setSettings({});
      });
  }

  function updateSettings(newSettings) {
    const req = { contest_id: contestId, settings: newSettings };

    axios
      .post(`/contests/${contestId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchContest())
      .catch((err) => {
        console.log(err);
      });
  }

  function onUpdate(newSettings) {
    updateSettings({ ...settings, ...newSettings });
  }

  return (
    <div>
      <Tabs
        onChange={(e) => onUpdate({ activeTab: e })}
        value={settings.activeTab}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.keywords}>Keywords</Tabs.Tab>
          <Tabs.Tab value={tabDefs.initial_entry}>Initial Entry</Tabs.Tab>
          <Tabs.Tab value={tabDefs.check_ins}>Check-Ins</Tabs.Tab>
          <Tabs.Tab value={tabDefs.winner_rules}>Winner Rules</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings
            onUpdate={(e) => onUpdate({ ...e })}
            contestVariety={contestVariety}
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.keywords}>
          <Keywords
            beginDate={beginDate}
            endDate={endDate}
            onUpdate={(e) =>
              onUpdate({ keywords: { ...settings.keywords, ...e } })
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.initial_entry}>
          <InitialEntry
            onUpdate={(e) =>
              onUpdate({ initial_entry: { ...settings.initial_entry, ...e } })
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.check_ins}>
          <Checkins
            beginDate={beginDate}
            endDate={endDate}
            onUpdate={(e) =>
              onUpdate({ check_ins: { ...settings.check_ins, ...e } })
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.winner_rules}>
          <WinnerRules
            canEditWinnerRules={canEditWinnerRules}
            onUpdate={(e) =>
              onUpdate({ winner_rules: { ...settings.winner_rules, ...e } })
            }
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
