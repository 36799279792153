import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Card, Text, Box, Button, Flex, NumberFormatter } from "@mantine/core";
import { motion, AnimatePresence } from "framer-motion";

import { OdometerForm } from "./VehicleVerificationView";
import DealerVerification from "./DealerVerification";
import { VehicleImage } from "@components/RideChallenge";
import OdometerValueMismatch from "./OdometerValueMismatch";
import DealerWarnings from "./DealerWarnings";
import DealerMode from "./DealerMode";
export default function VehicleManagement({
  vehicles = [],
  additionalReqData,
  fetchData,
  verificationCode,
  codeVerificationData,
  setUnverifiedView,
  setDashboardView,
  allowMileageEntry = true,
}) {
  return (
    <div>
      {!allowMileageEntry && (
        <Text fw={600} c="yellow" align="center" mb="lg">
          You've reached the maximum amount of miles.
        </Text>
      )}
      {vehicles.map((m) => (
        <Box key={m.id} mb="lg">
          <VehicleItem
            info={m}
            additionalReqData={additionalReqData}
            setDashboardView={setDashboardView}
            fetchData={() => {
              fetchData();
            }}
            verificationCode={verificationCode}
            codeVerificationData={codeVerificationData}
            setUnverifiedView={setUnverifiedView}
            allowMileageEntry={allowMileageEntry}
          />
        </Box>
      ))}
    </div>
  );
}

const VehicleItem = ({
  info,
  fetchData,
  additionalReqData,
  verificationCode,
  codeVerificationData,
  setUnverifiedView,
  setDashboardView,
  allowMileageEntry = true,
}) => {
  const [isOpen, setOpen] = useState(false);

  const odometerRolled = info.odometer_rolled;

  const currentOdometerReading = odometerRolled
    ? info.current_odometer_reading > 1000000
      ? info.current_odometer_reading - 1000000
      : info.current_odometer_reading
    : info.current_odometer_reading;

  return (
    <Card
      style={{
        background: "var(--mantine-color-dark-9)",
        borderColor: "var(--mantine-color-dark-8)",
      }}
    >
      <Flex align="start" gap="md">
        <div>
          <VehicleImage
            userVehicleId={info.id}
            onSuccess={fetchData}
            height={60}
            width={60}
            url={
              info.assets.length
                ? info.assets[info.assets.length - 1].filename_url
                : null
            }
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <Text c="white" size="md" fw={600}>
            {info.year} {info.make} {info.model}{" "}
            {info.series && <>{info.series}</>}
          </Text>
          {info.current_odometer_reading !== undefined && (
            <Text c="dimmed" size="sm">
              Odometer:{" "}
              <NumberFormatter
                thousandSeparator
                value={currentOdometerReading}
              />{" "}
              {info.use_kilometers ? "km" : "mi"}
            </Text>
          )}
          <Text c="dimmed" size="sm">
            <NumberFormatter thousandSeparator value={info.odometer_total} /> mi
            verified
          </Text>
          {!isOpen && info.has_unverified_readings && allowMileageEntry && (
            <Text c="yellow" size="sm" mt="sm" fw={600}>
              This motorcycle has an unverified reading. Verify the reading
              before logging more {info.use_kilometers ? "kilometers" : "miles"}
              .
            </Text>
          )}
        </div>
      </Flex>
      <Box mt={allowMileageEntry ? 0 : "sm"}>
        {isOpen ? (
          <motion.div
            initial={{ opacity: 0, y: -100, scale: 0.5 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            // exit={{ opacity: 0, y: 200, scale: 1.2 }}
            transition={{ duration: 0.6, type: "spring" }}
          >
            <AddNewReading
              fetchData={() => {
                setDashboardView();
                setOpen(false);
              }}
              odometerRolled={odometerRolled}
              codeVerificationData={codeVerificationData}
              currentOdometer={info.current_odometer_reading}
              reqData={{
                ...additionalReqData,
                make: info.make,
                model: info.model,
                series: info.series,
                year: info.year,
                vin: info.vin,
                user_vehicle_id: info.id,
              }}
              verificationCode={verificationCode}
            />
            <Flex justify="center" mt="lg">
              <Button
                size="xs"
                color="gray"
                variant="subtle"
                onClick={() => {
                  setOpen(false);
                  fetchData();
                }}
              >
                close
              </Button>
            </Flex>
          </motion.div>
        ) : (
          <div>
            {allowMileageEntry && (
              <>
                {info.has_unverified_readings ? (
                  <Button
                    fullWidth
                    radius="xl"
                    size="sm"
                    mt="xs"
                    onClick={setUnverifiedView}
                    color="yellow"
                  >
                    Verify Reading
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    radius="xl"
                    size="sm"
                    mt="xs"
                    onClick={() => setOpen(true)}
                  >
                    Log {info.use_kilometers ? "Kilometers" : "Miles"}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </Card>
  );
};

const AddNewReading = ({
  reqData,
  fetchData,
  verificationCode,
  codeVerificationData,
  currentOdometer,
  odometerRolled,
}) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [unverifiedReading, setUnverifiedReading] = useState(null);
  const [dealerSubmitting, setDealerSubmitting] = useState(false);
  const [userSubmittedReading, setUserSubmittedReading] = useState("");

  useEffect(() => {
    if (verificationCode) {
      setDealerCodeData(verificationCode);
    }
  }, []);

  const valueIsLower = odometerRolled
    ? value && parseInt(value) <= parseInt(currentOdometer - 1000000)
    : value && parseInt(value) <= parseInt(currentOdometer);
  const formattedValue = odometerRolled ? parseInt(value) + 1000000 : value;

  function onSubmit() {
    const req = {
      ...reqData,
      odometer_value: formattedValue,
    };
    setUserSubmittedReading(value.replace(/^0+/, ""));

    setLoading(true);

    axios
      .post(`/ride-challenge/submit-additional-reading/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        if (verificationCode) {
          onDealerSubmit(res);
        } else {
          setLoading(false);
          setUnverifiedReading(res);
          setValue("");
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onDealerSubmit(unverifiedResponseData = null) {
    setDealerSubmitting(true);

    const formattedValue = odometerRolled ? parseInt(value) + 1000000 : value;

    const req = {
      ...reqData,
      verifier_remote_code_id: dealerCodeData.id,
      odometer_value: formattedValue,
      ride_challenge_data_point_id:
        unverifiedReading?.ride_challenge_data_point_id ||
        unverifiedResponseData?.ride_challenge_data_point_id,
    };

    axios
      .post(`/ride-challenge/verify-reading/`, req)
      .then(() => {
        toast.success("Verified!");
        setDealerSubmitting(false);
        fetchData();
        // fetchUser();
      })
      .catch((err) => {
        toast.error(err);
        setDealerSubmitting(false);
      });
  }

  if (dealerCodeData) {
    return (
      <Box mt="lg">
        <DealerMode dealerCodeData={dealerCodeData}>
          <DealerWarnings
            reqData={{
              campaign_effort_id: reqData.campaign_effort_id,
              user_id: reqData.user_id,
            }}
          />
          <Flex justify="center" mt="lg">
            <OdometerForm value={value} onChange={(e) => setValue(e)} />
          </Flex>
          {unverifiedReading && (
            <OdometerValueMismatch
              originalValue={userSubmittedReading}
              newValue={value}
            />
          )}
          <Flex justify="center" mt="lg">
            <Button
              onClick={() => {
                if (verificationCode) {
                  onSubmit();
                } else {
                  onDealerSubmit();
                }
              }}
              disabled={!value || valueIsLower}
              loading={dealerSubmitting}
            >
              Submit Reading
            </Button>
          </Flex>
        </DealerMode>
      </Box>
    );
  }

  if (unverifiedReading) {
    return (
      <Box mt="lg">
        <DealerVerification
          codeVerificationData={codeVerificationData}
          setDealerCodeData={(e) => setDealerCodeData(e)}
        />
      </Box>
    );
  }

  return (
    <div>
      <Flex justify="center" mt="lg">
        <OdometerForm value={value} onChange={(e) => setValue(e)} />
      </Flex>
      <Flex justify="center" mt="lg">
        <Button
          onClick={onSubmit}
          disabled={!value || valueIsLower}
          loading={loading}
        >
          Submit
        </Button>
      </Flex>
    </div>
  );
};
