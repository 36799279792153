import React from "react";
import styled from "styled-components";

import logo from "@assets/images/lets-ride-logo.png";

export default function RideChallengeHeader() {
  return <StyledLogo src={logo} />;
}

const StyledLogo = styled.img`
  max-width: 300px;
  margin: 0 auto 0 auto;
  display: block;
`;
