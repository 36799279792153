import axios from "axios";
import React, { useState, useEffect } from "react";
import { Box, Flex, Tabs, Title, Card } from "@mantine/core";
import { useParams, useMatch, Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { AssetManagement, LogoUpload } from "@components/Asset";
import { CampaignManagement } from "@components/Campaign";
import { ContestManagement } from "@components/Contest";
import { EffortManagement } from "@components/Effort";
import { KeywordManagement } from "@components/Keyword";
import { LocationAssociate } from "@components/Location";
import { LootLockerManagement } from "@components/LootLocker";
import { ManagerManagement } from "@components/Manager";
import { OrganizationForm } from "@components/Organization";
import { PrizePoolManagement } from "@components/PrizePool";
import {
  ErrorView,
  EntitySettingsCode,
  PageTitle,
  QrCodeGenerator,
  RequestApiConnectionButton,
  Subnav,
} from "@components/shared";
import { EmailServerCreate } from "@components/EmailServer";
import { MessageList } from "@components/Message";
import {
  BulkCommunicationManagement,
  BulkCommunicationAllowToggle,
} from "@components/BulkCommunication";
import { EmailAppConfig } from "@components/AppConfig";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function OrganizationDetail({ isProfile }) {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const adminInfo = useSelector((state) => state.admin);
  const isAdmin = adminInfo ? true : false;

  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/organizations/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const postmarkServerId =
    info.postmark_servers && JSON.stringify(info.postmark_servers) !== "{}"
      ? Object.keys(info.postmark_servers)[0]
      : null;

  const links = [
    { to: `/organizations/${id}`, text: "Detail" },
    { to: `/organizations/${id}/locations`, text: "Locations" },
    { to: `/organizations/${id}/managers`, text: "Managers" },
    { to: `/organizations/${id}/campaigns`, text: "Campaigns" },
    { to: `/organizations/${id}/efforts`, text: "Efforts" },
    { to: `/organizations/${id}/contests`, text: "Contests" },
    { to: `/organizations/${id}/keywords`, text: "Keywords" },
    { to: `/organizations/${id}/look-and-feel`, text: "Look & Feel" },
    { to: `/organizations/${id}/loot-locker`, text: "Loot Locker" },
    { to: `/organizations/${id}/asset-library`, text: "Asset Library" },
    // {
    //   to: `/organizations/${id}/bulk-communication`,
    //   text: "Bulk Communication",
    // },
    { to: `/postmark/${postmarkServerId}`, text: "Email" },
    // { to: `/organizations/${id}/email`, text: "Email" },
    // { to: `/organizations/${id}/prize-pool`, text: "Prize Pool" },
    // { to: `/organizations/${id}/look-and-feel`, text: "Look & Feel" },
  ]
    .filter((f) =>
      !postmarkServerId
        ? !["Email", "Bulk Communication", "Look & Feel"].includes(f.text)
        : true
    )
    .filter((f) =>
      isProfile
        ? ![
            "Asset Library",
            "Bulk Communication",
            "Campaigns",
            "Contests",
            "Efforts",
            "Email",
            "Keywords",
            "Locations",
            "Locations",
            "Loot Locker",
            "Managers",
            "Prize Pool",
          ].includes(f.text)
        : true
    );

  return (
    <div>
      {info.third_party_ident && !adminInfo && (
        <Box mb="lg">
          <RequestApiConnectionButton organizationId={id} />
        </Box>
      )}
      <Flex mb="lg" gap="lg">
        <LogoUpload
          organizationId={id}
          onSuccess={fetchData}
          url={info.logo_url}
        />
        <PageTitle
          title={info.name}
          subtitle={<React.Fragment>Organization</React.Fragment>}
          icon={entityIcon.organization()}
          iconColor={entityColor.organization}
          style={{ marginBottom: "0" }}
        />
      </Flex>
      {links.length > 1 && (
        <Subnav buttonColor={entityColor.organization} links={links} />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Card>
                <OrganizationForm
                  address1={info.address_1}
                  address2={info.address_2}
                  allowCampaignCreate={info.allow_campaign_create}
                  city={info.city}
                  country={info.country}
                  id={id}
                  isProfile={isProfile}
                  keywordCode={info.keyword_code}
                  manageLocations={info.manage_locations}
                  name={info.name}
                  onSuccess={fetchData}
                  otpDisplayName={info.otp_display_name}
                  state={info.state_province}
                  timezone={info.timezone}
                  thirdPartyIdent={info.third_party_ident}
                  zip={info.zip}
                />
              </Card>
              {!postmarkServerId && !isProfile && (
                <EmailServerCreate fetchData={fetchData} organizationId={id} />
              )}
              {isAdmin && (
                <Card mt="lg">
                  <BulkCommunicationAllowToggle
                    fetchData={fetchData}
                    organizationId={id}
                    value={info.allow_email_marketing}
                    disabled={postmarkServerId ? false : true}
                  />
                </Card>
              )}
              {info.microsite_gallery_code && (
                <>
                  <Title mt="xl" mb="xs" order={4}>
                    Gallery QR Code
                  </Title>
                  <Card>
                    <QrCodeGenerator
                      text={`${import.meta.env.VITE_DOMAIN}/gallery/${
                        info.microsite_gallery_code
                      }`}
                    />
                  </Card>
                </>
              )}
            </React.Fragment>
          }
        />
        {!isProfile && [
          <Route
            path="campaigns"
            key="campaigns"
            element={
              <div>
                <CampaignManagement organizationId={id} />
              </div>
            }
          />,
          <Route
            path="contests"
            key="contests"
            element={
              <div>
                <ContestManagement organizationId={id} />
              </div>
            }
          />,
          <Route
            path="managers"
            key="managers"
            element={
              <div>
                <ManagerManagement organizationId={id} />
              </div>
            }
          />,
          <Route
            path="messages"
            key="messages"
            element={
              <div>
                <MessageList organizationId={id} />
              </div>
            }
          />,
          <Route
            path="keywords"
            key="keywords"
            element={
              <div>
                <KeywordManagement organizationId={id} />
              </div>
            }
          />,
          <Route
            path="efforts"
            key="efforts"
            element={
              <React.Fragment>
                <EffortManagement organizationId={id} showCreate={false} />
              </React.Fragment>
            }
          />,
          <Route
            path="prize-pool"
            key="prize-pool"
            element={
              <div>
                <PrizePoolManagement organizationId={id} />
              </div>
            }
          />,
          <Route
            path="locations"
            key="locations"
            element={
              <div>
                <LocationAssociate
                  organizationIds={[id]}
                  disabledIds={info.active_location_ids}
                  fetchData={() => fetchData()}
                  bulkData={{
                    show_location_variety_filter: true,
                    download_request_data: {
                      context: "org_add_locations",
                      organization_id: id,
                    },
                    upload_request_data: {
                      organization_id: id,
                      context: "add_org_loc",
                    },
                  }}
                />
              </div>
            }
          />,
        ]}
        {/* {postmarkServerId && [
          <Route
            path="bulk-communication"
            element={
              <div>
                <BulkCommunicationManagement orgId={id} />
              </div>
            }
          />,
        ]} */}
        {/* <Route
          path="assets"
          element={
            <React.Fragment>
              <AssetManagement organizationId={id} title="Uploads" />
            </React.Fragment>
          }
        /> */}
        <Route
          path="asset-library"
          element={
            <React.Fragment>
              <AssetManagement organizationId={id} library />
            </React.Fragment>
          }
        />
        <Route
          path="loot-locker"
          element={
            <div>
              <LootLockerManagement orgId={id} />
            </div>
          }
        />
        <Route
          path="look-and-feel"
          element={
            <React.Fragment>
              <Tabs variant="outline" defaultValue="mixer" keepMounted={false}>
                <Tabs.List mr="xs">
                  <Tabs.Tab value="mixer">Mixer Settings</Tabs.Tab>
                  <Tabs.Tab value="email">Email Settings</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="mixer">
                  <EntitySettingsCode organizationId={id} />
                </Tabs.Panel>
                <Tabs.Panel value="email">
                  <EmailAppConfig
                    orgId={id}
                    name={info.name}
                    color={
                      info.settings_code &&
                      info.settings_code.settings &&
                      info.settings_code.settings.settings
                        ? info.settings_code.settings.settings.color
                        : "blue"
                    }
                  />
                </Tabs.Panel>
              </Tabs>
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}
