import React from "react";
import { Radio, Switch, Title } from "@mantine/core";

const languageOptions = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
];

export default function ConfigSettings({
  locationId,
  settings,
  onChange,
  isRideChallenge,
}) {
  return (
    <div>
      {!isRideChallenge && (
        <>
          <Title mb="sm" order={4}>
            Ask for preferred location
          </Title>
          <Switch
            disabled={locationId ? true : false}
            checked={settings.preferred_location}
            label={settings.preferred_location ? "On" : "Off"}
            onChange={() =>
              onChange({
                ...settings,
                preferred_location: !settings.preferred_location,
              })
            }
          />
        </>
      )}
      {/* <Title order={4} mt="lg">
        Ask for Geolocation
      </Title>
      <Switch
        disabled={settings.location_locked}
        checked={settings.geolocation_prompt}
        label={settings.geolocation_prompt ? "On" : "Off"}
        onChange={() =>
          onChange({
            ...settings,
            geolocation_prompt: !settings.geolocation_prompt,
          })
        }
      /> */}
      <Title order={4} mt="lg" mb="sm">
        Lock to location
      </Title>
      <Switch
        checked={settings.location_locked}
        label={settings.location_locked ? "On" : "Off"}
        onChange={() =>
          onChange({
            ...settings,
            geolocation_prompt:
              settings.geolocation_prompt === false
                ? true
                : settings.geolocation_prompt,
            location_locked: !settings.location_locked,
          })
        }
      />
      {true && (
        <>
          <Title mt="lg" mb="sm" order={4}>
            Language
          </Title>
          <Radio.Group
            value={settings.language}
            name="language"
            onChange={(lang) =>
              onChange({
                ...settings,
                language: lang,
              })
            }
          >
            {languageOptions.map((m, i) => (
              <Radio mb="xs" label={m.label} value={m.value} key={i} />
            ))}
          </Radio.Group>
        </>
      )}
    </div>
  );
}
