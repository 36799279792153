import React, { useEffect } from "react";
import { Divider, Space, Tabs, Text } from "@mantine/core";
import axios from "axios";
import { shallow } from "zustand/shallow";

import GeneralSettings from "./GeneralSettings";
import RideChallengeSettings from "./RideChallengeSettings";

import useAppStore from "./effort-settings-store";
import { rideChallengeSettings } from "@components/RideChallenge/helpers";

export default function EffortSettings({
  effortId,
  fetchEffort,
  isRideChallenge,
}) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/efforts/${effortId}/settings/`)
      .then(({ data }) => {
        if (isRideChallenge) {
          setSettings({
            ...settings,
            ...rideChallengeSettings,
            ...data.response[0].settings,
          });
        } else {
          setSettings({ ...settings, ...data.response[0].settings });
        }
      })
      .catch((err) => {
        setSettings({});
      });
  }

  function updateSettings(newSettings) {
    const req = { campaign_effort_id: effortId, settings: newSettings };

    axios
      .post(`/efforts/${effortId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchEffort())
      .catch((err) => {});
  }

  function onUpdate(newSettings) {
    setSettings({ ...settings, ...newSettings });
    updateSettings({ ...settings, ...newSettings });
  }

  return (
    <div>
      <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
      {isRideChallenge && (
        <div>
          <Divider mt="lg" mb="lg" />
          <RideChallengeSettings onUpdate={(e) => onUpdate({ ...e })} />
        </div>
      )}
      <Text size="xs" c="dimmed" mt="lg">
        *changes saved automatically
      </Text>
    </div>
  );
}
