import React from "react";
import { Text, Title, Flex, Box, NumberFormatter } from "@mantine/core";
import styled from "styled-components";

export default function RevBreakdown({
  info = {},
  isGuide = false,
  children,
  isWinner = false,
}) {
  const equationItems = info
    ? [
        { value: info.total_tier_miles, text: "Total Miles" },
        { operator: "+" },
        {
          value: info.readings_engagements_tier_rev,
          text: "Odometer Rev",
          calculation: `${info.readings_engagements_tier_count} reading`,
        },
        { operator: "+" },
        {
          value: info.keyword_engagements_tier_rev,
          text: "Keywords Rev",
          calculation: `${info.keyword_engagements_tier_count} claim`,
        },
        { operator: "+" },
        {
          value: info.locations_tier_rev,
          text: "Locations Rev",
          calculation: `${info.locations_tier_count} location`,
        },
        { operator: "=" },
        {
          value: info.tier_rev_score,
          text: "Total Tier Rev",
        },
      ]
    : [];

  const headers = equationItems.map((m) => ({
    text: m.text,
  }));

  if (isGuide) {
    return (
      <Flex gap="xs" align="flex-start" mb="md">
        {headers.map((item, i) => (
          <StyledBlock key={i}>
            <Text size="xs" align="center" key={i}>
              {item.text}
            </Text>
          </StyledBlock>
        ))}
      </Flex>
    );
  }
  return (
    <StyledWrapper>
      <Flex gap="xs" align="flex-start">
        {equationItems.map((item, i) => {
          if (item.operator) {
            return (
              <StyledBlock key={i}>
                <Text align="center" key={i}>
                  {item.operator}
                </Text>
              </StyledBlock>
            );
          }
          return (
            <StyledBlock key={i}>
              <Title order={2} align="center">
                <NumberFormatter thousandSeparator value={item.value} />
              </Title>
              {/* <Text size="sm" align="center">
                {item.text}
              </Text> */}
              {item.calculation && (
                <Text size="xs" align="center">
                  ({item.calculation})
                </Text>
              )}
            </StyledBlock>
          );
        })}
        <Box>
          <Flex direction="column">
            <Text size="sm" fw={600}>
              {info.full_name}
            </Text>
            <Text size="sm" c="dimmed">
              {info.mobile_phone}
            </Text>
            <Text size="sm" c="dimmed">
              {info.email}
            </Text>
          </Flex>
          {children && <Box mt="xs">{children}</Box>}
        </Box>
      </Flex>
    </StyledWrapper>
  );
}

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  width: 100px;
  text-align: center;
`;

const StyledWrapper = styled.div`
  margin-bottom: 0.25em;
`;
