import React, { useEffect } from "react";
import { Button, Tabs } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { shallow } from "zustand/shallow";

import GeneralSettings from "./GeneralSettings";
import TextSettings from "./TextSettings";
import Intervals from "./Intervals";

import useAppStore from "./keyword-settings-store";

const tabDefs = {
  general: "general",
  text: "text",
  intervals: "intervals",
};

export default function KeywordSettings({
  beginDate,
  canEditWinnerRules,
  keywordId,
  endDate,
  fetchContest,
  wizardSettings = {},
}) {
  const queryClient = useQueryClient();
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/keywords/${keywordId}/settings/`)
      .then(({ data }) => {
        setSettings({
          ...settings,
          ...data.response[0].settings,
          contestDates: {
            begin: beginDate,
            end: endDate,
          },
        });
      })
      .catch((err) => {
        setSettings({});
      });
  }

  function updateSettings(newSettings) {
    const req = {
      contest_id: keywordId,
      settings: newSettings ? newSettings : settings,
    };

    axios
      .post(`/keywords/${keywordId}/settings/`, req)
      .then(() => {
        queryClient.invalidateQueries([`keywords${keywordId}`]);
      })
      .then(() => {
        fetchSettings();
        if (!newSettings) {
          toast.success("Updated!");
        }
      })
      .then(() => fetchContest())
      .catch((err) => {});
  }

  function onUpdate(newSettings, updateAfter = true) {
    setSettings({ ...settings, ...newSettings });
    if (updateAfter) {
      updateSettings({ ...settings, ...newSettings });
    }
  }

  return (
    <div>
      <Tabs
        onChange={(e) => onUpdate({ activeTab: e })}
        value={settings.activeTab}
      >
        <Tabs.List mb="sm">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.text}>Text</Tabs.Tab>
          <Tabs.Tab value={tabDefs.intervals}>Intervals</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.text}>
          <TextSettings onUpdate={(e) => onUpdate({ ...e }, false)} />
          <Button mt="sm" fullWidth onClick={() => updateSettings()}>
            Save
          </Button>
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.intervals}>
          <Intervals
            dates={{
              begin: beginDate,
              end: endDate,
            }}
            onChange={(newIntervals) => onUpdate({ intervals: newIntervals })}
            items={settings.intervals}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
