export const effortVariety = {
  sweepstakes: 1,
  survey: 2,
};

export const effortRecurringEngagement = {
  true: 1,
  false: 2,
};

export const effortAwardType = {
  contest_entries: 1,
  points: 2,
  entries_and_points: 3,
  tiered: 4,
};

export const effortPointSettings = {
  value: 0,
};

export const effortWizardSettings = {
  activate_keyword_on_create: false,
  allow_checkin: false,
  allow_geospot: false,
  allow_location_checkin: false,
  contest_required: false,
  end_required: true,
  featured_image_required: true,
  keyword_for_points: false,
  keyword_required: false,
  keyword_sms_required: false,
  locations_required: false,
  look_and_feel_required: false,
  member_only: false,
  microsite: false,
  point_award_required: false,
  recurring: false,
  registration_method: null,
  return_points_keyword: false,
  show_assets: true,
  show_award_type: false,
  show_awards: false,
  show_builder: false,
  show_csv: true,
  show_geospots: false,
  show_interaction_type: false,
  show_keywords: false,
  show_look_feel: false,
  show_quick_settings: true,
  show_settings: false,
  start_required: true,
  use_checkins: false,
  use_keywords: false,
};

export const defaultEffortConfig = {
  title: "",
  assets: [],
  fields: [],
  description: "",
};
