import React from "react";
import { Switch } from "@mantine/core";
import { shallow } from "zustand/shallow";

import useAppStore from "./contest-settings-store";

export default function WinnerRules({ canEditWinnerRules = true, onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  return (
    <div>
      <Switch
        disabled={!canEditWinnerRules}
        mt="lg"
        label="Prevent prize winners and finalists from winning other prizes"
        checked={settings.winner_rules.one_prize_per_winner}
        onChange={() =>
          onUpdate({
            one_prize_per_winner: !settings.winner_rules.one_prize_per_winner,
          })
        }
      />
    </div>
  );
}
