import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Switch, Title } from "@mantine/core";
import toast from "react-hot-toast";

export default function EntityConfigSettings({
  effortId = null,
  usePublicDashboard = false,
}) {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    use_public_dashboard: false,
  });

  useEffect(() => {
    setFormValues({
      ...formValues,
      use_public_dashboard: usePublicDashboard,
    });
  }, []);

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
      campaign_effort_id: effortId,
    };

    axios
      .post(`/update-settings/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Saved");
        onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Title order={4} mb="xs">
        Public Dashboard
      </Title>
      <Switch
        label="Use Public Dashboard"
        size="sm"
        mb="md"
        onChange={(e) =>
          setFormValues({
            ...formValues,
            use_public_dashboard: !formValues.use_public_dashboard,
          })
        }
        checked={formValues.use_public_dashboard === true}
      />
      <Button loading={loading} mt="sm" size="sm" onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
}
