import React, { useState, useEffect } from "react";
import { Button, Group, Text, TextInput } from "@mantine/core";
import { IconMapSearch } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import LocationSearch from "./LocationSearch";
import ViewTitle from "./ViewTitle";
import { iconSize } from "./helpers";
import useAppStore from "./ride-challenge-store";

export default function UserLocation() {
  const [manualSearch, setManualSearch] = useState(false);
  const [locating, setLocating] = useState(false);
  const [zip, setZipValue] = useState("");
  const [error, setError] = useState(false);
  const [showZip, setShowZip] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const {
    appConfig,
    coordsNoLocation,
    coords,
    entityInfo,
    location,
    setZip,
    setLocation,
    setCoordsNoLocation,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      coordsNoLocation: state.coordsNoLocation,
      coords: state.coords,
      entityInfo: state.entityInfo,
      location: state.location,
      setCoordsNoLocation: state.setCoordsNoLocation,
      setLocation: state.setLocation,
      setZip: state.setZip,
    }),
    shallow
  );

  useEffect(() => {
    if (!coords) return showManualSearch();

    if (coordsNoLocation) return showManualSearch();

    if (coords && !location) {
      showManualSearch();
    }
  }, [JSON.stringify(coords), coordsNoLocation]);

  function onZipSubmit() {
    if (zip.length >= 5 && zip.length <= 7) {
      setCoordsNoLocation(false);
      setZip(zip);
    }
  }

  function showManualSearch() {
    setLocating(false);
    setManualSearch(true);
  }

  const { t } = useTranslation();

  return (
    <div>
      <ViewTitle
        title={t("location_title")}
        subtitle={
          appConfig && appConfig.text && appConfig.text.location_question
            ? appConfig.text.location_question
            : t("location_subtitle")
        }
        icon={<IconMapSearch size={iconSize} />}
      />
      {locating && (
        <Text c="white" align="center">
          {t("locating_text")}
        </Text>
      )}
      {manualSearch && !locating && (
        <React.Fragment>
          {!showZip && !showSearch && (
            <Group grow>
              <Button size="xl" onClick={() => setShowSearch(true)}>
                {t("yes")}
              </Button>
              <Button
                size="xl"
                variant="light"
                color="red"
                onClick={() => setShowZip(true)}
              >
                {t("no")}
              </Button>
            </Group>
          )}
          {showZip && (
            <React.Fragment>
              <TextInput
                value={zip}
                placeholder={t("zip_code_placeholder")}
                onChange={(e) => setZipValue(e.target.value)}
                size="lg"
              />
              <Button
                fullWidth
                mt="sm"
                size="lg"
                onClick={onZipSubmit}
                disabled={zip.length >= 5 && zip.length <= 7 ? false : true}
              >
                {t("submit")}
              </Button>
              <OtherButton
                onClick={() => {
                  setShowZip(false);
                  setShowSearch(true);
                }}
                text={t("search_by_name_button")}
              />
            </React.Fragment>
          )}
          {showSearch && (
            <React.Fragment>
              <LocationSearch
                onSelect={(loc) => {
                  setCoordsNoLocation(false);
                  setLocation(loc);
                }}
              />
              <OtherButton
                onClick={() => {
                  setShowZip(true);
                  setShowSearch(false);
                }}
                text={t("search_by_zip_button")}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export const OtherButton = ({ onClick, text }) => (
  <Group justify="center" mt="xl">
    <Button c="dark" color="gray.1" radius="lg" size="xs" onClick={onClick}>
      {text}
    </Button>
  </Group>
);
