import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Text, Card, Table } from "@mantine/core";

export default function TieredContestUserStanding({ contestId, userId }) {
  const [standings, setStandings] = useState([]);

  useEffect(() => {
    fetchStandings();
  }, []);

  function fetchStandings() {
    axios
      .post(`/user-tier-standings/`, { contest_id: contestId, user_id: userId })
      .then(({ data }) => {
        setStandings(data.response.sort((a, b) => a.position - b.position));
      });
  }

  if (standings.length === 0) return null;

  return (
    <div>
      {standings.map((tier) => {
        const stats = [
          {
            label: "Total Miles",
            value: tier.total_tier_miles,
          },
          {
            label: "Tier Rev Score",
            value: tier.tier_rev_score,
          },
          {
            label: "Total Challenge Miles",
            value: tier.total_challenge_miles,
          },
          {
            label: "Keyword Engagements Tier Count",
            value: tier.keyword_engagements_tier_count,
          },
          {
            label: "Keyword Engagements Tier Rev",
            value: tier.keyword_engagements_tier_rev,
          },
          {
            label: "Readings Engagements Tier Count",
            value: tier.readings_engagements_tier_count,
          },
          {
            label: "Readings Engagements Tier Rev",
            value: tier.readings_engagements_tier_rev,
          },
          {
            label: "Locations Tier Count",
            value: tier.locations_tier_count,
          },
          {
            label: "Locations Tier Rev",
            value: tier.locations_tier_rev,
          },
        ];

        return (
          <Box mb="lg" key={tier.contest_tier_id}>
            <Text mb="sm" fw={600}>
              {tier.name}
            </Text>
            <Card>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Stat</Table.Th>
                    <Table.Th>Value</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {stats.map((stat, i) => (
                    <Table.Tr key={i}>
                      <Table.Td>{stat.label}</Table.Td>
                      <Table.Td>{stat.value}</Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Card>
          </Box>
        );
      })}
    </div>
  );
}
