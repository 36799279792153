import React from "react";
import { List, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

export default function KeywordSubmitSummary({ results = {} }) {
  const { results: resultItems } = results;

  return (
    <>
      {results.alt_message && <Text mt="lg">{results.alt_message}</Text>}
      {resultItems && resultItems.length > 0 && (
        <>
          <List mt="sm">
            {resultItems.map((result, i) => (
              <List.Item
                key={i}
                icon={
                  result.success ? (
                    <IconCheck color="green" />
                  ) : (
                    <IconX color="red" />
                  )
                }
              >
                {result.success ? (
                  <span>
                    {result.keyword_claim_count ? (
                      <>
                        your keyword claim total has increased to{" "}
                        {result.keyword_claim_count} for <b>{result.name}</b>
                      </>
                    ) : (
                      <>
                        you gained {result.entry_count}{" "}
                        {result.entry_count === 1 ? "entry" : "entries"} for{" "}
                        <b>{result.name}</b>, and have{" "}
                        {result.current_entry_total} out of{" "}
                        {result.possible_entries} total
                      </>
                    )}
                  </span>
                ) : (
                  <span>
                    {result.message} for <b>{result.name}</b>
                  </span>
                )}
              </List.Item>
            ))}
          </List>
        </>
      )}
    </>
  );
}
