import React from "react";
import { TextInput } from "@mantine/core";
import { shallow } from "zustand/shallow";

import useAppStore from "./keyword-settings-store";

export default function TextSettings({ onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const textFields = [
    {
      name: "member_rebound_message",
      value: settings.member_rebound_message,
      label: "Member Rebound Message",
    },
    {
      name: "non_member_rebound_message",
      value: settings.non_member_rebound_message,
      label: "Non-Member Rebound Message",
    },
  ];

  return (
    <div>
      {textFields.map((m, i) => (
        <TextInput
          key={i}
          label={m.label}
          value={m.value}
          onChange={(e) => {
            if (e.target.value.length > 160) return;
            onUpdate({ [m.name]: e.target.value });
          }}
          max={160}
        />
      ))}
    </div>
  );
}
