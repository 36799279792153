import React, { useState } from "react";
import { Box } from "@mantine/core";
import styled from "styled-components";
import { motion } from "framer-motion";

import {
  HistoryFilterButtons,
  viewValues,
  ReadingItem,
  KeywordItem,
  LocationItem,
  EmptyText,
} from "./DashboardHistory";

export default function UserHistory({
  mileageItems = [],
  keywordItems = [],
  locationItems = [],
}) {
  const [activeView, setActiveView] = useState(viewValues.readings);

  return (
    <div>
      <Box mb="xl">
        <HistoryFilterButtons
          activeView={activeView}
          setActiveView={setActiveView}
        />
      </Box>
      {activeView === viewValues.readings && (
        <div>
          {mileageItems.length == 0 && (
            <EmptyText text="You don't have any verified odometer readings yet. When you do, they will show up here." />
          )}
          {mileageItems.map((mileageItem) => (
            <motion.div
              key={mileageItem.id}
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={itemVariants}>
                <ReadingItem
                  reading={{
                    user_vehicle: {
                      year: mileageItem.year,
                      make: mileageItem.make,
                      model: mileageItem.model,
                      series: mileageItem.series,
                      assets: mileageItem.location_logo_url
                        ? [{ filename_url: mileageItem.location_logo_url }]
                        : [],
                    },
                    location_name: mileageItem.location_name,
                    odometer_value: mileageItem.odometer_reading,
                    verified_at: mileageItem.verified_at,
                    use_kilometers: mileageItem.use_kilometers,
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      )}
      {activeView === viewValues.keywords && (
        <div>
          {keywordItems.length === 0 && (
            <EmptyText text="You haven't entered any keywords yet. When you have, they will show up here." />
          )}
          {keywordItems.map((keywordItem) => (
            <motion.div
              key={keywordItem.id}
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={itemVariants}>
                <KeywordItem
                  keyword={{
                    keyword_text: keywordItem.keyword_text,
                    location_name: keywordItem.location_name,
                    claimed_at: keywordItem.claimed_at,
                    location_logo_url: keywordItem.location_logo_url,
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      )}
      {activeView === viewValues.locations && (
        <div>
          {locationItems.length == 0 && (
            <EmptyText text="You haven't visited any locations yet. When you have, they will show up here." />
          )}
          {locationItems.map((locationItem) => (
            <motion.div
              key={locationItem.id}
              variants={listVariants}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={itemVariants}>
                <LocationItem
                  location={{
                    ...locationItem,
                    logo_url: locationItem.location_logo_url,
                  }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}

export const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: contain;
  border-radius: 5px;
`;

export const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

export const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};
