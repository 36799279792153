import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  ActionIcon,
  Loader,
  Card,
  Badge,
  Divider,
  Button,
  Box,
  Grid,
  Modal,
} from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";
import styled from "styled-components";

import RevBreakdown from "./RevBreakdown";
import TierReport from "./TierReport";
import { winnerStyles } from "./helpers";
export default function RideChallengeDrawing({ contestId, effortId }) {
  const [tierGroups, setTierGroups] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, [effortId]);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/contests/${contestId}/retrieve-contest-tier-groups/`, {
        contest_id: contestId,
      })
      .then(({ data }) => {
        setTierGroups(
          data.response.map((m) => ({
            ...m,
            contest_tiers: m.contest_tiers.sort(
              (a, b) => a.position - b.position
            ),
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setTierGroups([]);
      });
  }
  return (
    <div>
      {error && (
        <Text mb="lg" fw={600} c="red">
          {error}
        </Text>
      )}
      {loading && <Loader />}
      {tierGroups.map((tierGroup) => (
        <TierGroup
          tierGroup={tierGroup}
          key={tierGroup.id}
          contestId={contestId}
          effortId={effortId}
          fetchData={fetchData}
        />
      ))}
    </div>
  );
}

const TierGroup = ({ tierGroup, contestId, effortId, fetchData }) => {
  return (
    <div>
      <Text mb="sm" fw={600}>
        {tierGroup.name} ({tierGroup.variety_formatted})
      </Text>
      {tierGroup.contest_tiers.map((tier) => (
        <Tier
          tier={tier}
          key={tier.id}
          contestId={contestId}
          effortId={effortId}
          fetchData={fetchData}
        />
      ))}
    </div>
  );
};

const Tier = ({ tier, contestId, effortId, fetchData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);
  const [winnerData, setWinnerData] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (tier.winner_data) {
      setWinnerData({ winner: [{ ...tier.winner_data }] });
    } else {
      setWinnerData(null);
    }
  }, [JSON.stringify(tier)]);

  useEffect(() => {
    if (!isOpen) {
      setIsDrawing(false);
    }
  }, [isOpen]);

  function handleDraw(tableDataOnly = false) {
    setIsDrawing(true);

    const req = {
      contest_tier_id: tier.id,
      contest_id: contestId,
      campaign_effort_id: effortId,
      table_data_only: tableDataOnly,
    };

    axios
      .post(`/determine-winners-for-tier/`, req)
      .then(({ data }) => {
        setIsDrawing(false);
        if (tableDataOnly) {
          setTableData(data.response[0].table_data);
        } else {
          setWinnerData(data.response[0]);
        }
      })
      .catch((err) => {
        toast.error(err);
        setIsDrawing(false);
        if (tableDataOnly) {
          setTableData([]);
        } else {
          setWinnerData(null);
        }
      });

    setTimeout(() => {
      setIsDrawing(false);
    }, 1000);
  }

  const tierImage = tier.assets[0]?.filename_url;
  const tierStage = getTierStage(tier);

  return (
    <div>
      <Card mb="sm">
        <Flex gap="xs" align="center">
          {tierImage && <StyledImage height={50} width={50} src={tierImage} />}
          <div style={{ flexGrow: 1 }}>
            <Flex align="center" gap="xs">
              <Text fw={600} size="lg">
                {tier.name}
              </Text>
              <Badge size="xs" color="gray" variant="light">
                {tierStage}
              </Badge>
            </Flex>
            <Text size="sm" c="dimmed">
              {tier.start_value} - {tier.end_value}
            </Text>
          </div>
          <ActionIcon
            color="gray"
            radius="xl"
            variant="light"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <IconChevronUp size={16} />
            ) : (
              <IconChevronDown size={16} />
            )}
          </ActionIcon>
        </Flex>
        {isOpen && (
          <div>
            <Divider mt="lg" mb="lg" />
            <Box mt="lg">
              {winnerData ? (
                <Box mb="lg">
                  <Winners
                    fetchData={() => {
                      fetchData();
                      setWinnerData(null);
                    }}
                    winnerData={winnerData}
                  />
                  <Flex gap="xs" align="center" mt="lg">
                    <Button
                      color="gray"
                      variant="light"
                      loading={isDrawing}
                      onClick={() => {
                        if (tableData.length > 0) {
                          setTableData([]);
                        } else {
                          handleDraw(true);
                        }
                      }}
                    >
                      {tableData.length > 0 ? "Hide Data" : "Show Data"}
                    </Button>
                  </Flex>
                </Box>
              ) : (
                <>
                  {tier.can_select_winner && (
                    <Text fw={600} mb="lg">
                      This tier is currently available to draw. You can draw for
                      this tier by clicking the button below.
                    </Text>
                  )}
                  <Flex gap="xs" align="center">
                    {tier.can_select_winner && (
                      <Button loading={isDrawing} onClick={() => handleDraw()}>
                        Determine Winner
                      </Button>
                    )}
                    <Button
                      color="gray"
                      variant="light"
                      loading={isDrawing}
                      onClick={() => {
                        if (tableData.length > 0) {
                          setTableData([]);
                        } else {
                          handleDraw(true);
                        }
                      }}
                    >
                      {tableData.length > 0 ? "Hide Data" : "Show Data"}
                    </Button>
                  </Flex>
                </>
              )}
            </Box>
            {tableData.length > 0 && (
              <Box mt="lg" pb="lg">
                <Divider mt="lg" mb="lg" />
                <TierReport
                  data={tableData}
                  tierName={tier.name}
                  winnerUserIds={
                    winnerData ? winnerData.winner.map((m) => m.user_id) : []
                  }
                />
              </Box>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

const Winners = ({ winnerData, fetchData }) => {
  return (
    <div>
      <Box style={{ padding: "0 0 0 15px" }}>
        <Text mb="md" fw={600}>
          Winner{winnerData.winner.length === 1 ? "" : "s"}
        </Text>
      </Box>
      <RevBreakdown isGuide />
      <Grid>
        {winnerData.winner.map((winner) => (
          <Grid.Col span={12} key={winner.user_id}>
            <Box
              style={{
                background: winnerStyles.background,
                border: winnerStyles.border,
                padding: "10px",
                marginBottom: "1px",
              }}
            >
              <RevBreakdown info={winner}>
                <RejectWinner winner={winner} fetchData={fetchData} />
              </RevBreakdown>
            </Box>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};

function RejectWinner({ winner, fetchData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onConfirmClick() {
    setLoading(true);
    axios
      .post(`/reject-winner-for-tier/`, {
        user_id: winner.user_id,
        contest_tier_id: winner.contest_tier_id,
      })
      .then(() => {
        setIsOpen(false);
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button size="xs" color="red" onClick={() => setIsOpen(true)}>
        Reject Winner
      </Button>
      {isOpen && (
        <Modal opened={isOpen} onClose={() => setIsOpen(false)}>
          <Text mt="md" align="center">
            This action will make{" "}
            <b>
              {winner.full_name} ineligible to win the{" "}
              {winner.contest_tier_name} tier again
            </b>
            .
          </Text>
          <Text align="center" mt="md">
            Are you sure you want to do this?
          </Text>
          <Button mt="lg" fullWidth onClick={onConfirmClick}>
            Reject Winner
          </Button>
          <Flex justify="center" mt="md">
            <Button
              size="xs"
              variant="subtle"
              color="gray"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Modal>
      )}
    </div>
  );
}

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;

function getTierStage(tier) {
  if (tier.can_select_winner && !tier.has_winner && !tier.needs_judge) {
    return "Ready for winner selection";
  }
  if (tier.has_winner && !tier.needs_judge) {
    return "Winner Selected";
  }
  if (tier.has_winner && tier.needs_judge) {
    return "No Clear Winner";
  }

  return "Not ready for winner selection";
}
