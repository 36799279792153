import React from "react";

import { ContestManagement } from "@components/Contest";
import { effortAwardType } from "./helpers";
import { EffortPointSettings } from "./";

export default function EffortAwards({
  awardType,
  effortId,
  endDate,
  fetchEffort,
  pointsOnly,
  pointSettings,
  recurringEngagement,
  showVarietyFilters = true,
  wizardSettings,
}) {
  return (
    <div>
      {awardType === effortAwardType.contest_entries && (
        <ContestManagement
          showCreate={false}
          effortId={effortId}
          endDate={endDate}
          showVarietyFilters={showVarietyFilters}
        />
      )}
      {awardType === effortAwardType.points && (
        <EffortPointSettings
          effortId={effortId}
          fetchEffort={fetchEffort}
          pointSettings={pointSettings}
          pointsOnly={pointsOnly}
          recurringEngagement={recurringEngagement}
          wizardSettings={wizardSettings}
        />
      )}
      {awardType === effortAwardType.tiered && (
        <ContestManagement
          showCreate={false}
          effortId={effortId}
          endDate={endDate}
          showVarietyFilters={false}
        />
      )}
    </div>
  );
}
