import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Flex,
  Card,
  Text,
  Grid,
  Title,
  NumberFormatter,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import axios from "axios";

import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";
import { ImageSlider } from "@components/shared";
import { size } from "@util/breakpoints";
import { Speedometer } from "@components/DataViz";
import { Odometer } from "@components/RideChallenge";
import {
  StyledImage,
  StyledPlaceholder,
} from "@components/RideChallengeApp/UserHistory";
import RideChallengeHeader from "@components/RideChallengeApp/RideChallengeHeader";
import logo from "@assets/images/lets-ride-logo.png";

const backgroundColor = `rgba(0, 0, 0, 0.925)`;

export default function EffortPublicDashboard({ codeData }) {
  useEffect(() => {
    if (!codeData) return;
    runSetup();
  }, [JSON.stringify(codeData)]);
  const { dashboard_data: dashboardData } = codeData;

  const effortId = dashboardData.campaign_effort_id;
  const goalMiles = 50000000;
  const totalMiles = dashboardData.ride_challenge_total_miles;
  const milesAway = goalMiles - totalMiles;
  const milesOver = totalMiles - goalMiles;
  const goalAchieved = totalMiles >= goalMiles;
  const dealerInfo = null;
  const gaugeMaxValue = getScaledGaugeMaxValue(totalMiles);

  const appConfig = codeData.published_app_configuration;
  const config = codeData.published_configuration;
  const baseColor = appConfig.colors?.base || "orange";

  function runSetup() {
    document.body.style.backgroundColor = "var(--mantine-color-dark-9)";
    const backgroundImage = getPlacementImage(
      appConfig,
      codeData.assets,
      placementDefs.background
    );

    if (backgroundImage) {
      document.body.style.background = `url(${backgroundImage}) no-repeat center center`;
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundAttachment = "fixed";
    }
  }

  const assets = config.assets
    ? config.assets
        .map((m) => ({
          ...m,
          asset: codeData.assets?.find((f) => f.id === m.id),
        }))
        .filter((f) => f.asset)
    : [].filter((f) => f.asset);
  const sliderImages = assets.filter((f) => f.placement === 2);

  const statBlocks = [
    {
      text: <>Total Miles</>,
      value: dashboardData.ride_challenge_total_miles,
    },
    {
      text: <>Total Registrations</>,
      value: dashboardData.ride_challenge_total_regs,
    },
    {
      text: <>Total Vehicles</>,
      value: dashboardData.ride_challenge_total_vehicles,
      // show_for_dealer: false,
    },
    {
      text: <>Average Miles</>,
      value: dashboardData.ride_challenge_avg_miles,
      // show_for_dealer: false,
    },
  ].filter((f) => (dealerInfo ? f.show_for_dealer !== false : true));

  return (
    <div>
      <Layout>
        <Wrapper isDealer={!!dealerInfo}>
          <Box p="lg">
            <Box mt="lg">
              {dealerInfo ? (
                <DealerInfo>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/f/f0/Papa_John%27s_Logo_2019.svg"
                    alt="Bluegrass Harley-Davidson"
                    className="dealer-logo"
                  />
                  <Box mt="sm" mb="sm">
                    <Text fw={600} c="white" size="xl" align="center">
                      {dealerInfo.name}
                    </Text>
                    <Text c="dimmed" size="xs" align="center">
                      {dealerInfo.address}
                    </Text>
                  </Box>
                  <img src={logo} alt="Let's Ride" className="rc-logo" />
                </DealerInfo>
              ) : (
                <RideChallengeHeader />
              )}
            </Box>
            {/* {sliderImages.length > 0 && (
            <ImageSlider
              items={[
                ...sliderImages.map((m) => ({
                  src: m.asset.filename_url,
                  external_link_url: m.asset.external_link_url,
                  key: m.id,
                })),
              ]}
            />
          )} */}
            <GaugeGoalWrapper>
              <Speedometer value={totalMiles} maxValue={gaugeMaxValue} />
            </GaugeGoalWrapper>
            <OdometerWrapper>
              <Odometer newValue={totalMiles} />
            </OdometerWrapper>
            <Title order={2} c="white" align="center" mt="md">
              Our Goal:{" "}
              <span style={{ color: `var(--mantine-color-${baseColor}-7)` }}>
                <NumberFormatter value={goalMiles} thousandSeparator />
              </span>{" "}
              miles
            </Title>
            <Text c="dimmed" align="center" size="xs">
              {goalAchieved ? (
                <span
                  style={{
                    color: "var(--mantine-color-green-7)",
                    fontWeight: 600,
                  }}
                >
                  Goal Achieved!{" "}
                  <NumberFormatter value={milesOver} thousandSeparator /> miles
                  over
                </span>
              ) : (
                <>
                  <NumberFormatter value={milesAway} thousandSeparator /> miles
                  away
                </>
              )}
            </Text>
            {/* <Box mt="lg">
              <Text c="white" mb="xs">
                For the glory of the Alliance! Champions of Azeroth, heed my
                words. In the depths of the Emerald Dream, where ancient druids
                slumber and wild gods roam, darkness stirs beneath the canopy of
                eternal twilight. The Burning Legion's shadow yet lingers, while
                the whispers of Old Gods echo through the halls
              </Text>
            </Box> */}
          </Box>
          <Box p="lg">
            <Grid>
              {statBlocks.map((statBlock, i) => (
                <Grid.Col span={6} key={i}>
                  <StatBlock color={baseColor}>
                    <div>
                      <Text size="xs" align="center" className="label">
                        {statBlock.text}
                      </Text>
                      <Title
                        mt={4}
                        order={2}
                        c="white"
                        align="center"
                        className="value"
                      >
                        <NumberFormatter
                          value={statBlock.value}
                          thousandSeparator
                        />
                      </Title>
                    </div>
                  </StatBlock>
                </Grid.Col>
              ))}
            </Grid>
            {dashboardData.additional_data.oldest_model && (
              <Box mt="md">
                <StatBlock color={baseColor}>
                  <div>
                    <Text size="xs" align="center" className="label">
                      Oldest Model
                    </Text>
                    <Title
                      mt={4}
                      order={2}
                      c="white"
                      align="center"
                      className="value"
                    >
                      {dashboardData.additional_data.oldest_model}
                    </Title>
                  </div>
                </StatBlock>
              </Box>
            )}
            {!dealerInfo && (
              <Box mt="xl">
                <Leaderboard
                  effortId={effortId}
                  color={baseColor}
                  contextLocations={{
                    miles: dashboardData.additional_data.miles_locations.map(
                      (m) => ({
                        ...m,
                        value: m.total_miles,
                      })
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
        </Wrapper>
        {dealerInfo && (
          <Box>
            <NonDealerLink color={baseColor} href="https://google.com">
              View overall standings
            </NonDealerLink>
          </Box>
        )}
      </Layout>
    </div>
  );
}

const Layout = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 60px 0 20px 0;
  word-wrap: break-word;
  overflow-x: hidden;

  label {
    color: whitesmoke;
  }

  @media (max-width: ${size.tablet}) {
    width: 97%;
    padding-top: 10px;
  }
`;

const GaugeGoalWrapper = styled.div`
  position: relative;
  p {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    top: -20px;
  }
`;

const OdometerWrapper = styled.div`
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .odometer-value {
    /* font-size: 40px; */
  }
`;

const StatBlock = styled.div`
  background: var(--mantine-color-dark-8);
  border: 1px solid var(--mantine-color-dark-5);
  border-radius: 10px;
  padding: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .value {
    font-weight: 700;
  }

  .label {
    color: ${(props) => `var(--mantine-color-${props.color}-7)`};
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const leaderboardContexts = [
  {
    label: "Overall",
    value: "overall",
    description: "A description of the overall leaderboard",
  },
  {
    label: "Miles",
    value: "miles",
    description: "Miles traveled by riders",
  },
  {
    label: "Keyword",
    value: "keyword",
    description: "A description of the keyword leaderboard",
  },
];

const Leaderboard = ({
  effortId,
  color,
  contextLocations = {
    miles: [],
    keyword: [],
    overall: [],
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [leaderboardContext, setLeaderboardContext] = useState("miles");

  const leaderboardItems = contextLocations[leaderboardContext];

  const leaderboardContextItem = leaderboardContexts.find(
    (c) => c.value === leaderboardContext
  );

  const visibleContexts = leaderboardContexts.filter(
    // (f) => contextLocations[f.value].length > 0
    (f) => contextLocations[f.value] && contextLocations[f.value].length > 0
  );

  // useEffect(() => {
  //   // fetchItems();
  // }, [leaderboardContext]);

  // function fetchItems() {
  //   const req = {
  //     context: leaderboardContext,
  //     campaign_effort_id: effortId,
  //   };

  //   setLoading(true);

  //   axios
  //     .post("/ride-challenge-leaderboard/", req)
  //     .then(({ data }) => {
  //       setLeaderboardItems(data.response);
  //       // setLeaderboardItems(fakeLeaderboardItems);
  //     })
  //     .catch((err) => {
  //       // setLeaderboardItems([]);
  //       setLeaderboardItems(fakeLeaderboardItems);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }

  return (
    <div>
      <Title order={2} c="white" align="center">
        Dealer Leaderboard
      </Title>
      <Box mih={150} style={{ position: "relative" }}>
        <StyledOverlay>
          <LoadingOverlay visible={loading} overlayBlur={2} />
        </StyledOverlay>
        {leaderboardContextItem && (
          <Text align="center" c="white" size="xs" mb="lg">
            {leaderboardContextItem.description}
          </Text>
        )}
        {visibleContexts.length > 1 && (
          <Flex gap="xs" justify="center" mb="xl">
            {visibleContexts.map((context, index) => (
              <Button
                key={index}
                color={color}
                variant={
                  leaderboardContext === context.value ? "filled" : "light"
                }
                size="xs"
                radius="lg"
                onClick={() => setLeaderboardContext(context.value)}
              >
                {context.label}
              </Button>
            ))}
          </Flex>
        )}
        {leaderboardItems.map((item, index) => (
          <CardContainer key={item.id}>
            <Flex gap="sm" align="center">
              <Title miw={18} order={5} c="white">
                {index + 1}
              </Title>
              {item.logo_url ? (
                <StyledImage height={40} width={40} src={item.logo_url} />
              ) : (
                <StyledPlaceholder height={40} width={40} />
              )}
              <div style={{ flexGrow: 1 }}>
                <Text size="sm" fw={600} c={`${color}.7`}>
                  {item.name}
                </Text>
                <Text size="sm" c="dimmed">
                  {item.city}, {item.state}
                </Text>
              </div>
              <Text
                size="xs"
                fw={600}
                c="dimmed"
                style={{
                  flexGrow: 1,
                  textAlign: "right",
                }}
              >
                <NumberFormatter value={item.value} thousandSeparator /> mi.
              </Text>
            </Flex>
          </CardContainer>
        ))}
        {!loading && leaderboardItems.length === 0 && (
          <Flex justify="center" align="center">
            <Text fw={600} c="white" align="center">
              Check back later for leaderboard results
            </Text>
          </Flex>
        )}
      </Box>
    </div>
  );
};

const CardContainer = ({ children }) => (
  <Card
    mb="xs"
    style={{
      background: "var(--mantine-color-dark-8)",
      // borderWidth: 4,
      borderColor: "var(--mantine-color-dark-5)",
    }}
  >
    {children}
  </Card>
);

const Wrapper = styled.div`
  /* background-color: ${backgroundColor}; */
  border: 1px solid var(--mantine-color-dark-7);
  padding-bottom: ${(props) => (props.isDealer ? "2em" : "0")};
  background: ${backgroundColor};
  border-radius: ${(props) =>
    !props.isDealer
      ? "var(--mantine-radius-md)"
      : "var(--mantine-radius-md) var(--mantine-radius-md) 0 0"};
`;

const DealerInfo = styled.div`
  img.dealer-logo {
    margin: 0 auto;
    display: block;
    max-width: 200px;
    max-height: 125px;
    object-fit: contain;
  }

  img.rc-logo {
    margin: 0 auto;
    display: block;
    width: 350px;
    /* max-width: 200px; */
    max-height: 125px;
    object-fit: contain;
  }
`;

const NonDealerLink = styled.a`
  text-align: center;
  background: var(--mantine-color-${(props) => props.color}-7);
  padding: 10px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  border-radius: 0 0 var(--mantine-radius-md) var(--mantine-radius-md);
  width: 100%;
  display: block;
  font-size: 0.95em;
`;

const StyledOverlay = styled.div`
  .mantine-LoadingOverlay-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
  }
`;

function getScaledGaugeMaxValue(currentValue) {
  if (currentValue < 100000) {
    return 100000;
  } else if (currentValue < 1000000) {
    return 1000000;
  } else if (currentValue < 10000000) {
    return 10000000;
  } else if (currentValue < 50000000) {
    return 50000000;
  }
  return 100000000;
}
