import React, { useState, useEffect } from "react";
import { TextInput } from "@mantine/core";
import { shallow } from "zustand/shallow";

import useAppStore from "./effort-settings-store";

export default function GeneralSettings({ onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const [tempSettings, setTempSettings] = useState({
    member_rebound_message: settings.member_rebound_message,
    non_member_rebound_message: settings.non_member_rebound_message,
  });

  useEffect(() => {
    setTempSettings({
      member_rebound_message: settings.member_rebound_message,
      non_member_rebound_message: settings.non_member_rebound_message,
    });
  }, [JSON.stringify(settings)]);

  const textFields = [
    {
      name: "member_rebound_message",
      label: "Member Rebound Message",
    },
    {
      name: "non_member_rebound_message",
      label: "Non-Member Rebound Message",
    },
  ];

  return (
    <div>
      {textFields.map((m, i) => (
        <TextInput
          key={i}
          label={m.label}
          value={tempSettings[m.name]}
          onChange={(e) => {
            if (e.target.value.length > 160) return;
            // onUpdate({ [m.name]: e.target.value });
            setTempSettings({ ...tempSettings, [m.name]: e.target.value });
          }}
          onBlur={(e) => onUpdate({ [m.name]: e.target.value })}
          max={160}
        />
      ))}
    </div>
  );
}
