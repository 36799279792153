import React, { useState } from "react";
import styled from "styled-components";
import { Button, Card, Title, Modal, Group } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import axios from "axios";
import { getUuid } from "@util/getUuid";

import FieldLibrary from "./FieldLibrary";
import FieldItem from "./EffortFieldItem";
import FieldForm from "./EffortFieldForm";
import { EffortSavedForms } from "@components/Effort";

import { addressKeys } from "./FieldLibrary";
import useAppStore from "./effort-builder-store";

export default function EffortFields({
  effortId,
  onChange,
  postmarkTemplateNames,
  eiWithTriggerId,
  entityName,
  effortName,
}) {
  const [customVisible, setCustomVisble] = useState(false);
  const [libraryVisible, setLibraryVisible] = useState(false);
  const { fields } = useAppStore(
    (state) => ({
      fields: state.effortState.fields,
    }),
    shallow
  );

  function onFieldUpdate(updatedField) {
    if (updatedField.options.length) {
      const options = updatedField.options.map((m) => ({
        text: m.text,
        effort_id: effortId,
      }));
    }

    onChange(
      [...fields].map((x) => {
        const item = fields.find((f) => x.uuid === updatedField.uuid);
        return item ? updatedField : x;
      })
    );
  }

  function onFieldRemove(uuid) {
    const fieldInfo = fields.find((f) => f.uuid === uuid);
    if (!fieldInfo) return;

    if (fieldInfo.name === "phone") {
      return onChange(fields.filter((f) => f.uuid !== uuid));
    } else {
      if (addressKeys.includes(fieldInfo.name)) {
        onChange(fields.filter((f) => !addressKeys.includes(f.name)));
      } else {
        onChange(fields.filter((f) => f.name !== fieldInfo.name));
      }
      // onChange(
      //   fields.filter((f) =>
      //     f.db_property && addressKeys.includes(f.db_property)
      //       ? !addressKeys.includes(f.db_property)
      //       : f.uuid !== uuid
      //   )
      // );
    }
    // onChange(fields.filter((f) => f.uuid !== uuid));
  }

  function onFieldLibraryAdd(e) {
    if (e.dynamic_label_field === "sms-opt-in") {
      return createEngagementItem(e);
    } else {
      onChange([...fields, { ...e, editable: false }]);
    }
    setLibraryVisible(false);
  }

  function createEngagementItem(e) {
    const req = {
      ...e,
      allow_multiple_responses: false,
      editable: false,
      effort_id: effortId,
      email_data_template_ref: null,
      label: `Opt in to receive text messages about promotions and updates related to ${entityName} and ${effortName}. Message and data rates may apply.`,
      reportable: false,
      required: false,
      select_items: [],
      type: "checkbox",
    };

    axios
      .post(`/engagement-items/`, req)
      .then(({ data }) => {
        onChange([
          ...fields,
          {
            allow_multiple_responses: false,
            api_field_name: "--",
            default_checked: false,
            dynamic_label_field: req.dynamic_label_field,
            email_data_template_ref: "",
            engagement_item_id: data.response[0].engagement_item_id,
            label_url: "",
            label: req.label,
            multiple: false,
            name: data.response[0].engagement_item_id,
            options: [],
            reportable: false,
            required: false,
            type: "checkbox",
            uuid: getUuid(),
          },
        ]);
        setLibraryVisible(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onAddressClick(arr, country) {
    let newFields = [...fields, ...arr.map((m) => ({ ...m, editable: false }))];
    const zipField = newFields.find((f) => f.db_property === "zip");
    if (zipField) {
      if (country === "ca") {
        zipField.label = "Postal Code";
      } else if (country === "both") {
        zipField.label = "Zip/Postal Code";
      } else {
        zipField.label = "Zip";
      }
    }
    onChange(newFields);
    setLibraryVisible(false);
  }

  return (
    <StyledFieldMgmt>
      <Modal
        opened={customVisible}
        onClose={() => setCustomVisble(false)}
        title="Add Custom Field"
      >
        <FieldForm
          effortId={effortId}
          hasImageField={fields.find((f) => f.type === "image") ? true : false}
          onSubmit={(e) => {
            onChange([...fields, { ...e, uuid: getUuid() }]);
            setCustomVisble(false);
          }}
        />
      </Modal>
      <Modal
        opened={libraryVisible}
        onClose={() => setLibraryVisible(false)}
        title="Add Field From Library"
      >
        <FieldLibrary
          onClick={(e) => onFieldLibraryAdd(e)}
          onAddressSelect={onAddressClick}
        />
      </Modal>
      <Group>
        <Title order={3}>Fields</Title>
        <Button
          leftSection={<IconPlus size={12} />}
          size="xs"
          variant="light"
          radius="lg"
          onClick={() => setCustomVisble(true)}
        >
          Custom Field
        </Button>
        <Button
          leftSection={<IconPlus size={12} />}
          radius="lg"
          size="xs"
          variant="light"
          onClick={() => setLibraryVisible(true)}
        >
          Library
        </Button>
        <EffortSavedForms
          effortId={effortId}
          hasCustomQuestion={fields.find((f) => !f.db_property) ? true : false}
        />
      </Group>
      <Card mt="sm">
        {fields.map((f) => (
          <FieldItem
            key={f.uuid}
            fieldFormState={f}
            eiWithTriggerId={eiWithTriggerId}
            effortId={effortId}
            onUpdate={(e) => onFieldUpdate(e)}
            onRemoveSuccess={(e) => onFieldRemove(e)}
            postmarkTemplateNames={postmarkTemplateNames}
          />
        ))}
      </Card>
    </StyledFieldMgmt>
  );
}

const StyledFieldMgmt = styled.div`
  .meta {
    display: flex;
    margin-bottom: 0.5em;
  }
`;

export const StyledTextButton = styled.button`
  font-family: inherit;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #8f8a8a;
  outline: none;
`;
