import React, { useState, useEffect } from "react";
import { Flex, Box, TextInput, Button, Text } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { Scanner } from "@yudiel/react-qr-scanner";
import styled from "styled-components";

export default function DealerVerification({
  setDealerCodeData,
  codeVerificationData,
  size = 250,
}) {
  const [codeValue, setCodeValue] = useState("");
  const [error, setError] = useState(null);
  const [triedAgain, setTriedAgain] = useState(false);

  function onCodeSubmit(scannedCodeValue) {
    const req = {
      ...codeVerificationData,
      code: scannedCodeValue || codeValue,
    };

    axios
      .post(`/ride-challenge/verification-code-check/`, req)
      .then(({ data }) => {
        setDealerCodeData(data.response[0]);
      })
      .catch((err) => {
        setCodeValue("");
        toast.error(err, {
          duration: 10000,
        });
      });
  }

  return (
    <div>
      <Text c="white" align="center" mb="xl" fw={600}>
        An authorized dealership validator is needed for this step. If you
        aren't with a staff member, please ask the dealership for assistance.
      </Text>
      <Flex justify="center" mb="xl">
        {error ? (
          <div>
            <Text size="lg" c="red" align="center" fw={600}>
              Camera access is required for this step. Please grant access to
              your camera and try again. You may need to reload the page for
              changes to apply.
            </Text>
            <Box mt="lg">
              {!triedAgain && (
                <Button
                  fullWidth
                  onClick={() => {
                    setError(null);
                    setTriedAgain(true);
                  }}
                  size="lg"
                  mt="lg"
                  mb="sm"
                >
                  Try Again
                </Button>
              )}
              {triedAgain && (
                <Text mb="lg" size="lg" c="red" align="center" fw={600}>
                  Please reload the page and try again.
                </Text>
              )}
              <Button
                fullWidth
                size="lg"
                onClick={() => window.location.reload()}
              >
                Reload Page
              </Button>
            </Box>
          </div>
        ) : (
          <StyledScanner size={size}>
            <Scanner
              styles={{
                container: {},
              }}
              onScan={(result) => {
                if (result.length && result[0].rawValue) {
                  // setCodeValue(result[0].rawValue);
                  onCodeSubmit(result[0].rawValue);
                }
              }}
              onError={(e) => {
                setError(e);
              }}
            />
          </StyledScanner>
        )}
      </Flex>
      {import.meta.env.VITE_ENV === "dev" && (
        <>
          <TextInput
            label="Dealer Code"
            value={codeValue}
            onChange={(e) => setCodeValue(e.target.value)}
          />
          <Button
            mt="xs"
            fullWidth
            disabled={!codeValue}
            onClick={() => onCodeSubmit()}
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
}

const StyledScanner = styled.div`
  display: flex;
  justify-content: center;
  height: ${(props) => (props.size ? `${props.size}px` : "250px")};
  width: ${(props) => (props.size ? `${props.size}px` : "250px")};

  svg {
    border: none !important;
  }
`;
