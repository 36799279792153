import React, { useState, useEffect } from "react";
import axios from "axios";
import { Text } from "@mantine/core";
import { VinSetup } from "@components/RideChallenge";
import { UnverifiedItem } from "./VehicleVerificationView";
import DealerVerification from "./DealerVerification";

export default function VehicleSetup({
  onSuccess,
  fetchData,
  additionalReqData,
  verificationCode,
  codeVerificationData,
}) {
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [showDealerVerification, setShowDealerVerification] = useState(false);
  const [userSubmitData, setUserSubmitData] = useState(null);

  useEffect(() => {
    if (verificationCode) {
      setDealerCodeData(verificationCode);
    }
  }, []);

  const unverifiedItem =
    userSubmitData && userSubmitData.ride_challenge_data_point
      ? userSubmitData.ride_challenge_data_point
      : null;

  if (showDealerVerification) {
    if (dealerCodeData && unverifiedItem) {
      return (
        <div>
          <UnverifiedItem
            info={unverifiedItem}
            additionalReqData={{
              ...additionalReqData,
              verifier_remote_code_id: dealerCodeData.id,
            }}
            fetchUser={() => {
              onSuccess();
            }}
            verificationCode={verificationCode}
            dealerCodeData={dealerCodeData}
          />
        </div>
      );
    }

    if (dealerCodeData)
      return (
        <Text align="center" fw={600} c="white">
          We're having trouble verifying your vehicle. Please reload the page.
        </Text>
      );

    return (
      <div>
        <DealerVerification
          codeVerificationData={codeVerificationData}
          setDealerCodeData={(e) => {
            setDealerCodeData(e);
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <VinSetup
        isAdditionalVehicle
        onSuccess={(e) => {
          setUserSubmitData(e);
          setShowDealerVerification(true);
          fetchData();
        }}
        additionalReqData={additionalReqData}
        verificationCode={verificationCode}
      />
    </div>
  );
}
