import React from "react";
import { Text, Title } from "@mantine/core";
import styled from "styled-components";

import useAppStore from "./ride-challenge-store";

export default function ViewTitle({
  iconColor = null,
  title = "Title",
  icon,
  order = 2,
  subtitle = "",
  marginBottom = "2em",
}) {
  const colors = useAppStore((state) => state.colors);
  return (
    <div style={{ marginBottom }}>
      {icon && (
        <StyledWrapper color={iconColor || colors.icon}>{icon}</StyledWrapper>
      )}
      <Title align="center" order={2} c="white">
        {title}
      </Title>
      {subtitle && (
        <Text size="lg" align="center" c="white">
          {subtitle}
        </Text>
      )}
    </div>
  );
}

const StyledWrapper = styled.div`
  text-align: center;

  svg {
    stroke: ${(props) => props.color || "#000"};
    color: ${(props) => props.color || "#000"};
  }
`;
