import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Flex, Text, Box, Card } from "@mantine/core";
import { useParams, Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { LoyaltyMembership } from "@components/LoyaltyMembership";
import { UserVehicleItem } from "@components/UserVehicle";
import {
  ErrorView,
  PageTitle,
  Subnav,
  EntitySubtitles,
} from "@components/shared";
// import { UserMilestones } from "@components/Milestone";
import UserMilestones from "@components/RideChallengeApp/UserMilestones";
import { TieredContestUserStanding } from "@components/Contest";

const RideChallengeInteractionDetail = () => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { effortId, userId } = params;

  if (!effortId || !userId) return null;

  const baseUrl = `/ride-challenge-interactions/${effortId}/${userId}`;

  useEffect(() => {
    fetchData();
  }, [effortId, userId]);

  function fetchData() {
    const req = {
      campaign_effort_id: effortId,
      user_id: userId,
    };

    axios
      .post(`/efforts/${effortId}/retrieve-management-detail/`, req)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  // const rideChallengeDataPoints = info
  //   ? info.ride_challenge_data_points.map((m) => ({
  //       ...m,
  //       user_vehicle: info.user_vehicles.find(
  //         (f) => f.id === m.user_vehicle_id
  //       ),
  //     }))
  //   : [];

  const vehicles = info.user_vehicles
    .map((vehicle) => ({
      ...vehicle,
      // user_vehicle_data_points: info.user_vehicle_data_points.filter(
      //   (f) => f.user_vehicle_id === vehicle.id
      // ),
    }))
    .sort((a, b) => b.odometer_total - a.odometer_total);

  const metaBlocks = info.meta_data
    ? [
        { text: "Total Miles", value: info.meta_data.total_miles },
        { text: "Rev Score", value: info.meta_data.rev_score },
        {
          text: "Keyword Engagements",
          value: info.meta_data.keyword_engagements_count,
        },
        {
          text: "Keyword Engagements Rev",
          value: info.meta_data.keyword_engagements_rev,
        },
        {
          text: "Reading Engagements",
          value: info.meta_data.readings_engagements_count,
        },
        {
          text: "Reading Engagements Rev",
          value: info.meta_data.readings_engagements_rev,
        },
        {
          text: "Rev Score Keywords Multiplier",
          value: info.meta_data.rev_score_keywords_multiplier,
        },
        {
          text: "Rev Score Readings Multiplier",
          value: info.meta_data.rev_score_readings_multiplier,
        },
      ]
    : null;

  return (
    <>
      <PageTitle
        title={info.user_info.full_name}
        subtitle="Ride Challenge Interaction"
        style={{ marginBottom: "0" }}
      />
      <EntitySubtitles
        info={{
          ...info,
          campaign_effort_name: info.campaign_effort_title,
        }}
      />
      <Box mt="lg">
        <Subnav
          links={[
            { to: `${baseUrl}`, text: "Detail" },
            { to: `${baseUrl}/vehicles`, text: "Vehicles" },
            { to: `${baseUrl}/milestones`, text: "Milestones" },
            { to: `${baseUrl}/standings`, text: "Standings" },
          ]}
        />
      </Box>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Box mb="lg">
                <Grid gutter="xs">
                  {metaBlocks.map((metaBlock, i) => (
                    <Grid.Col key={i} span={{ base: 12, md: 4 }}>
                      <Card>
                        <Flex align="center" gap="lg">
                          <div style={{ flexGrow: 1 }}>
                            <Text fw={600} size="xl">
                              {metaBlock.value}
                            </Text>
                            <Text>{metaBlock.text}</Text>
                          </div>
                        </Flex>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid>
              </Box>
              <Card
                style={{
                  background: "var(--mantine-color-dark-5)",
                }}
              >
                <Box>
                  <StyledForm>
                    <LoyaltyMembership
                      membership={
                        info && info.loyalty_memberships.length > 0
                          ? info.loyalty_memberships[0]
                          : null
                      }
                      userInfo={info.user_info}
                      additionalReqData={{
                        variety: 1,
                        in_use: true,
                        user_id: info.user_id,
                        user_location_id: info.id,
                        campaign_effort_id: effortId,
                      }}
                      fetchData={fetchData}
                      showMembershipSignup={false}
                    />
                  </StyledForm>
                </Box>
              </Card>
            </>
          }
        />
        <Route
          path="vehicles"
          element={
            <>
              {vehicles.map((vehicle) => (
                <Card mb="sm" key={vehicle.id}>
                  <UserVehicleItem
                    vehicle={{
                      ...vehicle,
                      unverified_ride_challenge_data_points:
                        info.unverified_ride_challenge_data_points
                          .filter((f) => f.user_vehicle_id === vehicle.id)
                          .map((m) => ({
                            ...m,
                            year: vehicle.year,
                            vin: vehicle.vin,
                            series: vehicle.series,
                            make: vehicle.make,
                            model: vehicle.model,
                            checksum_results: vehicle.checksum_results,
                            nhtsa_results: vehicle.nhtsa_results,
                          })),
                    }}
                    fetchData={fetchData}
                  />
                </Card>
              ))}
            </>
          }
        />
        <Route
          path="milestones"
          element={
            <Card
              mt="lg"
              p="xl"
              style={{
                background: "var(--mantine-color-dark-7)",
              }}
            >
              <UserMilestones
                loading={false}
                setAppLoading={() => true}
                reqData={{ campaign_effort_id: effortId, user_id: userId }}
                administrationData={{
                  campaign_effort_id: effortId,
                  user_id: userId,
                }}
              />
            </Card>
          }
        />
        <Route
          path="standings"
          element={
            <TieredContestUserStanding
              contestId={info.ride_challenge_contest_id}
              userId={userId}
            />
          }
        />
      </Routes>
      {/* <Text mb="sm">
        Ride Challenge Data Points: {info.ride_challenge_data_points.length}
      </Text>
      {rideChallengeDataPoints.map((datapoint) => {
        return (
          <Box mb="sm">
            <Text fw={600} key={datapoint.id}>
              {datapoint.odometer_value} miles
            </Text>
            {datapoint.user_vehicle && (
              <Text size="sm" c="dimmed">
                {datapoint.user_vehicle.year} {datapoint.user_vehicle.make}{" "}
                {datapoint.user_vehicle.model}
                {datapoint.user_vehicle.series
                  ? ` ${datapoint.user_vehicle.series}`
                  : ""}
              </Text>
            )}
            <Text size="sm" c="dimmed">
              {new Date(datapoint.created_at).toLocaleString()}
            </Text>
          </Box>
        );
      })} */}
    </>
  );
};

export default RideChallengeInteractionDetail;

const StyledForm = styled.div`
  label {
    color: var(--mantine-color-gray-0);
  }
`;
