import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  Flex,
  Text,
  Box,
  ActionIcon,
  rem,
  Badge,
  Card,
  Divider,
  SegmentedControl,
  UnstyledButton,
  Switch,
} from "@mantine/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { IconCaretUpFilled, IconCaretDownFilled } from "@tabler/icons-react";

import { UserVehicleDatapoints } from "./";
import { DatapointTable } from "./UserVehicleDatapoints";
import UnverifiedItems from "@components/RideChallengeApp/UnverifiedItems";

const UserVehicleItem = ({ vehicle, fetchData }) => {
  const [showDatapointAdd, setShowDatapointAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [datapoints, setDatapoints] = useState([]);
  const [filters, setFilters] = useState({
    variety: "odometer",
    status: "active",
  });
  const [showUnverified, setShowUnverified] = useState(false);
  const managerInfo = useSelector((state) => state.manager);
  const adminInfo = useSelector((state) => state.admin);

  useEffect(() => {
    if (!isOpen) return;
    fetchDatapoints();
  }, [isOpen, JSON.stringify(filters)]);

  function fetchDatapoints() {
    const req = {
      ...filters,
      user_vehicle_id: vehicle.id,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/user-vehicles/${vehicle.id}/retrieve-data-points/`, req)
      .then(({ data }) => {
        setDatapoints(
          data.response.map((m) => ({
            ...m,
            odometer_value: m.odometer_rolled
              ? m.odometer_value > 1000000
                ? m.odometer_value - 1000000
                : m.odometer_value
              : m.odometer_value,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setDatapoints([]);
        setError(err);
        setLoading(false);
      });
  }

  const mostRecentReading = datapoints.length ? datapoints[0] : null;
  const unverifiedItems = vehicle.unverified_ride_challenge_data_points;
  const formattedVin = vehicle.vin ? vehicle.vin.toUpperCase() : "Missing VIN";

  return (
    <div>
      <Flex gap="sm" align="center">
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Flex gap="xs" align="center">
            <Text fw={600} key={vehicle.id}>
              {vehicle.year} {vehicle.make} {vehicle.model}
              {vehicle.series ? ` ${vehicle.series}` : ""}
            </Text>
            {vehicle.status === 2 && <Badge color="red">INACTIVE</Badge>}
            {/* {unverifiedItems.length > 0 && (
              <Badge color="yellow" size="xs" variant="light">
                Needs Attention
              </Badge>
            )} */}
          </Flex>
          <Text size="sm" c="dimmed">
            VIN: {formattedVin}
          </Text>
          <Text size="sm" c="dimmed">
            {vehicle.odometer_total} mi{" "}
            {vehicle.odometer_rolled ? "(rolled)" : ""}
          </Text>
          <Text size="sm" c="dimmed">
            added at {vehicle.added_at_location_id} on{" "}
            {new Date(vehicle.created_at).toLocaleString()}
          </Text>
        </div>
        <div>
          <ActionIcon
            onClick={() => setOpen(!isOpen)}
            size="lg"
            variant="light"
            color="gray"
            radius="xl"
          >
            {isOpen ? (
              <IconCaretUpFilled style={{ width: rem(16), height: rem(16) }} />
            ) : (
              <IconCaretDownFilled
                style={{ width: rem(16), height: rem(16) }}
              />
            )}
          </ActionIcon>
        </div>
      </Flex>
      {isOpen && (
        <div
          style={{
            minHeight: "125px",
          }}
        >
          {unverifiedItems.length > 0 && (
            <Box mt="lg">
              <UnstyledButton
                onClick={() => setShowUnverified(!showUnverified)}
                component={Text}
                fw={600}
                mb="sm"
              >
                Unverified Items ({unverifiedItems.length}){" "}
                {showUnverified ? (
                  <IconCaretUpFilled size={12} />
                ) : (
                  <IconCaretDownFilled size={12} />
                )}
              </UnstyledButton>
              {showUnverified && (
                <Card
                  p="sm"
                  style={{
                    background: "var(--mantine-color-dark-7)",
                  }}
                >
                  <UnverifiedItems
                    fetchData={() => {
                      fetchData();
                      fetchDatapoints();
                    }}
                    items={unverifiedItems}
                    additionalReqData={{}}
                    administrationData={{
                      admin_id: adminInfo?.admin_id,
                      manager_id: managerInfo?.manager_id,
                      location_id: managerInfo?.location_id,
                      organization_id: managerInfo?.organization_id,
                    }}
                    verificationCode={{}}
                  />
                </Card>
              )}
              <Divider mt="lg" mb="lg" />
            </Box>
          )}
          {error && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: "125px",
              }}
            >
              <Text c="red" fw={600}>
                {error}
              </Text>
            </div>
          )}
          {datapoints && (
            <>
              {!managerInfo && (
                <>
                  {mostRecentReading && (
                    <Box mt="lg" mb="xl">
                      <Text fw={600}>Most Recent Datapoint</Text>
                      <DatapointTable
                        datapoints={[mostRecentReading]}
                        fetchData={() => {
                          fetchDatapoints();
                          fetchData();
                        }}
                        showStatus
                        showEdit
                        vehicleStatus={vehicle.status}
                      />
                      <Divider mt="lg" mb="lg" />
                    </Box>
                  )}
                </>
              )}
              <UserVehicleDatapoints
                datapoints={
                  mostRecentReading
                    ? datapoints.filter((f) => f.id !== mostRecentReading.id)
                    : datapoints
                }
                fetchData={() => {
                  fetchDatapoints();
                  fetchData();
                }}
                vehicleId={vehicle.id}
                userId={vehicle.user_id}
                showStatus={filters.status === "all"}
                effortId={vehicle.campaign_effort_id}
                vehicleStatus={vehicle.status}
                units={vehicle.use_kilometers ? "km" : "mi"}
              >
                <Box>
                  <SegmentedControl
                    size="xs"
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        status: e,
                      })
                    }
                    value={filters.status}
                    data={[
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                      { value: "all", label: "All" },
                    ]}
                  />
                </Box>
              </UserVehicleDatapoints>
            </>
          )}
          <Divider mt="lg" mb="lg" />
          <Flex>
            <div style={{ flexGrow: 1 }}>
              <RollOdometer
                vehicleId={vehicle.id}
                value={vehicle.odometer_rolled}
                fetchData={fetchData}
              />
            </div>
            <VehicleStatusToggle
              status={vehicle.status}
              onSuccess={fetchData}
              reqData={{
                user_vehicle_id: vehicle.id,
              }}
            />
          </Flex>
        </div>
      )}
    </div>
  );
};

export default UserVehicleItem;

const VehicleStatusToggle = ({ reqData, onSuccess, status }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function onSubmit() {
    setLoading(true);

    axios
      .post(`/user-vehicle/toggle-active/`, reqData)
      .then(() => {
        setLoading(false);
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setLoading(true);
        toast.error(err);
      });
  }

  return (
    <>
      <Flex justify="flex-end">
        <Button
          onClick={() => {
            status === 2 ? onSubmit() : setOpen(true);
          }}
          size="xs"
          color="gray"
          variant="subtle"
        >
          {status === 2 ? "Activate" : "Deactivate"} Vehicle
        </Button>
      </Flex>
      <Modal opened={isOpen} onClose={onClose}>
        <Text align="center">
          Are you sure you want to deactivate this vehicle?{" "}
          <b>This has huge implications.</b>
        </Text>
        <Button onClick={onClose} fullWidth color="red" mt="lg">
          No. Get me out of here!
        </Button>
        <Flex justify="center">
          <Button
            onClick={onSubmit}
            mt="sm"
            size="xs"
            color="gray"
            variant="subtle"
          >
            Deactivate the vehicle.
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

const RollOdometer = ({ fetchData, value, vehicleId }) => {
  function toggleRoll() {
    axios
      .post(`/user-vehicles/toggle-rolled-odometer/`, {
        user_vehicle_id: vehicleId,
      })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Switch
      size="xs"
      label="Rolled Odometer"
      onChange={() => toggleRoll()}
      checked={value}
    />
  );
};
