import React from "react";
import { Group } from "@mantine/core";
import { shallow } from "zustand/shallow";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import useAppStore from "./effort-point-settings-store";

export default function Keywords({ onUpdate }) {
  const { settings } = useAppStore(
    (state) => ({
      settings: state.settings,
    }),
    shallow
  );

  const counterFields = [
    {
      title: "Use Total",
      keyName: "keyword_use_total",
    },
    { title: "Value", keyName: "keyword_value", unlimited: false },
    {
      title: "Total Entries Allowed",
      keyName: "total_entries_allowed",
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: settings.keywords[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={c.unlimited}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      <Intervals
        items={settings.keywords.intervals}
        title="Keyword Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
