import React from "react";
import { Switch, Divider, Group, NumberInput } from "@mantine/core";
import { shallow } from "zustand/shallow";

import Intervals from "./Intervals";
import useAppStore from "./location-settings-store";

export default function Checkins({ onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  return (
    <div>
      <Switch
        label="Allow Check-ins"
        checked={settings.check_ins_enabled}
        onChange={() =>
          onUpdate({
            check_ins_enabled: !settings.check_ins_enabled,
          })
        }
        mb="sm"
      />
      {settings.check_ins_enabled && (
        <React.Fragment>
          <Switch
            label="Allow Check-ins at this location"
            checked={settings.allow_check_in_this_location}
            onChange={() =>
              onUpdate({
                allow_check_in_this_location:
                  !settings.allow_check_in_this_location,
              })
            }
            mb="sm"
          />
          <Group>
            <NumberInput
              value={settings.check_in_radius}
              onBlur={(e) =>
                onUpdate({
                  check_in_radius: parseFloat(e.target.value),
                })
              }
              precision={3}
            />
            <h3>Check in radius</h3>
          </Group>
          {/* {counterFields.map((c) => (
            <Group key={c.key}>
              <Counter
                value={c.value}
                unlimited={c.unlimited}
                onChange={(e) =>
                  onUpdate({
                    [c.keyName]: e,
                  })
                }
              />
              <h3 style={{ margin: "0" }}>{c.title}</h3>
            </Group>
          ))} */}
          <Divider mt="lg" mb="lg" />
          <Intervals
            items={settings.intervals}
            title="Check-In Interval"
            onChange={(e) => onUpdate({ intervals: e })}
          />
        </React.Fragment>
      )}
    </div>
  );
}
