import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Speedometer({ value = 0, maxValue = 200 }) {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          backgroundColor: "transparent",
          type: "gauge",
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          // marginBottom: -20,
          height: "300px",
          style: {
            fontFamily: "inherit",
            backgroundColor: "transparent",
            borderRadius: "2px",
          },
        },

        title: null,

        pane: {
          startAngle: -90,
          endAngle: 89.9,
          background: null,
          center: ["50%", "80%"],
          size: "130%",
        },
        plotOptions: {
          series: {
            groupPadding: 0,
          },
        },
        // the value axis
        yAxis: {
          min: 0,
          max: maxValue,
          tickPixelInterval: 72,
          tickPosition: "inside",
          tickColor: "var(--mantine-color-dark-8)",
          tickLength: 20,
          tickWidth: 2,
          minorTickInterval: null,
          labels: {
            distance: 20,
            style: {
              fontSize: "12px",
              color: "var(--mantine-color-gray-6)",
            },
          },
          title: {
            enabled: false,
            text: "",
          },
          lineWidth: 0,
          plotBands: [
            {
              from: 0,
              to: maxValue * 0.6,
              color: "var(--mantine-color-green-5)", // green
              thickness: 20,
              borderRadius: "50%",
            },
            {
              from: maxValue * 0.75,
              to: maxValue,
              color: "var(--mantine-color-red-5)", // yellow
              thickness: 20,
              borderRadius: "50%",
            },
            {
              from: maxValue * 0.55,
              to: maxValue * 0.8,
              color: "var(--mantine-color-yellow-5)", // red
              thickness: 20,
              // borderRadius: "50%",
            },
          ],
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },
        series: [
          {
            name: "",
            data: [value >= maxValue ? maxValue : value],
            dataLabels: {
              enabled: false,
            },
            dial: {
              radius: "80%",
              backgroundColor: "var(--mantine-color-gray-4)",
              baseWidth: 12,
              baseLength: "0%",
              rearLength: "0%",
            },
            pivot: {
              backgroundColor: "var(--mantine-color-gray-4)",
              radius: 8,
            },
          },
        ],
      }}
    />
  );
}
