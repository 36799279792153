import React, { useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { Button, TextInput, Space } from "@mantine/core";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import useAppStore from "./wizard-store";
import { initialConfig as initialMicrositeConfig } from "@components/shared/MicrositeSettings/helpers";
import {
  initialState as effortInitialSettings,
  generateEffortRebounds,
} from "@components/Effort/EffortSettings/helpers";
import {
  effortWizardSettings,
  defaultEffortConfig,
} from "@components/Effort/helpers";
import { contestWizardSettings } from "@components/Contest/helpers";
import { initialPrizeSettings } from "@components/Prize/helpers";
import { initialState as initialContestSettings } from "@components/Contest/ContestSettings/helpers";
import { rideChallengeSettings } from "@components/RideChallenge/helpers";

export default function WizardRideChallenge({ locationId, organizationId }) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { campaignSettings, setCampaignSettings } = useAppStore(
    (state) => ({
      campaignSettings: state.campaignSettings,
      setCampaignSettings: state.setCampaignSettings,
    }),
    shallow
  );

  function onSubmit() {
    const req = {
      create_ride_challenge: {
        name: campaignSettings.title,
        location_id: locationId,
        organization_id: organizationId,
        variety: 4,
        settings: {
          ...initialContestSettings,
          gauge_max_value: 10000,
        },
        wizard_settings: {
          ...contestWizardSettings,
          dates_required: true,
          entry_cost_required: false,
          featured_image_required: true,
          locations_required: false,
          prize_required: true,
        },
        prize: {
          settings: initialPrizeSettings,
          name: `${campaignSettings.title} Prize`,
          // description: "A description of this prize",
          description: "",
        },
        create_effort: {
          title: `${campaignSettings.title}`,
          recurring_engagement: 1,
          award_type: 4,
          variety: 6,
          location_id: locationId,
          organization_id: organizationId,
          draft_app_configuration: initialMicrositeConfig,
          draft_configuration: defaultEffortConfig,
          settings: {
            ...effortInitialSettings,
            ...generateEffortRebounds(campaignSettings.title),
            ...rideChallengeSettings,
          },
          wizard_settings: {
            ...effortWizardSettings,
            contest_required: true,
            look_and_feel_required: true,
            recurring: true,
            use_keywords: true,
            show_assets: true,
            show_award_type: false,
            show_awards: true,
            show_builder: true,
            show_csv: false,
            show_geospots: false,
            show_interaction_type: false,
            show_keywords: true,
            show_look_feel: true,
            show_quick_settings: true,
            show_settings: true,
          },
        },
      },
    };

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        setLoading(false);
        navigate(`/efforts/${data.response[0].campaign_effort_id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled = !campaignSettings.title ? true : false;

  return (
    <div>
      <TextInput
        label="Title"
        maxLength={100}
        value={campaignSettings.title}
        onChange={(e) =>
          setCampaignSettings({
            ...campaignSettings,
            title: e.target.value,
          })
        }
      />
      <Button
        mt="sm"
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={submitDisabled}
      >
        Create
      </Button>
    </div>
  );
}
