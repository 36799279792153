import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import styled from "styled-components";
import { motion } from "framer-motion";

import { LoyaltyMembership } from "@components/LoyaltyMembership";
import ViewTitle from "./ViewTitle";
import hogLogo from "@assets/images/hog-logo-color.png";

import useAppStore from "./ride-challenge-store";

export default function DashboardMembership({ children }) {
  const { coords, effortId, location, entityInfo, setAppLoading, user } =
    useAppStore(
      (state) => ({
        coords: state.coords,
        effortId: state.effortId,
        entityInfo: state.entityInfo,
        location: state.location,
        user: state.user,
        setAppLoading: state.setAppLoading,
      }),
      shallow
    );
  const [membership, setMembership] = useState(null);

  useEffect(() => {
    fetchMembership();
  }, []);

  function fetchMembership() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: entityInfo.location?.id,
      user_id: user.id,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/retrieve-hog-membership/`, req)
      .then(({ data }) => {
        setMembership(data.response[0]);
        setAppLoading(false);
      })
      .catch((err) => {
        setMembership(null);
        setAppLoading(false);
      });
  }

  return (
    <div>
      <StyledLogo
        src={hogLogo}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
      {!membership && (
        <ViewTitle
          title=""
          subtitle={`Harley Owners Group and Passenger (formerly Associate members), add your Member ID here`}
        />
      )}
      <LoyaltyMembership
        codeVerificationData={{
          ...coords,
          user_location_id: user?.user_location_id,
          campaign_effort_id: effortId,
          location_id: location?.id,
          user_id: user?.id,
        }}
        membership={membership}
        additionalReqData={{
          check: true,
          user_id: user.id,
          user_location_id: user.user_location_id,
          variety: 1,
          campaign_effort_id: effortId,
        }}
        fetchData={fetchMembership}
      >
        {children}
      </LoyaltyMembership>
    </div>
  );
}

const StyledLogo = styled(motion.img)`
  max-width: 300px;
  margin: 1.5em auto 1em auto;
  display: block;
`;
