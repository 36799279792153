import React from "react";
import { UnstyledButton, Group, Flex, Avatar, Text } from "@mantine/core";
import { Link } from "react-router-dom";

import { LogoutButton } from "@components/Auth";

export default function Nameplate({
  title = "Name",
  subtitle = "",
  avatarUrl,
  linkUrl = "/profile",
}) {
  const initials = getFirstLetters(title);

  return (
    <Group>
      <UnstyledButton component={Link} to={linkUrl}>
        <Flex gap="xs" align="center">
          <Avatar size={40} radius="100%" color="blue" src={avatarUrl}>
            {avatarUrl === null && `${initials}`}
          </Avatar>
          <div>
            <Text c="gray.2" size="sm" fw={600}>
              {title}
            </Text>
            {subtitle && (
              <Text size="xs" c="dimmed">
                {subtitle}
              </Text>
            )}
          </div>
        </Flex>
      </UnstyledButton>
      <LogoutButton />
    </Group>
  );
}

function getFirstLetters(str) {
  const firstLetters = str
    .split(" ")
    .map((word) => word[0])
    .join("");

  return firstLetters;
}
