import { create } from "zustand";

const useAppStore = create((set) => ({
  effortState: {
    description: "",
    fields: [],
    title: "",
  },
  setEffortState: (effortState) => set({ effortState }),
}));

export default useAppStore;
