import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  TextInput,
  Text,
  Title,
  Flex,
  Loader,
  PinInput,
  Radio,
  Stack,
  Input,
  Anchor,
  Box,
  NumberFormatter,
} from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import { shallow } from "zustand/shallow";

import { OdometerForm } from "@components/RideChallengeApp/VehicleVerificationView";

import useAppStore from "@components/RideChallengeApp/ride-challenge-store";

export default function VinSetup({
  additionalReqData,
  onSuccess,
  isAdditionalVehicle = false,
  verificationCode,
}) {
  const [vinInfo, setVinInfo] = useState(null);
  const [odometerInfo, setOdometerInfo] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    if (odometerInfo) {
      createReading();
    }
  }, [JSON.stringify(odometerInfo)]);

  function createReading() {
    const req = {
      ...additionalReqData,
      is_passenger: vinInfo.is_passenger,
      is_rental: vinInfo.is_rental,
      use_kilometers: vinInfo.use_kilometers,
      make: vinInfo.make,
      vin: vinInfo.vin,
      year: vinInfo.year,
      model: vinInfo.model,
      odometer_value: odometerInfo.value,
    };

    setSubmitting(false);

    const route = isAdditionalVehicle
      ? `/ride-challenge/submit-new-vehicle/`
      : `/ride-challenge/submit-initial-reading/`;

    axios
      .post(route, req)
      .then(({ data }) => {
        setSubmitting(false);
        setVinInfo(null);
        setOdometerInfo(null);
        onSuccess({
          ...req,
          ride_challenge_data_point: data.response[0],
        });
      })
      .catch((err) => {
        setSubmitting(false);
        setSubmitError(err);
      });
  }

  if (odometerInfo) {
    if (submitError) {
      return (
        <div>
          <Text align="center" c="white">
            {submitError}
          </Text>
          <Flex justify="center" mt="xl">
            <Button
              onClick={() => {
                setSubmitError(null);
                setOdometerInfo(null);
                setVinInfo(null);
              }}
            >
              Retry
            </Button>
          </Flex>
        </div>
      );
    }

    return (
      <div>
        {submitting ? (
          <Flex justify="center" align="center" gap="sm">
            <Loader />
            <Text c="white" size="xs">
              Generating next steps...
            </Text>
          </Flex>
        ) : (
          <Flex direction="column" justify="center" align="center" gap="sm">
            <Loader />
            <Text c="white" size="sm" fw={600} mt="xs">
              Thank you for your patience. This may take up to 15 seconds.
            </Text>
          </Flex>
        )}
      </div>
    );
  }

  if (vinInfo) {
    return (
      <div>
        <OdometerReading
          onSuccess={(e) => setOdometerInfo(e)}
          units={vinInfo.use_kilometers ? "km" : "mi"}
        />
      </div>
    );
  }

  return (
    <div>
      <VinForm onSuccess={(newVinInfo) => setVinInfo(newVinInfo)} />
    </div>
  );
}

export function VinForm({ onSuccess, isOverride, originalData }) {
  const { coords, effortId, entityInfo, setAppLoading, user } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      setAppLoading: state.setAppLoading,
      user: state.user,
    }),
    shallow
  );
  const [formValues, setFormValues] = useState({
    vin: "",
    year: "",
    is_passenger: false,
    is_rental: false,
    use_kilometers: false,
  });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const disabledVins = vehicles.map((v) => v.vin);

  useEffect(() => {
    fetchVehicles();
  }, []);

  function fetchVehicles() {
    if (!user) return;
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: entityInfo.location?.id,
      user_id: user.id,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/retrieve-vehicles-for-user/`, req)
      .then(({ data }) => {
        setVehicles(
          data.response.sort((a, b) => b.odometer_total - a.odometer_total)
        );
        setAppLoading(false);
      })
      .catch((err) => {
        setVehicles([]);
        setAppLoading(false);
      });
  }

  useEffect(() => {
    if (originalData) {
      setFormValues({
        ...formValues,
        vin: originalData.vin || "",
        year: originalData.year || "",
        is_passenger: originalData.is_passenger || false,
        is_rental: originalData.is_rental || false,
        use_kilometers: originalData.use_kilometers || false,
      });
    }
  }, [JSON.stringify(originalData)]);

  const options = generateOptions(1950);

  function onConfirm() {
    setConfirm(true);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
      make: "HARLEY-DAVIDSON",
    };

    onSuccess(req);
  }

  const is1980OrOlder = formValues.year && parseInt(formValues.year) <= 1980;
  const needModel = isOverride || is1980OrOlder;
  const vinTaken = disabledVins.includes(formValues.vin.toLowerCase());

  const vinSubmitDisabled = vinTaken
    ? true
    : is1980OrOlder
    ? false
    : formValues.vin.length !== 17;

  if (confirm) {
    return (
      <div>
        <Text size="lg" mb="sm" c="white" align="center">
          Please confirm the following information is correct:
        </Text>
        <Title c="white" align="center">
          {formValues.year}
          {formValues.model ? ` ${formValues.model}` : ""}
        </Title>
        <StyledVinConfirm>
          <Text c="white" fw={600} align="center" size="lg">
            {formValues.vin?.toUpperCase()}
          </Text>
        </StyledVinConfirm>
        <Text mt="lg" size="lg" mb="lg" c="white" align="center">
          &bull; I'm {formValues.is_passenger ? "a passenger" : "a rider"}
          <br />
          &bull; I
          {formValues.is_rental
            ? "'m renting the Motorcycle"
            : " own the Motorcycle"}
          <br />
          &bull; My Motorcycle's odometer is in{" "}
          {formValues.use_kilometers ? "kilometers" : "miles"}
        </Text>
        <Flex
          justify="center"
          mt="md"
          gap="sm"
          direction={{
            base: "column",
            xs: "row",
          }}
        >
          <Button
            disabled={!formValues.vin || !formValues.year}
            onClick={onSubmit}
            loading={loading}
          >
            Looks good!
          </Button>
          <Button
            disabled={loading}
            onClick={() => setConfirm(false)}
            variant="light"
            color="gray"
          >
            I need to change something
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Input.Wrapper label="Rider or Passenger" mt="lg">
          <Stack gap="xs" mt="xs">
            <Radio
              label="I'm a rider"
              checked={formValues.is_passenger === false}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  is_passenger: false,
                })
              }
            />
            <Radio
              label="I'm a passenger"
              checked={formValues.is_passenger === true}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  is_passenger: true,
                })
              }
            />
          </Stack>
        </Input.Wrapper>
        <Input.Wrapper label="Motorcycle Ownership" mt="lg">
          <Stack gap="xs" mt="xs">
            <Radio
              label="I own the Motorcycle"
              checked={formValues.is_rental === false}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  is_rental: false,
                })
              }
            />
            <Radio
              label="I'm renting the Motorcycle"
              checked={formValues.is_rental === true}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  is_rental: true,
                })
              }
            />
          </Stack>
        </Input.Wrapper>
        <Input.Wrapper label="Your odometer" mt="lg" mb="lg">
          <Stack gap="xs" mt="xs">
            <Radio
              label="My odometer is in miles"
              checked={formValues.use_kilometers === false}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  use_kilometers: false,
                })
              }
            />
            <Radio
              label="My odometer is in kilometers"
              checked={formValues.use_kilometers === true}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  use_kilometers: true,
                })
              }
            />
          </Stack>
        </Input.Wrapper>
        <Select
          label="Model Year"
          data={options}
          placeholder="Select one"
          value={formValues.year}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              year: e,
              model: "",
            })
          }
        />
        <Box mt="sm" mb="sm">
          <StyledVinInput>
            <TextInput
              label="VIN"
              value={formValues.vin}
              onChange={(e) => {
                if (
                  is1980OrOlder &&
                  e.target.value.length <= 17 &&
                  new RegExp(/^[A-Za-z0-9]*$/).test(e.target.value)
                ) {
                  setFormValues({
                    ...formValues,
                    vin: e.target.value.toUpperCase(),
                  });
                } else if (
                  new RegExp(/^[A-Za-z0-9]*$/).test(e.target.value) &&
                  e.target.value.length <= 17
                ) {
                  setFormValues({
                    ...formValues,
                    vin: e.target.value.toUpperCase(),
                  });
                } else {
                }
              }}
            />
          </StyledVinInput>
          {vinTaken && (
            <Text mt="sm" size="sm" fw={600} c="red">
              You've already entered this VIN.
            </Text>
          )}
          <Flex gap="xs" mt="xs">
            {/* <Anchor
              size="xs"
              target="_blank"
              href="https://harley-davidson.com"
            >
              Where's my VIN?
            </Anchor> */}
            <Text c="white" size="xs">
              {is1980OrOlder
                ? `${formValues.vin.length} characters entered`
                : `${formValues.vin.length}/17 characters entered`}
            </Text>
          </Flex>
        </Box>
        {needModel && (
          <ModelSelect
            label="Model"
            value={formValues.model}
            reqData={{
              year: formValues.year,
            }}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                model: e,
              })
            }
          />
        )}
        <Button
          mt="lg"
          disabled={
            !formValues.vin ||
            !formValues.year ||
            (needModel && !formValues.model) ||
            vinSubmitDisabled
          }
          onClick={onConfirm}
          loading={loading}
          fullWidth
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

const generateOptions = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years
    .sort((a, b) => b - a)
    .map((m) => ({
      label: `${m}`,
      value: `${m}`,
    }));
};

function OdometerReading({ onSuccess, units }) {
  const [formValues, setFormValues] = useState({
    value: "",
  });
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  function onConfirm() {
    setConfirm(true);
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formValues,
    };

    onSuccess(req);
    setConfirm(false);
  }

  if (confirm) {
    return (
      <div>
        <Text align="center" c="white" mb="lg">
          Please confirm your odometer reading:
        </Text>
        <Title align="center" c="white" order={1}>
          <NumberFormatter
            thousandSeparator
            value={formValues.value.replace(/^0+/, "")}
          />{" "}
          {units}
        </Title>
        <Flex
          direction={{
            base: "column",
            xs: "row",
          }}
          justify="center"
          mt="xl"
          gap="sm"
        >
          <Button
            disabled={!formValues.value}
            onClick={onSubmit}
            loading={loading}
          >
            Looks good!
          </Button>
          <Button
            disabled={loading}
            onClick={() => setConfirm(false)}
            variant="light"
            color="gray"
          >
            I need to change something
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <>
      <OdometerForm
        value={formValues.value}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            value: e,
          })
        }
      />
      <Flex justify="center" mt="lg">
        <Button
          disabled={!formValues.value}
          onClick={onConfirm}
          loading={loading}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
}

function ModelSelect({ value, onChange, reqData }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!reqData || !reqData.year) return;
    fetchOptions();
  }, [JSON.stringify(reqData)]);

  function fetchOptions() {
    const req = {
      ...reqData,
    };

    axios
      .post(`/ride-challenge/legacy-models/`, req)
      .then(({ data }) => {
        setOptions(
          data.response[0].models.map((d) => ({
            label: d,
            value: d,
          }))
        );
      })
      .catch((err) => {
        setOptions([]);
      });
  }

  return (
    <Select
      label="Model"
      value={value}
      onChange={(e) => onChange(e)}
      data={options}
      placeholder="Select one..."
    />
  );
}

const StyledVinInput = styled.div`
  input {
    text-transform: uppercase;
    font-size: 1.25em;
    letter-spacing: 0.25em;
  }
`;

const StyledVinConfirm = styled.div`
  letter-spacing: 0.25em;
`;
