import Compressor from "compressorjs";

export default function compressImages(files) {
  return new Promise(function (resolve, reject) {
    let compressedImages = [];

    files.forEach((file, i) => {
      if (!file) return;
      new Compressor(file.file, {
        convertSize: 250000,
        success(result) {
          compressedImages.push({
            id: file.id,
            name: result.name,
            file: result,
          });
          if (i + 1 === files.length) {
            resolve(compressedImages);
          }
        },
      });
    });
  });
}
