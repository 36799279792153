import React from "react";
import { Group } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { Counter } from "@components/shared";
import useAppStore from "./keyword-settings-store";

export default function GeneralSettings({ onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const counterFields = [
    {
      title: "Point Value",
      keyName: "point_value",
      value: settings.point_value,
      max: 20,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={false}
            maxValue={c.max}
            onChange={(e) => {
              if (e > 20) {
                onUpdate({
                  [c.keyName]: 20,
                });
              } else {
                onUpdate({
                  [c.keyName]: e,
                });
              }
            }}
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      {/* <Switch
        mt="lg"
        label="Member only"
        checked={memberOnly}
        onChange={() =>
          onUpdate({
            members_only: !memberOnly,
          })
        }
      /> */}
    </div>
  );
}
