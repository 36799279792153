import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";

export default function MilestoneForm({
  title = "",
  description = "",
  value = "",
  multipleAward = false,
  requiresPickup = false,
  startAt = "",
  endAt = "",
  id = null,
  onSuccess = () => {},
  reqData,
  variety = "",
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    const formatted = {
      ...formData,
    };

    if (
      (formData.start_at && !formData.end_at) ||
      (!formData.start_at && formData.end_at)
    ) {
      return alert("Please finish adding dates");
    }

    if (!formData.start_at || !formData.end_at) {
      formatted.ongoing = true;
    } else {
      formatted.ongoing = false;
    }

    setLoading(true);

    if (!id) return onCreate(formatted);

    return onUpdate(formatted);
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      milestone_id: id,
    };

    axios
      .put(`/milestones/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Updated!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onCreate(formData) {
    const req = {
      ...reqData,
      ...formData,
    };

    axios
      .post(`/milestones/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Created!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "title",
      label: "Title",
      initialValue: title,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "description",
      label: "Short description (max 255 characters)",
      initialValue: description,
      required: true,
      textarea: true,
      schema: () => Yup.string().max(255, "Too long!").required("Required"),
    },
    {
      name: "variety",
      label: "Variety",
      initialValue: variety,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
      select: true,
      options: [
        { label: "Mileage", value: "1" },
        { label: "Odometer Readings", value: "2" },
        { label: "Unique Dealer Visits", value: "3" },
        { label: "Keyword Claims", value: "4" },
      ],
    },
    {
      name: "value",
      label: "Value",
      initialValue: value,
      required: true,
      schema: () =>
        Yup.string()
          .matches(
            /^(?=.+)(?:[1-9]\d*)?(?:(\.\d+)|(0\.\d*[1-9]+\d*))?$/,
            "Numbers only"
          )
          .required("Required"),
    },
    // {
    //   name: "multiple_award",
    //   label: "Can be awarded multiple times",
    //   initialValue: multipleAward,
    //   required: true,
    //   schema: () => null,
    //   toggle: true,
    // },
    {
      name: "requires_pickup",
      label: "Requires pickup",
      initialValue: requiresPickup,
      required: true,
      schema: () => null,
      toggle: true,
    },
    {
      name: "start_at",
      label: "Start (leave blank if ongoing)",
      initialValue: startAt,
      required: false,
      schema: () => null,
      datepicker: {},
    },
    {
      name: "end_at",
      label: "End (leave blank if ongoing)",
      initialValue: endAt,
      required: false,
      schema: () => null,
      datepicker: {},
    },
  ];

  return (
    <div>
      <BasicForm
        buttonProps={{
          fullWidth: true,
        }}
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
      />
    </div>
  );
}
