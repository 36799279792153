import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

import "./Odometer.css";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const getFontSize = (width) => {
  if (width >= 768) return "47px";
  return "36px";
};

const Odo = ({ newValue }) => {
  const [value, setValue] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(newValue), 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [newValue]);

  return (
    <Odometer
      value={value}
      format="d"
      style={{
        fontSize: getFontSize(width),
        fontFamily: `DS-Digital, monospace`,
      }}
    />
  );
};

export default Odo;
