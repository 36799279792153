import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { shallow } from "zustand/shallow";
import {
  Button,
  Modal,
  Text,
  Radio,
  Textarea,
  TextInput,
  ActionIcon,
  Select,
} from "@mantine/core";
import { IconHelp } from "@tabler/icons-react";
import axios from "axios";
import toast from "react-hot-toast";

import { emailValidation, phoneValidation } from "@util/validation";
import useAppStore from "./ride-challenge-store";

export default function DealerMode({
  border = true,
  children,
  dealerCodeData,
}) {
  return (
    <StyledDealerMode border={border}>
      {dealerCodeData && (
        <Text align="center" size="xs" c="dimmed">
          Validator: {dealerCodeData.first_name} {dealerCodeData.last_name}
        </Text>
      )}
      <>{children}</>
      <div className="help">
        <NeedHelp dealerCodeData={dealerCodeData} />
      </div>
    </StyledDealerMode>
  );
}

const StyledDealerMode = styled.div`
  background: var(--mantine-color-dark-8);
  padding: 1em 0.5em;
  border-radius: 0.25em;
  border: ${(props) =>
    props.border ? `1px solid var(--mantine-color-dark-6)` : "none"};
  position: relative;

  .help {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const initialFormValues = {
  description: "",
  preferred_contact: "phone",
  email: "",
  phone: "",
  nature: "",
  vin: "",
};

const NeedHelp = ({ dealerCodeData }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [vehicles, setVehicles] = useState([]);

  const { effortId, user, coords, entityInfo } = useAppStore(
    (state) => ({
      coords: state.coords,
      entityInfo: state.entityInfo,
      effortId: state.effortId,
      user: state.user,
    }),
    shallow
  );

  useEffect(() => {
    if (user && showModal) {
      fetchVehicles();
    }

    if (!showModal) {
      setFormValues({
        ...formValues,
        user_vehicle_id: "",
        nature: "",
        description: "",
      });
    }
  }, [showModal]);

  useEffect(() => {
    if (dealerCodeData) {
      setFormValues({
        ...formValues,
        // email: dealerCodeData.email,
        phone: dealerCodeData.number,
      });
    }
  }, [JSON.stringify(dealerCodeData)]);

  function fetchVehicles() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: entityInfo.location?.id,
      user_id: user.id,
    };

    axios
      .post(`/ride-challenge/retrieve-vehicles-for-user/`, req)
      .then(({ data }) => {
        const res = data.response.map((m) => ({
          ...m,
          vin: `${m.vin.toUpperCase()}`,
        }));

        setVehicles(res);

        if (res.length === 1) {
          setFormValues({
            ...formValues,
            user_vehicle_id: res[0].id,
          });
        }
      })
      .catch((err) => {
        setVehicles([]);
      });
  }

  function onClose() {
    setShowModal(false);
    setLoading(false);
  }

  function onSubmit() {
    // setLoading(true);

    const userVehicle = vehicles.find(
      (v) => v.id === formValues.user_vehicle_id
    );

    const req = {
      description: `${formValues.description}`,
      preferred_contact: formValues.preferred_contact,
      validator_email: formValues.email,
      validator_phone: formValues.phone || dealerCodeData.number,
      validator_first_name: dealerCodeData.first_name,
      validator_last_name: dealerCodeData.last_name,
      verifier_location_id: dealerCodeData.location_id,
      campaign_effort_id: effortId,
      nature: formValues.nature,
      user_id: user?.id,
      user_first_name: user?.first_name,
      user_last_name: user?.last_name,
      user_email: user?.email,
      user_mobile_phone: user?.mobile_phone,
      loyalty_membership_ident: user?.hog_membership_data?.ident,
      vin: userVehicle?.vin,
    };

    axios
      .post("/ride-challenge/verifier-help/", req)
      .then(({ data }) => {
        setLoading(false);
        setShowModal(false);
        toast.success("Help Request Submitted!");
        setFormValues({
          ...formValues,
          description: "",
          nature: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const vehicleOptions = vehicles.map((v) => ({
    label: v.vin,
    value: v.id,
  }));

  const submitDisabled =
    !formValues.description ||
    !formValues.nature ||
    !formValues.user_vehicle_id ||
    (formValues.preferred_contact === "email" && !formValues.email) ||
    (formValues.preferred_contact === "phone" && !formValues.phone) ||
    (formValues.preferred_contact === "text" && !formValues.phone) ||
    (formValues.preferred_contact === "email" &&
      !emailValidation.test(formValues.email)) ||
    (formValues.preferred_contact === "phone" &&
      !phoneValidation.test(formValues.phone)) ||
    (formValues.preferred_contact === "text" &&
      !phoneValidation.test(formValues.phone));

  return (
    <div>
      <ActionIcon
        onClick={() => setShowModal(true)}
        radius="xl"
        variant="subtle"
      >
        <IconHelp size={16} />
      </ActionIcon>
      <Modal opened={showModal} onClose={onClose}>
        {dealerCodeData && (
          <Text mb="xs" fw={600}>
            Hey {dealerCodeData.first_name}, how can we help?
          </Text>
        )}
        <Radio.Group
          label="Your Preferred contact method"
          withAsterisk
          onChange={(e) =>
            setFormValues({ ...formValues, preferred_contact: e })
          }
          value={formValues.preferred_contact}
        >
          {contactMethods.map((method, i) => (
            <Radio
              mt="xs"
              key={i}
              mb="sm"
              value={method.value}
              label={method.label}
            />
          ))}
        </Radio.Group>
        {formValues.preferred_contact === "email" && (
          <TextInput
            label="Validator's Email"
            mb="sm"
            withAsterisk
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            value={formValues.email}
          />
        )}
        {(formValues.preferred_contact === "phone" ||
          formValues.preferred_contact === "text") && (
          <TextInput
            label="Validator's Phone Number"
            withAsterisk
            mb="sm"
            onChange={(e) =>
              setFormValues({ ...formValues, phone: e.target.value })
            }
            value={formValues.phone}
          />
        )}
        {vehicleOptions.length > 1 && (
          <Select
            label="What VIN is the issue with?"
            withAsterisk
            mb="sm"
            data={vehicleOptions}
            onChange={(e) =>
              setFormValues({ ...formValues, user_vehicle_id: e })
            }
            value={formValues.user_vehicle_id}
            placeholder="Select a vehicle..."
          />
        )}
        <Select
          label="Nature of Issue"
          withAsterisk
          mb="lg"
          placeholder="Select one..."
          onChange={(e) => setFormValues({ ...formValues, nature: e })}
          value={formValues.nature}
          data={natureOptions}
        />
        <Textarea
          label="Issue Description"
          description="Please describe the issue you are experiencing."
          withAsterisk
          mb="lg"
          onChange={(e) =>
            setFormValues({ ...formValues, description: e.target.value })
          }
          value={formValues.description}
        />
        <Button
          disabled={submitDisabled}
          fullWidth
          mt="md"
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
};

const contactMethods = [
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Text",
    value: "text",
  },
];

const natureOptions = [
  {
    label: "VIN #",
    value: "VIN #",
  },
  {
    label: "Member ID#",
    value: "Member ID#",
  },
  {
    label: "Mileage Issue",
    value: "Mileage Issue",
  },
  {
    label: "Other",
    value: "Other",
  },
];
