import React, { useState, useEffect } from "react";
import { Group, Divider, Title, TextInput, NumberInput } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { Counter } from "@components/shared";

import useAppStore from "./effort-settings-store";

const isNum = (num) => new RegExp("^[0-9]+$").test(num);

export default function RideChallengeSettings({ onUpdate }) {
  const { settings } = useAppStore(
    (state) => ({
      settings: state.settings,
    }),
    shallow
  );

  const [tempValues, setTempValues] = useState({
    max_daily_mileage: "",
    vin_user_limit: "",
    daily_mileage_limit: "",
  });

  useEffect(() => {
    if (!settings.rev_score) return;

    setTempValues({
      ...tempValues,
      daily_mileage_limit:
        settings?.ride_challenge_settings?.daily_mileage_limit || "",
      max_daily_mileage:
        settings?.ride_challenge_warnings?.max_daily_mileage || "",
      vin_user_limit: settings?.ride_challenge_warnings?.vin_user_limit || "",
    });
  }, [JSON.stringify(settings)]);

  if (!settings.rev_score) return null;

  return (
    <div>
      {/* <Title order={4}>Keywords</Title>
      <TextInput
        label="Keyword Claim Message"
        value={rideChallengeText.keyword_claim_message}
        onChange={(e) =>
          setTempTextValues({
            ...tempTextValues,
            keyword_claim_message: e.target.value,
          })
        }
        onBlur={() => {
          onUpdate({
            ride_challenge_text: {
              ...rideChallengeText,
              keyword_claim_message: tempTextValues.keyword_claim_message,
            },
          });
        }}
      />
      <Divider mt="lg" mb="lg" /> */}
      <Title order={4}>Ride Challenge Settings</Title>
      <NumberInput
        label="Daily Mileage Limit"
        allowDecimal={false}
        allowNegative={false}
        allowLeadingZeros={false}
        value={tempValues.daily_mileage_limit}
        onChange={(e) =>
          setTempValues({
            ...tempValues,
            daily_mileage_limit: e,
          })
        }
        onBlur={() => {
          if (!isNum(tempValues.daily_mileage_limit)) {
            return setTempValues({
              ...tempValues,
              daily_mileage_limit: "",
            });
          }
          onUpdate({
            ride_challenge_settings: {
              ...settings.ride_challenge_settings,
              daily_mileage_limit: parseInt(tempValues.daily_mileage_limit),
            },
          });
        }}
      />
      <Divider mt="lg" mb="lg" />
      <Title order={4}>Warnings</Title>
      <NumberInput
        label="Max Daily Mileage"
        allowDecimal={false}
        allowNegative={false}
        allowLeadingZeros={false}
        value={tempValues.max_daily_mileage}
        onChange={(e) =>
          setTempValues({
            ...tempValues,
            max_daily_mileage: e,
          })
        }
        onBlur={() => {
          if (!isNum(tempValues.max_daily_mileage)) {
            return setTempValues({
              ...tempValues,
              max_daily_mileage: "",
            });
          }
          onUpdate({
            ride_challenge_warnings: {
              ...settings.ride_challenge_warnings,
              max_daily_mileage: parseInt(tempValues.max_daily_mileage),
            },
          });
        }}
      />
      <NumberInput
        label="VIN User Limit"
        allowDecimal={false}
        allowNegative={false}
        allowLeadingZeros={false}
        value={tempValues.vin_user_limit}
        onChange={(e) =>
          setTempValues({
            ...tempValues,
            vin_user_limit: e,
          })
        }
        onBlur={() => {
          if (!isNum(tempValues.vin_user_limit)) {
            return setTempValues({
              ...tempValues,
              vin_user_limit: "",
            });
          }
          onUpdate({
            ride_challenge_warnings: {
              ...settings.ride_challenge_warnings,
              vin_user_limit: parseInt(tempValues.vin_user_limit),
            },
          });
        }}
      />
      <Divider mt="lg" mb="lg" />
      <Group>
        <Counter
          unlimited={false}
          minValue={1}
          value={settings.rev_score.keywords_engagement_multiplier}
          onChange={(e) => {
            onUpdate({
              rev_score: {
                ...settings.rev_score,
                keywords_engagement_multiplier: e,
              },
            });
          }}
        />
        <Title order={3}>Keywords Engagement Multiplier</Title>
      </Group>
      <Group>
        <Counter
          unlimited={false}
          minValue={1}
          value={settings.rev_score.readings_engagement_multiplier}
          onChange={(e) => {
            onUpdate({
              rev_score: {
                ...settings.rev_score,
                readings_engagement_multiplier: e,
              },
            });
          }}
        />
        <Title order={3}>Readings Engagement Multiplier</Title>
      </Group>
      <Group>
        <Counter
          unlimited={false}
          minValue={1}
          value={settings.rev_score.locations_engagement_multiplier || 0}
          onChange={(e) => {
            onUpdate({
              rev_score: {
                ...settings.rev_score,
                locations_engagement_multiplier: e,
              },
            });
          }}
        />
        <Title order={3}>Locations Engagement Multiplier</Title>
      </Group>
    </div>
  );
}
