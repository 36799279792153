export const iconSize = 60;

export const validManufacturers = [
  "HARLEY-DAVIDSON DO BRASIL LTDA",
  "HARLEY-DAVIDSON MOTOR COMPANY",
  "HARLEY-DAVIDSON OFF-ROAD",
].map((m) => m.toLowerCase());

export function nhtsaMakeIsValid(results) {
  if (!results || JSON.stringify(results) === "{}") return false;
  const make = results
    .find((result) => result.Variable === "Make")
    ?.Value?.toLowerCase();

  const manufacturer = results
    .find((result) => result.Variable === "Manufacturer Name")
    ?.Value?.toLowerCase();

  const validMakes = ["harley-davidson", "harley", "livewire"].map((m) =>
    m.toLowerCase()
  );

  if (!make && !manufacturer) {
    return true;
  }

  if (!make) {
    return validManufacturers.includes(manufacturer);
  }

  return validMakes.includes(make);
}
