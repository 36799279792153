import React, { useState, useCallback, useRef, useEffect } from "react";
import update from "immutability-helper";
import { Button, Modal, Flex, Text } from "@mantine/core";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import { IconArrowsMove, IconDragDrop } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";

import useAppStore from "./effort-builder-store";

export default function EffortFieldReorder({ onUpdate }) {
  const [draftFields, setDraftFields] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const { fields } = useAppStore(
    (state) => ({
      fields: state.effortState.fields,
    }),
    shallow
  );

  useEffect(() => {
    setDraftFields(fields);
  }, [JSON.stringify(fields)]);

  function onClose() {
    setOpen(false);
  }

  function onSaveClick() {
    onClose();
    onUpdate(draftFields);
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setDraftFields((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.uuid}
        index={index}
        id={card.uuid}
        text={card.label}
        moveCard={moveCard}
      />
    );
  });

  return (
    <div>
      <Button
        leftSection={<IconArrowsMove />}
        onClick={() => setOpen(true)}
        variant="light"
        color="teal"
      >
        Reorder fields
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text mb="lg" size="md" align="center">
          Reposition fields by dragging{" "}
          <IconArrowsMove
            size={18}
            style={{ position: "relative", top: "5px" }}
          />{" "}
          and dropping{" "}
          <IconDragDrop
            size={18}
            style={{ position: "relative", top: "5px" }}
          />
        </Text>
        <DndProvider backend={HTML5Backend}>
          <div>{draftFields.map((card, i) => renderCard(card, i))}</div>
        </DndProvider>
        <Button mt="xl" fullWidth onClick={onSaveClick}>
          Save Order
        </Button>
      </Modal>
    </div>
  );
}

const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <StyledItem ref={ref} dragging={isDragging} data-handler-id={handlerId}>
      <Flex grow>
        <IconArrowsMove color="#000" />
        <Text ml="sm">{text}</Text>
      </Flex>
    </StyledItem>
  );
};

const StyledItem = styled.div`
  padding: 5px 3px;
  opacity: ${(props) => (props.dragging ? 0 : 1)};
  cursor: pointer;
  margin-bottom: 0.5rem;
  background-color: #fff;
  cursor: move;
  border: 1px dashed #eee;
`;
