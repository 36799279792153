import React, { useEffect, useState } from "react";
import { Button, Group, TextInput, Loader } from "@mantine/core";
import axios from "axios";
import { IconMapPin, IconMoodSad } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import ViewTitle from "./ViewTitle";
import ItemSlider from "./ItemSlider";
import { iconSize } from "./helpers";
import LocationSearch from "./LocationSearch";
import useAppStore from "./ride-challenge-store";
import ViewLoader from "./ViewLoader";

import generateStaticMap from "@util/generateStaticMap";

export default function LocationLocator({
  allLocations = false,
  buttonText = "Register Here",
  onLocationSelect,
  showTitle = true,
}) {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [zipValue, setZipValue] = useState("");

  const {
    appConfig,
    campaignId,
    coords,
    effortId,
    entityInfo,
    zip,
    setZip,
    setCoords,
    setLocation,
    setCoordsNoLocation,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      campaignId: state.campaignId,
      coords: state.coords,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      zip: state.zip,
      setZip: state.setZip,
      setCoords: state.setCoords,
      setLocation: state.setLocation,
      setCoordsNoLocation: state.setCoordsNoLocation,
    }),
    shallow
  );

  useState(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (!allLocations) return;

    fetchLocations();
  }, [zipValue]);

  function fetchLocations() {
    if (
      (allLocations && zipValue.length !== 5) ||
      (allLocations && zipValue.length !== 6) ||
      (allLocations && zipValue.length !== 7)
    ) {
      return;
    }

    setLoading(true);

    const req = {};

    if (campaignId) req.campaign_id = campaignId;
    if (effortId) req.campaign_effort_id = effortId;

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    if (zip) {
      req.zipcode = zip;
    }

    if (zipValue) {
      req.organizational = true;
      req.zipcode = zipValue;
    }

    if (entityInfo.organization_id)
      req.organization_id = entityInfo.organization_id;

    if (entityInfo.location) {
      req.location_id = entityInfo.location.id;
    }

    axios
      .post(
        coords
          ? `/microsite/location-point-check/`
          : `/microsite/location-by-zipcode/`,
        req
      )
      .then(({ data }) => {
        if (data.response.length === 0 && coords)
          return setCoordsNoLocation(true);
        setLocations(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLocations([]);
        setLoading(false);
      });
  }

  const { t } = useTranslation();

  const locationDisplay = (
    <React.Fragment>
      {showTitle && (
        <ViewTitle
          title={
            appConfig && appConfig.text && appConfig.text.location_search_title
              ? appConfig.text.location_search_title
              : t("location_search_title")
          }
          subtitle={
            appConfig &&
            appConfig.text &&
            appConfig.text.location_search_subtitle
              ? appConfig.text.location_search_subtitle
              : t("location_search_subtitle")
          }
          icon={<IconMapPin size={iconSize} />}
        />
      )}
      <ItemSlider
        data={locations.map((loc) => ({
          id: loc.id,
          title: loc.name,
          category: `${loc.city}, ${loc.state_province}${
            loc.distance ? ` - ${loc.distance} miles` : ""
          }`,
          image: generateStaticMap(
            {
              latitude: loc.latitude,
              longitude: loc.longitude,
            },
            {
              width: 500,
              height: 500,
            },
            14
          ),
          children: (
            <div>
              <Button
                style={{ marginTop: "1.5em" }}
                onClick={() =>
                  onLocationSelect ? onLocationSelect(loc) : setLocation(loc)
                }
              >
                {buttonText}
              </Button>
            </div>
          ),
        }))}
      />
    </React.Fragment>
  );

  if (allLocations) {
    return (
      <React.Fragment>
        <TextInput
          size="lg"
          value={zipValue}
          onChange={(e) => setZipValue(e.target.value)}
          style={{ marginBottom: "1em" }}
        />
        {locations.length > 0 && (
          <React.Fragment>{locationDisplay}</React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <div>
      {loading && <ViewLoader />}
      {!loading && locations.length === 0 && (
        <React.Fragment>
          <ViewTitle
            title={t("no_locations_title")}
            subtitle={`${t("no_locations_subtitle")}${
              zip ? ` near ${zip}` : ""
            }.`}
            icon={<IconMoodSad size={iconSize} />}
            marginBottom="1em"
          />
          {!coords ? (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ marginBottom: "2em" }}
                onClick={() => {
                  setCoords(null);
                  setZip(null);
                }}
                variant="light"
              >
                {t("change_location_button")}
              </Button>
            </div>
          ) : (
            <React.Fragment>
              <LocationSearch />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {locations.length > 0 && locationDisplay}
    </div>
  );
}
