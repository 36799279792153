import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { Anchor, Button, Stack, Text, Group } from "@mantine/core";
import toast from "react-hot-toast";

const sizeButtons = [
  { label: "XS", width: 100 },
  { label: "SM", width: 150 },
  { label: "MD", width: 200 },
  { label: "LG", width: 250 },
  { label: "XL", width: 500 },
  { label: "XXL", width: 1200 },
].map((m, i) => ({
  ...m,
  key: i,
}));

export default function QrCodeGenerator({
  text = "hello",
  minimal = false,
  filename = "qr-code.png",
  isLink = true,
}) {
  const [imgData, setImgData] = useState(null);
  const [size, setSize] = useState(250);

  useEffect(() => {
    if (text) {
      generateCode();
    }
  }, [text, size]);

  function generateCode(clicked = false) {
    QRCode.toDataURL(text, {
      width: size,
    })
      .then((url) => {
        setImgData(url);
        if (clicked) {
          toast.success("Code Generated!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (minimal && imgData) {
    return (
      <a href={imgData} download={filename}>
        <img
          style={{
            width: "250px",
            maxWidth: "250px",
            display: "block",
          }}
          src={imgData}
          alt="QR Code"
          title={filename}
        />
      </a>
    );
  }

  return (
    <React.Fragment>
      <Text fw="bold">
        Select a download size (currently {size}x{size}px)
      </Text>
      <Text size="xs" c="dimmed">
        Don't worry! You won't see many changes with the QR code below. When you
        download the QR code, it will be the correct size.
      </Text>
      <Stack align="flex-start" mt="lg">
        <Group position="start" spacing="sm" title="Select a size">
          {sizeButtons.map((b) => {
            return (
              <Button
                variant={size === b.width ? "filled" : "outline"}
                size="sm"
                key={b.key}
                onClick={() => setSize(b.width)}
              >
                {b.label}
              </Button>
            );
          })}
        </Group>
        {imgData && (
          <React.Fragment>
            <a href={imgData} download={filename}>
              <img
                style={{
                  width: "250px",
                  maxWidth: "250px",
                  display: "block",
                  boxShadow: "1px 1px 5px #ccc",
                }}
                src={imgData}
                alt="QR Code"
                title={filename}
              />
            </a>
            <Text fw={700}>Click QR code to download</Text>
            {isLink ? (
              <Text>
                When scanned, this code leads to{" "}
                <Anchor href={text} target="_blank">
                  {text}
                </Anchor>
              </Text>
            ) : (
              <Text size="xs" c="dimmed">
                {text}
              </Text>
            )}
          </React.Fragment>
        )}
        {/* <Button
        leftSection={<IconQrcode />}
        variant="light"
        onClick={() => generateCode(true)}
      >
        Generate QR Code
      </Button> */}
      </Stack>
    </React.Fragment>
  );
}
