import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Grid, Group, Title, Divider } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import EffortPicker from "./EffortPicker";
import EffortRegistered from "./EffortRegistered";
import EffortSlider from "./EffortSlider";
import EffortView from "./EffortView";
import LocationLocator from "./LocationLocator";
import PreferredLocation from "./PreferredLocation";
import UserCheck from "./UserCheck";
import UserLocation from "./UserLocation";
import UserView from "./UserView";
import ViewError from "./ViewError";
import ViewLoader from "./ViewLoader";

import useAppStore from "./microsite-store";

import { getPlacementImage } from "@components/Asset/helpers";
import { placementDefs } from "@components/Asset/placementDefs";

import "./locales";

export default function Microsite({
  appConfigData,
  campaignId,
  effortId,
  entityInfoData,
  fetchUser,
  preview,
}) {
  const [error, setError] = useState(null);
  const [locating, setLocating] = useState(true);
  const [registrationInfo, setRegistrationInfo] = useState(null);

  const { t, i18n } = useTranslation();

  const {
    appConfig,
    colors,
    coords,
    coordsNoLocation,
    effort,
    effortEntryVisible,
    email,
    entityInfo,
    location,
    phone,
    preferredLocation,
    setAppConfig,
    setCampaignId,
    setEntityInfo,
    setColors,
    setCoords,
    setEffort,
    setEffortId,
    setEffortEntryVisible,
    setEmail,
    setEmailStreams,
    setLocation,
    setUser,
    setUserSelectedEffortId,
    user,
    zip,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      campaignId: state.campaignId,
      coords: state.coords,
      coordsNoLocation: state.coordsNoLocation,
      effort: state.effort,
      effortId: state.effortId,
      effortEntryVisible: state.effortEntryVisible,
      email: state.email,
      entityInfo: state.entityInfo,
      location: state.location,
      phone: state.phone,
      preferredLocation: state.preferredLocation,
      setAppConfig: state.setAppConfig,
      setCampaignId: state.setCampaignId,
      setEntityInfo: state.setEntityInfo,
      setColors: state.setColors,
      setCoords: state.setCoords,
      setEffort: state.setEffort,
      setEffortId: state.setEffortId,
      setEffortEntryVisible: state.setEffortEntryVisible,
      setEmail: state.setEmail,
      setEmailStreams: state.setEmailStreams,
      setLocation: state.setLocation,
      setPreferredLocation: state.setPreferredLocation,
      setUser: state.setUser,
      setUserSelectedEffortId: state.setUserSelectedEffortId,
      user: state.user,
      zip: state.zip,
    }),
    shallow
  );

  useEffect(() => {
    if (entityInfoData.pixel_code) {
      trackPageView();
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "#f8f9fa";
    setEntityInfo(entityInfoData);
    setCampaignId(campaignId);
    setEffortId(effortId);
    setAppConfig(appConfigData);

    if (effortId) {
      setUserSelectedEffortId(effortId);
      setEffort({
        id: effortId,
      });
    }

    if (
      entityInfoData &&
      entityInfoData.language &&
      entityInfoData.language !== "en"
    ) {
      i18n.changeLanguage(entityInfoData.language);
    }

    if (preview) {
      setEmail("example@example.com");
    }
  }, []);

  useEffect(() => {
    fetchGeolocation();
  }, [JSON.stringify(appConfig)]);

  useEffect(() => {
    if (!appConfig) return;
    const backgroundImage = getPlacementImage(
      appConfig,
      entityInfo.assets,
      placementDefs.background
    );

    if (backgroundImage) {
      document.body.style.background = `url(${backgroundImage}) no-repeat center center`;
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundAttachment = "fixed";
    }

    if (appConfig.colors) {
      setColors({ ...colors, ...appConfig.colors });

      document.body.style.backgroundColor =
        appConfig.colors.background || "#f8f9fa";
    }
  }, [JSON.stringify(entityInfo)]);

  useEffect(() => {
    if (!effortEntryVisible && user) {
      fetchUser();
    }
  }, [effortEntryVisible]);

  useEffect(() => {
    if (!user) return;

    if (
      !user.campaign_efforts ||
      !user.campaign_efforts.length ||
      !user.campaign_efforts.find((f) => f.entered)
    ) {
      setEffortEntryVisible(true);
    }

    if (
      entityInfoData.campaign_effort_id &&
      user.campaign_efforts &&
      user.campaign_efforts.find(
        (f) => f.id === entityInfoData.campaign_effort_id && !f.entered
      )
    ) {
      setEffortEntryVisible(true);
    }
  }, [user]);

  function checkAndSetLocationFromResponse() {
    if (!entityInfoData.location_locked && entityInfoData.location) {
      setLocation(entityInfoData.location);
    }
    setLocating(false);
  }

  function trackPageView() {
    setTimeout(() => {
      if (window.fbq) {
        window.fbq("track", "PageView");
      }
    }, 5000);
  }

  function fetchGeolocation() {
    setLocating(true);
    const successCallback = (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      setCoords({
        latitude: lat,
        longitude: lng,
      });
      fetchLocationsFromGeo({
        latitude: lat,
        longitude: lng,
      });
    };

    const errorCallback = (error) => {
      // if location required, set error
      if (entityInfoData.location_locked) {
        setLocating(false);
        return setError(t("location_services_required_error"));
      } else {
        checkAndSetLocationFromResponse();
        setLocating(false);
        setCoords(null);
      }
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity,
    });
  }

  function fetchLocationsFromGeo(position) {
    if (!position) {
      if (entityInfoData.location_locked) {
        setError(t("no_coords_error"));
        setLocating(false);
      } else {
        checkAndSetLocationFromResponse();
      }
      return;
    }

    const req = {
      campaign_id: campaignId,
      campaign_effort_id: effortId,
      latitude: position.latitude,
      longitude: position.longitude,
    };

    if (entityInfo && entityInfo.location)
      req.location_id = entityInfo.location?.id;

    axios
      .post(`/microsite/location-point-check/`, req)
      .then(({ data }) => {
        if (!data.response.length) {
          if (entityInfoData.location_locked) {
            setLocating(false);
            setError(t("not_at_location_error"));
          } else {
            checkAndSetLocationFromResponse();
          }
        } else {
          const foundGeospot = data.response.find((f) => f.geo_spot_id);
          const foundLocation = data.response.find(
            (f) => f.class_name === "Location"
          );

          if (foundLocation) {
            if (
              entityInfoData.location &&
              entityInfoData.location_locked &&
              entityInfoData.location.id !== foundLocation.id
            ) {
              setLocating(false);
              setError(t("not_at_location_error"));
            } else {
              setLocation({
                ...foundLocation,
                present_at_location: true,
              });
              setLocating(false);
            }
          } else {
            setLocation(foundGeospot);
            setLocating(false);
            // no location
            // if (entityInfoData.location_locked) {
            //   setLocating(false);
            //   setError(t("not_at_location_error"));
            // } else {
            //   if (foundGeospot) {
            //     setLocation(foundGeospot);
            //     setLocating(false);
            //   }
            // }
          }
        }
      })
      .catch((err) => {
        setError(err);
        setLocating(false);
      });
  }

  function fetchUser(calledFromEntryForm = false) {
    // if (!user) return;
    setLocating(true);

    const req = {};

    const locationId = entityInfo.location_id || location ? location.id : null;

    if (locationId) req.location_id = locationId;
    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;

    if (email) req.email = email;
    if (phone) req.mobile_phone = phone;

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/user-lookup/`, req)
      .then(({ data }) => {
        if (data.response[0].date_submitted) {
          return setRegistrationInfo(data.response[0]);
        }
        setUser(data.response[0]);
        if (
          data.response[0].email_streams &&
          data.response[0].email_streams.length
        ) {
          setEmailStreams(
            data.response[0].email_streams.map((m) => ({
              ...m,
              value: m.active,
              label: m.name,
            }))
          );
        }
      })
      .then(() => {
        if (calledFromEntryForm) {
          clearEffortState();
        }
      })
      .then(() => setLocating(false))
      .catch((err) => {
        setUser(null);
        setLocating(false);
        setError(err);
      });
  }

  function onMoreEfforts() {
    setLocating(true);

    const req = {};

    const locationId = entityInfo.location_id || location ? location.id : null;

    if (locationId) req.location_id = locationId;
    if (effortId) req.campaign_effort_id = effortId;
    if (campaignId) req.campaign_id = campaignId;

    if (email) req.email = email;
    if (phone) req.mobile_phone = phone;

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/user-lookup/`, req)
      .then(({ data }) => {
        setUser(data.response[0]);
        if (
          data.response[0].email_streams &&
          data.response[0].email_streams.length
        ) {
          setEmailStreams(
            data.response[0].email_streams.map((m) => ({
              ...m,
              value: m.active,
              label: m.name,
            }))
          );
        }
      })
      .then(() => {
        setLocating(false);
        setUserSelectedEffortId(null);
        // setEffortId(null);
        setEffort(null);
        setEffortEntryVisible(true);
      })
      .catch((err) => {
        setUser(null);
        setLocating(false);
        setError(err);
      });
  }

  function clearEffortState() {
    setEffort(null);
    setEffortEntryVisible(false);
    setLocating(false);
  }

  function onUserEnter() {
    fetchUser(true);
  }

  if ((!campaignId && !effortId) || !entityInfo || !appConfig) return null;

  // const showPreferredLocation = entityInfo.location
  //   ? false
  //   : entityInfo.preferred_location;

  const showPreferredLocation = entityInfo.preferred_location;

  if (locating) {
    return <ViewLoader />;
  }

  if (error) {
    return (
      <ViewError
        title={t("generic_error_title")}
        order={1}
        subtitle={error}
        marginBottom={0}
      />
    );
  }

  if (registrationInfo) {
    return (
      <EffortRegistered registrationInfo={registrationInfo} info={entityInfo} />
    );
  }

  if (!entityInfoData.location_id && !location) {
    if ((zip || coords) && !coordsNoLocation) {
      return <LocationLocator buttonText={t("register_here_button")} />;
    }

    return <UserLocation />;
  }

  if (!email && !phone && !user) {
    return <UserCheck />;
  }

  if (user && !effortEntryVisible) {
    return <UserView fetchUser={fetchUser} />;
  }

  if (location && showPreferredLocation && !preferredLocation) {
    return <PreferredLocation />;
  }

  if (effort) {
    if (!location) {
      return <UserLocation />;
    }

    if (preview) {
      return (
        <div>
          <Title order={3} c="white" mb="sm">
            Preview Entry View
          </Title>
          <EffortView
            onEnterSuccess={(usr) => onUserEnter()}
            onMoreEfforts={() => onMoreEfforts()}
            showPreferredLocation={showPreferredLocation}
            preview
          />
          <Divider mt="lg" mb="lg" />
          <Title order={3} c="white" mb="sm">
            Preview Featured Image
          </Title>
          <EffortSlider
            colors={colors}
            preview
            efforts={[
              {
                ...entityInfo,
                draft_configuration: entityInfo.configuration,
                children: (
                  <Button style={{ marginTop: "1.5em" }} c="brand" radius="md">
                    Button color
                  </Button>
                ),
              },
            ]}
          />
        </div>
      );
    }

    return (
      <EffortView
        onEnterSuccess={(usr) => onUserEnter()}
        onMoreEfforts={() => onMoreEfforts()}
        showPreferredLocation={showPreferredLocation}
      />
    );
  }

  return (
    <div>
      {((!coords && !zip && !location) || coordsNoLocation) && (
        <React.Fragment>
          <UserLocation />
        </React.Fragment>
      )}
      {(zip || coords) && !coordsNoLocation && !location && !effort && (
        <LocationLocator buttonText={t("register_here_button")} />
      )}
      {location && !effort && (
        <React.Fragment>
          <EffortPicker />
          {user &&
            user.campaign_efforts &&
            user.campaign_efforts.find((f) => f.entered) && (
              <Group justify="center">
                <Button
                  mt="lg"
                  variant="subtle"
                  color="gray"
                  onClick={() => setEffortEntryVisible(false)}
                >
                  {t("view_my_campaigns_button")}
                </Button>
              </Group>
            )}
        </React.Fragment>
      )}
    </div>
  );
}
