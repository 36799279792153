import React from "react";
import { Box, Text } from "@mantine/core";

export default function VehicleCard({ info }) {
  const formattedItems = info
    ? [
        { text: "Make", value: info.make },
        { text: "Model", value: info.model },
        { text: "Year", value: info.year },
        { text: "Series", value: info.series },
      ].filter((f) => f.value)
    : [];

  return (
    <Box
      mt="xs"
      style={{
        background: "var(--mantine-color-dark-6)",
        padding: "10px",
        borderRadius: "6px",
        border: "1px solid var(--mantine-color-dark-5)",
      }}
    >
      {formattedItems.map((m, i) => (
        <div key={i}>
          <Text c="white">
            {m.text}: {m.value || "?"}
          </Text>
        </div>
      ))}
    </Box>
  );
}
