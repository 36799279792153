import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ActionIcon,
  Card,
  Box,
  Grid,
  Text,
  TextInput,
  FileInput,
  Divider,
  Button,
  Flex,
  Modal,
  LoadingOverlay,
  Switch,
  List,
} from "@mantine/core";
import * as Yup from "yup";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { IconQrcode, IconPencil } from "@tabler/icons-react";

import { BasicForm, QrCodeGenerator } from "@components/shared";
import { LocationSelect } from "@components/Location";
import { phoneValidation } from "@util/validation";
import entityIcon from "@util/entityIcon";

export default function RideChallengeVerificationCodes({
  effortId,
  effortName,
  fetchAlerts,
  locationId,
}) {
  const [selectedLocationId, setSelectedLocationId] = useState("");

  useEffect(() => {
    if (!locationId) return;
    setSelectedLocationId(locationId);
  }, [locationId]);

  return (
    <div>
      {!locationId && (
        <>
          <Box mb="sm">
            <BulkAdd
              global
              additionalFormData={{
                campaign_effort_id: effortId,
              }}
              onSuccess={() => {
                setSelectedLocationId("");
                fetchAlerts();
              }}
            />
          </Box>
          <LocationSelect
            reqData={{
              campaign_effort_id: effortId,
            }}
            value={selectedLocationId}
            onChange={(e) => setSelectedLocationId(e)}
          />
        </>
      )}
      <Box mt="lg">
        <LocationView
          effortId={effortId}
          effortName={effortName}
          locationId={selectedLocationId}
          fetchAlerts={fetchAlerts}
        />
      </Box>
    </div>
  );
}

const LocationView = ({ effortId, effortName, locationId, fetchAlerts }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [codes, setCodes] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    setCodes([]);
    if (!locationId) return;
    fetchData();
  }, [locationId]);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/efforts/${effortId}/retrieve-verifiers/`, {
        location_id: locationId,
      })
      .then(({ data }) => {
        setCodes(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (!locationId) {
    return (
      <Card>
        <Box
          mih={300}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {entityIcon.location(48)}
            </div>
            <Text align="center" fw={600} size="xl">
              Select a location first...
            </Text>
          </div>
        </Box>
      </Card>
    );
  }

  const filteredCodes = searchValue.length
    ? codes.filter((f) =>
        f.first_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        f.last_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        f.number.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          ? true
          : false
      )
    : codes;

  return (
    <div>
      <Box pos="relative" mih={300}>
        <LoadingOverlay zIndex={1} visible={loading} />
        {!managerInfo && (
          <>
            <Flex align="center" gap="sm">
              <CodeModal
                additionalFormData={{
                  campaign_effort_id: effortId,
                  location_id: locationId,
                }}
                onSuccess={() => {
                  fetchData();
                  fetchAlerts();
                }}
              />
              <BulkAdd
                additionalFormData={{
                  campaign_effort_id: effortId,
                  location_id: locationId,
                }}
                onSuccess={() => {
                  fetchData();
                  fetchAlerts();
                }}
              />
            </Flex>
            <Divider mt="lg" mb="lg" />
          </>
        )}
        <TextInput
          placeholder="Search codes..."
          mb="lg"
          size="md"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Grid>
          {filteredCodes.map((code) => (
            <Grid.Col span={{ base: 12, md: 3 }} key={code.id}>
              <CodeListItem
                effortId={effortId}
                effortName={effortName}
                codeData={code}
                editable={managerInfo ? false : true}
                fetchData={() => {
                  fetchData();
                  fetchAlerts();
                }}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

const CodeModal = ({ additionalFormData, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  function onSubmit(formData) {
    setLoading(true);

    const req = {
      ...additionalFormData,
      ...formData,
    };

    axios
      .post(
        `/efforts/${additionalFormData.campaign_effort_id}/add-verifier-code/`,
        req
      )
      .then(() => {
        setLoading(false);
        onClose();
        onSuccess();
        toast.success("Added!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add a new code</Button>
      <Modal title="Add a new code" opened={isOpen} onClose={onClose}>
        <CodeForm
          additionalFormData={additionalFormData}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
      </Modal>
    </>
  );
};

const CodeForm = ({
  additionalFormData,
  onSuccess,
  firstName = "",
  lastName = "",
  phone = "",
  id,
}) => {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setLoading(true);

    const req = {
      ...additionalFormData,
      ...formData,
    };

    if (id) {
      return onUpdate(req);
    }

    axios
      .post(
        `/efforts/${additionalFormData.campaign_effort_id}/add-verifier-code/`,
        req
      )
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Added!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(req) {
    axios
      .put(
        `/efforts/${additionalFormData.campaign_effort_id}/update-verifier/`,
        req
      )
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Updated!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "phone",
      label: "Phone",
      initialValue: phone,
      mask: {
        pattern: "(999) 999-9999",
        placeholder: "(XXX) XXX-XXXX",
      },
      required: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone")
          .required("Required"),
    },
  ];
  return (
    <BasicForm
      buttonProps={{
        fullWidth: true,
      }}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
};

const BulkAdd = ({ additionalFormData, onSuccess, global = false }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
    setUploading(false);
    setValue(null);
  }

  function onUploadClick() {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", value);
    Object.keys(additionalFormData).forEach((k) =>
      formData.append(k, additionalFormData[k])
    );

    axios
      .post(
        `/efforts/${additionalFormData.campaign_effort_id}/bulk-add-verifier-codes/`,
        formData
      )
      .then(() => {
        toast.success("Success!");
        setUploading(false);
        onSuccess();
        onClose();
      })
      .catch((err) => {
        setUploading(false);
        toast.error(err);
      });
  }

  const requiredHeaders = [
    "first_name",
    "last_name",
    "phone_number",
    "location_id",
  ].filter((f) => (global ? true : f !== "location_id"));

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {global ? "Bulk add codes across locations" : "Bulk Add Codes"}
      </Button>
      <Modal
        title={global ? "Bulk add codes across locations" : "Bulk Add Codes"}
        opened={isOpen}
        onClose={onClose}
      >
        <Text>Please make sure your CSV rows contain the following:</Text>
        <List mb="lg">
          {requiredHeaders.map((item, i) => (
            <List.Item key={i}>{item}</List.Item>
          ))}
        </List>
        <FileInput
          label="CSV File"
          placeholder="Select a file to upload..."
          accept=".csv"
          required
          value={value}
          onChange={setValue}
        />
        <Button
          loading={uploading}
          mt="sm"
          fullWidth
          disabled={!value}
          onClick={onUploadClick}
        >
          Upload CSV
        </Button>
      </Modal>
    </>
  );
};

const CodeListItem = ({
  codeData,
  effortId,
  effortName,
  fetchData,
  editable = true,
}) => {
  const [active, setActive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    if (!codeData) return;
    setActive(codeData.active);
  }, [JSON.stringify(codeData)]);

  function onStatusChange() {
    setActive(!active);

    const req = {
      remote_code_id: codeData.id,
    };

    axios
      .post(`/efforts/${effortId}/toggle-verifier/`, req)
      .then(() => {
        toast.success("Updated!");
        fetchData();
      })
      .catch((err) => {
        setActive(!active);
        toast.error(err);
      });
  }

  return (
    <Card
      style={{
        overflow: "hidden",
      }}
    >
      <Card.Section>
        <Box
          style={{
            background: "var(--mantine-color-gray-9)",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <StyledCode>
            {active ? (
              <QrCodeGenerator
                minimal
                text={codeData.code}
                filename={`${codeData.first_name} ${codeData.last_name} ${effortName}.png`}
              />
            ) : (
              <div className="empty"></div>
            )}
          </StyledCode>
        </Box>
      </Card.Section>
      <Flex gap="xs" mt="md" align="center">
        <Text fw={500}>
          {codeData.first_name} {codeData.last_name}
        </Text>
        <ActionIcon
          onClick={() => setEditOpen(true)}
          variant="light"
          color="gray"
          size="sm"
          radius="xl"
          title="Edit code"
        >
          <IconPencil size={14} />
        </ActionIcon>
        {active && (
          <ActionIcon
            onClick={() => setOpen(true)}
            variant="light"
            color="gray"
            size="sm"
            radius="xl"
            title="Additional QR code sizes"
          >
            <IconQrcode size={14} />
          </ActionIcon>
        )}
      </Flex>
      <Text size="sm" c="dimmed">
        {codeData.number}
      </Text>
      <Modal size="lg" opened={isOpen} onClose={() => setOpen(false)}>
        <QrCodeGenerator
          text={codeData.code}
          filename={`${codeData.first_name}-${codeData.last_name}.png`}
          isLink={false}
        />
      </Modal>
      <Modal opened={editOpen} onClose={() => setEditOpen(false)}>
        {editable && (
          <>
            <CodeForm
              firstName={codeData.first_name}
              lastName={codeData.last_name}
              phone={codeData.number}
              additionalFormData={{
                remote_code_id: codeData.id,
                campaign_effort_id: effortId,
              }}
              onSuccess={() => {
                fetchData();
                setEditOpen(false);
              }}
              id={codeData.id}
            />
            <Divider mt="lg" mb="lg" />
          </>
        )}
        <Switch
          checked={active}
          label={active ? "Enabled" : "Disabled"}
          mt="sm"
          onChange={onStatusChange}
        />
      </Modal>
    </Card>
  );
};

const StyledCode = styled.div`
  img,
  .empty {
    height: 150px !important;
    width: 150px !important;
    border-radius: 10px;
  }
`;
