import React from "react";
import { Group } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { Counter } from "@components/shared";
import useAppStore from "./effort-point-settings-store";

export default function Entry({ onUpdate }) {
  const { settings } = useAppStore(
    (state) => ({
      settings: state.settings,
    }),
    shallow
  );

  const counterFields = [
    {
      title: "Entry Count",
      keyName: "entry_count",
      unlimited: false,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: settings.initial_entry[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            unlimited={c.unlimited}
            value={c.value}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
    </div>
  );
}
