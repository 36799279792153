import React, { useState, useEffect } from "react";
import { Box, Flex, Text, NumberFormatter } from "@mantine/core";
import axios from "axios";
import styled from "styled-components";
import { shallow } from "zustand/shallow";

import UserHistory from "./UserHistory";
import useAppStore from "./ride-challenge-store";

import { Odometer, TierGauge } from "@components/RideChallenge";

export default function RevScore({
  onRevScoreClick,
  expanded = false,
  reqData,
  tiers = [],
  color,
  gaugeMaxValue,
}) {
  const { dashboardData, setAppLoading } = useAppStore(
    (state) => ({
      dashboardData: state.dashboardData,
      setAppLoading: state.setAppLoading,
    }),
    shallow
  );
  const [revScoreInfo, setRevScoreInfo] = useState(null);
  if (!dashboardData) return null;

  useEffect(() => {
    if (!expanded) return;
    fetchData();
  }, [expanded]);

  function fetchData() {
    setAppLoading(true);
    axios
      .post(`/ride-challenge/rev-breakdown/`, reqData)
      .then(({ data }) => {
        const res = data.response[0];
        setRevScoreInfo({
          ...data.response[0],
          mileage: data.response[0].mileage
            .sort((a, b) => new Date(b.verified_at) - new Date(a.verified_at))
            .map((m) => ({
              ...m,
              odometer_reading:
                m.odometer_reading >= 1000000
                  ? m.odometer_reading - 1000000
                  : m.odometer_reading,
            })),
          keywords: data.response[0].keywords.sort(
            (a, b) => new Date(b.claimed_at) - new Date(a.claimed_at)
          ),
        });
        setAppLoading(false);
      })
      .catch((err) => {
        setRevScoreInfo(null);
        setAppLoading(false);
      });
  }

  const equationItems = revScoreInfo
    ? [
        { value: revScoreInfo.total_miles, text: "Total Miles" },
        { operator: "+" },
        {
          value: revScoreInfo.readings_engagements_rev,
          text: "Odometer Rev",
          calculation: `${revScoreInfo.readings_engagements_count} reading * ${revScoreInfo.rev_score_readings_multiplier}`,
        },
        { operator: "+" },
        {
          value: revScoreInfo.keyword_engagements_rev,
          text: "Keywords Rev",
          calculation: `${revScoreInfo.keyword_engagements_count} claim * ${revScoreInfo.rev_score_keywords_multiplier}`,
        },
        { operator: "+" },
        {
          value: revScoreInfo.locations_rev,
          text: "Locations Rev",
          calculation: `${revScoreInfo.locations_count} visit * ${revScoreInfo.rev_score_locations_multiplier}`,
        },
      ]
    : [];

  const revScore =
    expanded && revScoreInfo ? revScoreInfo.rev_score : dashboardData.rev_score;
  const totalMiles =
    expanded && revScoreInfo
      ? revScoreInfo.total_miles
      : dashboardData.total_miles;

  const formattedTiers = tiers
    .sort((a, b) => a.position - b.position)
    .map((tier) => ({
      label: tier.name,
      start: tier.start_value,
      end: tier.end_value,
      color: tier?.settings?.fill_color || "orange",
    }));

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <StyledOdometer>
            <Odometer newValue={totalMiles} />
            {/* <Text c="white" fw={600}>
              MILES
            </Text> */}
          </StyledOdometer>
          {tiers.length > 0 && (
            <StyledTierGauge>
              <TierGauge
                sections={formattedTiers}
                value={totalMiles}
                maxValue={gaugeMaxValue}
              />
            </StyledTierGauge>
          )}
        </div>
      </div>
      <StyledRevScoreCircle
        color={color}
        revScoreLength={`${revScore}`.length}
        onClick={() => (!expanded ? onRevScoreClick() : null)}
        expanded={expanded}
      >
        <p>
          <span>REV SCORE</span>
        </p>
        <h1>{revScore}</h1>
      </StyledRevScoreCircle>
      {/* {!expanded && (
        <div
          style={{
            position: "relative",
            top: "-30px",
          }}
        >
          <Flex justify="center">
            <Button size="xs" variant="subtle" onClick={onRevScoreClick}>
              How's this calculated?
            </Button>
          </Flex>
        </div>
      )} */}
      {expanded && (
        <div style={{ minHeight: "200px" }}>
          {revScoreInfo && (
            <div>
              <Flex gap="xs" justify="center" align="flex-start">
                {equationItems.map((item, i) => {
                  if (item.operator) {
                    return (
                      <Text c="white" align="center" key={i}>
                        +
                      </Text>
                    );
                  }
                  return (
                    <div key={i}>
                      <Text fw={600} c="white" align="center">
                        <NumberFormatter thousandSeparator value={item.value} />
                      </Text>
                      <Text size="xs" c="white" align="center">
                        {item.text}
                      </Text>
                      {item.calculation && (
                        <Text size="xs" c="white" align="center">
                          ({item.calculation})
                        </Text>
                      )}
                    </div>
                  );
                })}
              </Flex>
              <Box mt="xl">
                <UserHistory
                  mileageItems={revScoreInfo.mileage}
                  keywordItems={revScoreInfo.keywords}
                  locationItems={revScoreInfo.locations}
                />
              </Box>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const StyledOdometer = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .odometer-value {
    /* font-size: 40px; */
  }
`;

const StyledTierGauge = styled.div`
  overflow: auto;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-bottom: 25px;
  }

  svg {
    /* width: 95%; */
    /* height: 95%; */
  }
`;

const StyledRevScoreCircle = styled.div`
  height: 155px;
  width: 155px;
  background: ${(props) => `var(--mantine-color-${props.color}-7)`};
  outline: ${(props) =>
    props.expanded ? `15px solid transparent` : `15px solid #000`};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  top: -50px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 120px;
    width: 120px;
    outline-width: 10px;
    top: -50px;
  }

  p {
    color: #fff;
    line-height: 0;
    font-size: 1.15em;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 0.754em;
    }
  }

  h1 {
    line-height: 0.7;
    margin: 5px 0 0 0;
    color: #fff;
    font-size: ${(props) => (props.revScoreLength >= 6 ? "1.75em" : "2.65em")};

    @media (max-width: 768px) {
      font-size: ${(props) => (props.revScoreLength >= 6 ? "1.25em" : "2em")};
    }
  }
`;
