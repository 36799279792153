import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Select,
  Text,
  TextInput,
  Button,
  Flex,
  ActionIcon,
  rem,
} from "@mantine/core";
import axios from "axios";
import { IconTrash } from "@tabler/icons-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { DealerRideChallenge, RideChallengeManagementList } from "./";

const pageSize = 25;

export default function RideChallengeManagement({ effortId, locationId }) {
  return <RideChallengeManagementList effortId={effortId} />;

  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("search");
  const [codes, setCodes] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(codes.slice(0, pageSize));
  const pageCount = Math.round(codes.length / pageSize);
  const [entityInfo, setEntityInfo] = useState(null);
  const [appConfig, setAppConfig] = useState(null);
  const [dealerCode, setDealerCode] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchData();
    i18n.changeLanguage("manager");
  }, []);

  useEffect(() => {
    if (!selected) return;
    const code = codes.find((f) => f.code === selected);
    if (code) {
      setSelectedLocation(code);
    } else {
      setSelectedLocation(null);
    }
  }, [selected]);

  function fetchData() {
    setLoading(true);
    axios
      .post(`/efforts/${effortId}/get-url-codes-for-qr/`, {
        campaign_effort_id: effortId,
      })
      .then(({ data }) => {
        const res = data.response;
        setCodes(res);
        if (locationId) {
          const matchedLocation = res.find((f) => f.id === locationId);
          if (matchedLocation) {
            setSelected(matchedLocation.code);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setCodes([]);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!selected) return;
    fetchAppData();
  }, [selected]);

  function fetchAppData() {
    const req = {
      code: selected,
    };

    axios
      .post(`/ride-challenge/retrieve-by-url/`, req)
      .then(({ data }) => {
        setEntityInfo(data.response[0]);
        setAppConfig(data.response[0].app_configuration);
      })
      .catch((err) => {});
  }

  const options = codes.map((code) => ({
    value: code.code,
    label: code.name,
  }));

  if (!selected || !verificationCode)
    return (
      <div>
        {!locationId && (
          <Select
            data={options}
            searchable
            label="Location"
            placeholder="Search..."
            value={selected}
            onChange={(e) => setSelected(e)}
          />
        )}
        <VerificationLookup
          additionalReqData={{
            campaign_effort_id: effortId,
            location_id: selectedLocation?.id,
          }}
          onChange={(e) => setVerificationCode(e)}
        />
      </div>
    );

  if (!entityInfo) return null;

  return (
    <div>
      {selectedLocation && !locationId && (
        <Flex gap="xs" align="center" mb="lg">
          <Text fw={600}>Selected Location: {selectedLocation.name}</Text>
          <ActionIcon
            size="sm"
            radius="xl"
            color="red"
            variant="light"
            onClick={() => {
              setSelected("");
              setSelectedLocation(null);
              setVerificationCode(null);
              fetchData();
            }}
          >
            <IconTrash
              style={{
                width: rem(16),
                height: rem(16),
              }}
            />
          </ActionIcon>
        </Flex>
      )}
      {verificationCode && (
        <Flex gap="xs" align="center" mb="lg">
          <Text fw={600}>
            Acting as: {verificationCode.first_name}{" "}
            {verificationCode.last_name}
          </Text>
          <ActionIcon
            size="sm"
            radius="xl"
            color="red"
            variant="light"
            onClick={() => {
              setVerificationCode(null);
            }}
          >
            <IconTrash
              style={{
                width: rem(16),
                height: rem(16),
              }}
            />
          </ActionIcon>
        </Flex>
      )}
      <StyledWrapper>
        <DealerRideChallenge
          effortId={effortId}
          entityInfoData={entityInfo}
          verificationCode={verificationCode}
        />
      </StyledWrapper>
    </div>
  );
}

const StyledWrapper = styled.div`
  background: var(--mantine-color-dark-8);
  padding: 50px 60px;
  border-radius: 4px;
  width: 600px;
  /* margin: 0 auto; */

  label {
    color: whitesmoke;
  }
`;

function VerificationLookup({ onChange, additionalReqData }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeInfo, setCodeInfo] = useState(null);

  useEffect(() => {
    setCodeInfo(null);
    setValue("");
  }, [JSON.stringify(additionalReqData)]);

  function onSubmit() {
    const req = {
      ...additionalReqData,
      number: value,
    };

    setLoading(true);

    axios
      .post(`/ride-challenge/verification-code-lookup/`, req)
      .then(({ data }) => {
        setLoading(false);
        setCodeInfo(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function getVerificationCode() {
    const req = {
      code: codeInfo.code,
    };

    setLoading(true);

    axios
      .post(`/ride-challenge/verification-code-check/`, req)
      .then(({ data }) => {
        setLoading(false);
        onChange(data.response[0]);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  if (codeInfo) {
    return (
      <div>
        <Text mt="lg" fw={600}>
          Continue as {codeInfo.first_name} {codeInfo.last_name}?
        </Text>
        <Flex gap="xs" mt="sm">
          <Button onClick={getVerificationCode} color="green">
            Yes
          </Button>
          <Button color="red" onClick={() => setCodeInfo(null)}>
            No
          </Button>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <TextInput
        label="Phone"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        mb="sm"
      />
      <Button
        loading={loading}
        onClick={onSubmit}
        disabled={!value || !additionalReqData?.location_id}
      >
        Submit
      </Button>
    </div>
  );
}
