import React from "react";
import axios from "axios";
import { FileButton } from "@mantine/core";
import toast from "react-hot-toast";
import { IconCamera } from "@tabler/icons-react";
import styled from "styled-components";

import compressImages from "@util/compressImages";

const StyledImage = styled.img`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlaceholder = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : `100px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100px`)};
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default function VehicleImage({
  userVehicleId,
  onSuccess,
  url,
  height,
  width,
}) {
  function onUpload(file) {
    const formData = new FormData();
    compressImages([{ id: 1, file }]).then((compressedImages) => {
      formData.append("tempfile", compressedImages[0].file);
      // formData.append("tempfile", file);
      formData.append("variety", 19);
      formData.append("user_vehicle_id", userVehicleId);

      axios
        .post(`/assets/`, formData)
        .then(() => {
          toast.success("Uploaded!");
          onSuccess();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  }

  return (
    <FileButton accept="image/*" onChange={(e) => onUpload(e)}>
      {(props) =>
        url ? (
          <StyledImage
            {...props}
            src={url || null}
            height={height}
            width={width}
          />
        ) : (
          <StyledPlaceholder {...props} height={height} width={width}>
            <IconCamera size={24} color="var(--mantine-color-gray-6)" />
          </StyledPlaceholder>
        )
      }
    </FileButton>
  );
}
