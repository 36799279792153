import React from "react";
import { Group } from "@mantine/core";
import { shallow } from "zustand/shallow";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import useAppStore from "./effort-point-settings-store";

export default function Checkins({ onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );
  const counterFields = [
    {
      title: "Value",
      keyName: "check_in_value",
      unlimited: false,
      max: 70,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: settings.check_ins[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={c.unlimited}
            maxValue={c.max}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      <Intervals
        items={settings.check_ins.intervals}
        title="Check-In Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
