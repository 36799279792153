import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  Flex,
  Badge,
  Text,
  Box,
  Title,
  Loader,
  TextInput,
  Select,
  Grid,
  Divider,
  Checkbox,
  Radio,
  Card,
  List,
} from "@mantine/core";
import toast from "react-hot-toast";
import styled from "styled-components";

import { PrizeForm, PrizeItem } from "@components/Prize";
import { TierImage } from "./ContestTierGroupManagement";

export default function TieredContestPrizes({
  contestId,
  locationId,
  organizationId,
}) {
  const [tierGroups, setTierGroups] = useState([]);
  const [prizes, setPrizes] = useState([]);
  // const [tiers, setTiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState(null);

  const tiers = tierGroups.reduce((acc, cur) => {
    const tierGroup = cur;
    tierGroup.contest_tiers.forEach((tier) => {
      acc.push({
        ...tier,
        tier_group: tierGroup,
      });
    });
    return acc;
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      contest_id: contestId,
    };

    axios
      .post(`/contests/${contestId}/retrieve-contest-tier-groups/`, req)
      .then(({ data }) => {
        const res = data.response;
        setTierGroups(
          res
            .sort((a, b) => a.position - b.position)
            .map((m) => ({
              ...m,
              contest_tiers: m.contest_tiers.sort(
                (a, b) => a.position - b.position
              ),
            }))
        );
        setLoading(false);
        const formattedPrizes = res.reduce((acc, cur) => {
          const tierGroup = cur;
          tierGroup.contest_tiers.forEach((tier) => {
            tier.prizes.forEach((prize) => {
              const inArray = acc.find((f) => f.id === prize.id);
              if (!inArray) {
                acc.push({
                  ...prize,
                  tiers: [{ ...tier }],
                });
              } else {
                inArray.tiers.push(tier);
              }
            });
          });
          return acc;
        }, []);
        fetchContestPrizes(formattedPrizes);
      })
      .catch((err) => {
        setTierGroups([]);
        setLoading(false);
      });
  }

  function fetchContestPrizes(formattedPrizes) {
    setLoading(true);

    const req = {
      contest_id: contestId,
    };

    axios
      .get(`/contests/${contestId}/retrieve-prizes/`, req)
      .then(({ data }) => {
        const res = data.response;
        let comboPrizes = [...formattedPrizes];
        res.forEach((prize) => {
          const foundPrize = comboPrizes.find((f) => f.id === prize.id);
          if (!foundPrize) {
            comboPrizes.push({ ...prize, tiers: [] });
          }
        });
        setPrizes(comboPrizes);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function onTierChange(tierId) {
    const prizeInTier = tiers
      ?.find((f) => f.id === tierId)
      ?.prizes?.find((f) => f.id === selectedPrize.id);
    const newPrizes = [...prizes];
    const newTierGroups = [...tierGroups];
    const prizeToUpdate = newPrizes.find((f) => f.id === selectedPrize.id);
    const tierToUpdate = tiers.find((f) => f.id === tierId);
    const tierGroupToUpdate = newTierGroups.find(
      (f) => f.id === tierToUpdate.tier_group.id
    );
    const newTierInTierGroup = tierGroupToUpdate.contest_tiers.find(
      (f) => f.id === tierId
    );
    if (
      !prizeToUpdate ||
      !tierToUpdate ||
      !tierGroupToUpdate ||
      !newTierInTierGroup
    )
      return;

    if (prizeInTier) {
      prizeToUpdate.tiers = [...prizeToUpdate.tiers].filter(
        (f) => f.id !== tierId
      );
      newTierInTierGroup.prizes = [...newTierInTierGroup.prizes].filter(
        (f) => f.id !== selectedPrize.id
      );
    } else {
      const tierToAdd = tiers.find((f) => f.id === tierId);
      if (!tierToAdd) return;
      prizeToUpdate.tiers = [...prizeToUpdate.tiers, { ...tierToAdd }];
      newTierInTierGroup.prizes = [
        ...newTierInTierGroup.prizes,
        { ...selectedPrize },
      ];
    }

    const req = {
      contest_tier_id: tierId,
      prize_id: selectedPrize.id,
    };

    const url = prizeInTier
      ? `/remove-contest-tier-prize/`
      : `/add-contest-tier-prize/`;

    axios
      .post(url, req)
      .then(() => {
        setPrizes(newPrizes);
        // setTiers(newTiers);
        setTierGroups(newTierGroups);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onPrizeRemoveClick(prizeId) {
    axios
      .delete(`/prizes/${prizeId}/`)
      .then(() => {
        const newTierGroups = [...tierGroups];
        newTierGroups.forEach((tierGroup) => {
          tierGroup.contest_tiers.forEach((tier) => {
            tier.prizes = tier.prizes.filter((f) => f.id !== prizeId);
          });
        });

        if (selectedPrize && selectedPrize.id === prizeId) {
          setSelectedPrize(null);
        }

        setPrizes([...prizes].filter((f) => f.id !== prizeId));
        setTierGroups(tierGroups);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex align="center" mb="lg">
        <PrizeAdd onSuccess={fetchData} contestId={contestId} />
      </Flex>
      <Grid>
        <Grid.Col
          span={{
            base: 12,
            md: 5,
          }}
          style={{
            maxHeight: "800px",
            overflowY: "auto",
          }}
        >
          {prizes.map((prize) => {
            const isSelectedPrize = selectedPrize
              ? selectedPrize.id === prize.id
              : false;

            const prizeAssets = prize.loot_items.reduce((acc, cur) => {
              cur.assets.forEach((asset) => acc.push(asset));
              return acc;
            }, []);
            const prizeAsset = prizeAssets.length > 0 ? prizeAssets[0] : null;
            const prizeTierIds = prize.tiers.map((m) => m.id);
            const prizeTierGroups = prize.tiers
              .reduce((acc, cur) => {
                if (!acc.includes(cur.contest_tier_group_id))
                  acc.push(cur.contest_tier_group_id);
                return acc;
              }, [])
              .map((tierGroupId) =>
                tierGroups.find((f) => f.id === tierGroupId)
              )
              .filter((f) => f !== undefined)
              .map((m) => ({
                ...m,
                contest_tiers: m.contest_tiers.filter((f) =>
                  prizeTierIds.includes(f.id)
                ),
              }));

            return (
              <Card
                key={prize.id}
                mb="sm"
                style={{
                  opacity: isSelectedPrize || !selectedPrize ? 1 : 0.35,
                }}
              >
                <Flex align="flex-start" gap="sm">
                  {prizeAsset ? (
                    <StyledImage src={prizeAsset.filename_url} />
                  ) : (
                    <StyledPlaceholder />
                  )}
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <Flex gap="xs" align="center">
                      <Text fw={600}>{prize.name}</Text>
                      <Badge
                        variant="light"
                        color={prize.tiers.length === 0 ? "red" : "blue"}
                        size="xs"
                      >
                        in {prize.tiers.length} tier
                        {prize.tiers.length === 1 ? "" : "s"}
                      </Badge>
                    </Flex>
                    <Text c="dimmed" size="sm" mb="xs">
                      {prize.description}
                    </Text>
                    {prize.loot_items.length > 0 && (
                      <Box>
                        <Text size="sm" fw={600}>
                          Loot Items
                        </Text>
                        <List size="xs">
                          {prize.loot_items.map((lootItem) => (
                            <List.Item key={lootItem.loot_item_id}>
                              ({lootItem.quantity}) {lootItem.name}
                            </List.Item>
                          ))}
                        </List>
                      </Box>
                    )}
                    {prizeTierGroups.length > 0 && (
                      <Box>
                        <Divider mt="sm" mb="sm" />
                        {prizeTierGroups.map((tierGroup) => (
                          <Box mb="sm" key={tierGroup.id}>
                            <Text fw={600} size="xs">
                              {tierGroup.name} ({tierGroup.variety_formatted})
                            </Text>
                            <List size="xs">
                              {tierGroup.contest_tiers.map((tier) => (
                                <List.Item key={tier.id}>
                                  {tier.name} ({tier.start_value} -{" "}
                                  {tier.end_value})
                                </List.Item>
                              ))}
                            </List>
                          </Box>
                        ))}
                      </Box>
                    )}
                    <Divider mt="sm" mb="sm" />
                    <Box>
                      <Flex align="center" gap="xs">
                        <Button
                          variant={isSelectedPrize ? "filled" : "light"}
                          onClick={() =>
                            setSelectedPrize(isSelectedPrize ? null : prize)
                          }
                          size="xs"
                        >
                          Tiers
                        </Button>
                        <PrizeItem
                          id={prize.id}
                          contestId={contestId}
                          organizationId={organizationId}
                          locationId={locationId}
                          minimal
                          showDelete={false}
                          prizeData={prize}
                          onModalClose={fetchData}
                          onUpdate={fetchData}
                          showSettings={false}
                        />
                        {prizes.length > 1 && (
                          <Button
                            size="xs"
                            variant="light"
                            color="red"
                            onClick={() => onPrizeRemoveClick(prize.id)}
                          >
                            Remove
                          </Button>
                        )}
                      </Flex>
                    </Box>
                  </div>
                </Flex>
              </Card>
            );
          })}
        </Grid.Col>
        <Grid.Col
          span={{
            base: 12,
            md: 7,
          }}
        >
          {tierGroups.map((group) => (
            <Box mb="xl" key={group.id}>
              <Grid align="center">
                <Grid.Col span={5}>
                  <Card mb="sm">
                    <Flex gap="xs" align="center">
                      <div>
                        <TierImage
                          tierGroupId={group.id}
                          contestId={contestId}
                          onSuccess={fetchData}
                          height={40}
                          width={40}
                          url={
                            group.assets && group.assets.length
                              ? group.assets[group.assets.length - 1]
                                  .filename_url
                              : null
                          }
                        />
                      </div>
                      <div>
                        <Flex gap="xs" align="center">
                          <Text size="sm" fw={600}>
                            {group.name}
                          </Text>
                          <Badge size="xs">{group.variety_formatted}</Badge>
                        </Flex>
                        <Text size="xs" c="dimmed">
                          {group.contest_tiers.length} Tier
                          {group.contest_tiers.length === 1 ? "" : "s"}
                        </Text>
                      </div>
                    </Flex>
                  </Card>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Box
                    style={{
                      background: "var(--mantine-color-gray-1)",
                      padding: "var(--mantine-spacing-sm)",
                      borderRadius: "5px",
                      border: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    {group.contest_tiers.length === 0 && (
                      <Text c="red" align="center" size="xs" fw={600}>
                        No tiers yet!
                      </Text>
                    )}
                    {group.contest_tiers.map((tier, i) => {
                      const tierPrizeIds = tier.prizes.map((p) => p.id);
                      const tierImage =
                        tier.assets && tier.assets.length
                          ? tier.assets[tier.assets.length - 1].filename_url
                          : null;
                      return (
                        <Card
                          key={tier.id}
                          mb={i < group.contest_tiers.length - 1 ? "sm" : "0"}
                        >
                          <Flex align="center" gap="sm">
                            {selectedPrize && (
                              <Checkbox
                                checked={
                                  selectedPrize
                                    ? tierPrizeIds.includes(selectedPrize.id)
                                    : false
                                }
                                onChange={() => {
                                  onTierChange(tier.id);
                                }}
                                disabled={!selectedPrize}
                              />
                            )}
                            <div>
                              <Flex gap="xs">
                                <div>
                                  <TierImage
                                    tierId={tier.id}
                                    contestId={contestId}
                                    url={tierImage}
                                    onSuccess={fetchData}
                                    height={40}
                                    width={40}
                                  />
                                </div>
                                <div>
                                  <Flex gap="xs" align="center">
                                    <Text size="sm" fw={600}>
                                      {tier.name}
                                    </Text>
                                    <Badge size="xs">
                                      {tier.start_value} - {tier.end_value}
                                    </Badge>
                                  </Flex>
                                  {tier.prizes.length > 0 ? (
                                    <List size="xs">
                                      {tier.prizes.map((prize) => (
                                        <List.Item
                                          key={prize.id}
                                          style={{
                                            background:
                                              selectedPrize &&
                                              selectedPrize.id === prize.id
                                                ? "var(--mantine-color-yellow-1)"
                                                : "none",
                                          }}
                                        >
                                          {prize.name}
                                        </List.Item>
                                      ))}
                                    </List>
                                  ) : (
                                    <Text c="red" fw={600} size="xs">
                                      No prizes for this tier!
                                    </Text>
                                  )}
                                </div>
                              </Flex>
                            </div>
                          </Flex>
                        </Card>
                      );
                    })}
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          ))}
        </Grid.Col>
      </Grid>
    </div>
  );
}

const PrizeAdd = ({ contestId, onSuccess }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Add Prize</Button>
      <Modal opened={isOpen} onClose={onClose}>
        <PrizeForm
          buttonProps={{
            fullWidth: true,
          }}
          contestId={contestId}
          onSuccess={() => {
            onClose();
            onSuccess();
          }}
        />
      </Modal>
    </div>
  );
};

const StyledImage = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 5px;
`;

const StyledPlaceholder = styled.div`
  width: 75px;
  height: 75px;
  background: var(--mantine-color-gray-4);
  border-radius: 5px;
`;

const PrizeLootItems = ({ contestId, prizeId, locationId, organizationId }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Button size="xs" variant="light" color="teal">
        Loot Items
      </Button>
    </div>
  );
};
