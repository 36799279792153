import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  Card,
  Divider,
  Group,
  Flex,
  Modal,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconCheck, IconX, IconBell } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";

import entityColor from "@util/entityColor";

const statusBtns = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
  { text: "deleted", value: 4, color: "yellow" },
].map((m) => ({
  ...m,
  key: m.value,
}));

export default function EffortStatus({
  effortId = null,
  fetchData,
  statusOnly,
  isRideChallenge,
}) {
  const [loading, setLoading] = useState(false);

  const { data } = useQuery({
    initialData: null,
    queryKey: [`efforts${effortId}Checkup`],
    queryFn: async () =>
      axios
        .post(`/efforts/${effortId}/data-grabber/`, {
          context: "effort_checkup",
        })
        .then(({ data }) => {
          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  function onStatusClick(newStatus) {
    const req = {
      effort_id: effortId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .put(`/efforts/${effortId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  if (!data) return null;

  const publishedAppConfigEmpty = !data.live_status.published_app_configuration
    ? true
    : false;

  const items = [
    { text: "active", value: data.live_status.active },
    { text: "live", value: data.live_status.is_live },
    { text: "turned on", value: data.live_status.turned_on },
    {
      text: "published configuration",
      value: data.live_status.published_configuration,
    },
    {
      text: "published app configuration",
      value: data.live_status.published_app_configuration,
    },
    { text: "campaign live", value: data.live_status.campaign_is_live },
  ]
    .filter((f) =>
      !data.campaign_id ? !["campaign live"].includes(f.text) : true
    )
    .filter((f) =>
      publishedAppConfigEmpty ? f.text !== "published app configuration" : true
    );

  const SectionDivider = ({ text }) => <Divider my="sm" label={text} />;

  if (statusOnly) {
    return (
      <Card>
        <SectionDivider text="Status" />
        <Group spacing="xs">
          {statusBtns.map((b) => (
            <Button
              key={b.key}
              onClick={() => onStatusClick(b.value)}
              color={b.color}
              size="xs"
              variant={data.status === b.value ? "filled" : "light"}
            >
              {b.text}
            </Button>
          ))}
        </Group>
      </Card>
    );
  }

  return (
    <Card>
      <SectionDivider
        text={isRideChallenge ? "Ride Challenge Checkup" : "Effort Checkup"}
      />
      <div>
        {items.map((item, i) => (
          <Flex key={i} align="center" gap="xs" mb="xs">
            <ThemeIcon
              variant="filled"
              radius="xl"
              size="sm"
              color={item.value ? "green" : "red"}
            >
              {item.value ? <IconCheck /> : <IconX />}{" "}
            </ThemeIcon>
            <Text>{item.text}</Text>
          </Flex>
        ))}
      </div>
      {/* {data.campaign_id && (
        <React.Fragment>
          <SectionDivider text="App Config" />
          <AppConfigButtons
            effortId={effortId}
            onSuccess={fetchData}
            usingEffortConfig={data.using_effort_app_config}
            showAppConfigWarning={data.show_app_config_warning}
          />
        </React.Fragment>
      )} */}
      <SectionDivider text="Status" />
      <Group spacing="xs">
        {statusBtns.map((b) => (
          <Button
            key={b.key}
            onClick={() => onStatusClick(b.value)}
            color={b.color}
            size="xs"
            variant={data.status === b.value ? "filled" : "light"}
          >
            {b.text}
          </Button>
        ))}
      </Group>
      <SectionDivider text="Config" />
      <PublishButton effortId={effortId} onSuccess={fetchData} />
      {data.has_config_to_publish && (
        <Flex mt="sm" gap="xs" align="center">
          <ThemeIcon
            // variant="filled"
            variant="light"
            radius="xl"
            mr="xs"
            size="sm"
            color="pink.4"
          >
            <IconBell />
          </ThemeIcon>
          <Text size="xs">unpublished config changes</Text>
        </Flex>
      )}
      <SectionDivider text="App Config" />
      <PublishAppConfigButton effortId={effortId} onSuccess={fetchData} />
      {data.has_app_config_to_publish && (
        <Flex mt="sm" gap="xs" align="center">
          <ThemeIcon
            // variant="filled"
            variant="light"
            radius="xl"
            mr="xs"
            size="sm"
            color="pink.4"
          >
            <IconBell />
          </ThemeIcon>
          <Text style={{ display: "flex", alignItems: "center" }} size="xs">
            unpublished app config changes
          </Text>
        </Flex>
      )}
    </Card>
  );
}

export const PublishButton = ({
  effortId,
  onSuccess,
  buttonProps = {
    size: "xs",
    color: "green",
  },
}) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);
    axios
      .put(`/efforts/${effortId}/publish-config/`, {})
      .then(() => {
        setLoading(false);
        toast.success("Published");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button {...buttonProps} loading={loading} onClick={onClick}>
      Publish Config
    </Button>
  );
};

const PublishAppConfigButton = ({ effortId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);
    axios
      .put(`/efforts/${effortId}/publish-app-config/`, {})
      .then(() => {
        setLoading(false);
        toast.success("Published");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button loading={loading} onClick={onClick} size="xs" color="green">
      Publish App Config
    </Button>
  );
};

const AppConfigButtons = ({
  usingEffortConfig,
  effortId,
  onSuccess,
  showAppConfigWarning,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  function onModalClose() {
    setShowModal(false);
  }

  function toggleConfig(confirmed = false) {
    if (showAppConfigWarning && !confirmed) return setShowModal(true);

    setLoading(true);

    axios
      .post(`/efforts/${effortId}/toggle-app-config/`, {})
      .then(() => {
        setLoading(false);
        setShowModal(false);
        toast.success("Saved!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }
  return (
    <>
      <Modal opened={showModal} onClose={onModalClose}>
        <Text align="center">
          This action will <b>turn this effort off</b>.
        </Text>
        <Text mt="sm" align="center">
          Are you sure this is what you want?
        </Text>
        <Divider mt="lg" mb="lg" />
        <Button color="red" fullWidth mb="sm" onClick={onModalClose}>
          Nope! Get me out of here!
        </Button>
        <Group justify="center">
          <Button
            variant="subtle"
            color="gray"
            size="xs"
            onClick={() => toggleConfig(true)}
            loading={loading}
          >
            I'm sure
          </Button>
        </Group>
      </Modal>
      <Group>
        <Button
          size="xs"
          onClick={() => toggleConfig()}
          variant={usingEffortConfig ? "filled" : "subtle"}
          color={usingEffortConfig ? entityColor.effort : "gray"}
        >
          Effort
        </Button>
        <Button
          size="xs"
          onClick={() => toggleConfig()}
          variant={!usingEffortConfig ? "filled" : "subtle"}
          color={!usingEffortConfig ? entityColor.campaign : "gray"}
        >
          Campaign
        </Button>
      </Group>
    </>
  );
};
