import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import {
  ActionIcon,
  Group,
  Grid,
  Flex,
  Text,
  HoverCard,
  Divider,
  Modal,
  Box,
  Select,
  List,
  Textarea,
  Button,
} from "@mantine/core";
import { IconBolt, IconTrash } from "@tabler/icons-react";

export default function MilestoneTrigger({
  info,
  id,
  fetchData,
  triggers = [],
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    message: "",
  });

  if (!info) return null;

  useEffect(() => {
    if (!isOpen && !triggers.length) {
      setFormValues({
        message: "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setFormValues({
      message: "",
    });
  }, [triggers]);

  function onClose() {
    setOpen(false);
  }

  function onSubmit() {
    setLoading(true);
    axios
      .post(`/triggers/`, {
        milestone_id: id,
        message: formValues.message,
        variety: "milestone_trigger",
      })
      .then(() => {
        setLoading(false);
        onClose();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const hasTrigger = triggers.length > 0;
  const submitDisabled =
    formValues.message.length === 0 || formValues.message.length > 140;

  return (
    <div>
      <ActionIcon
        onClick={() => setOpen(true)}
        title="Milestone Trigger Setup"
        variant="light"
        color={hasTrigger ? "green" : "gray"}
        // turn color green if there is a trigger?
        radius="xl"
        size="xs"
      >
        <IconBolt size={14} />
      </ActionIcon>
      <Modal
        title={`${info.title} - Trigger Setup`}
        opened={isOpen}
        onClose={onClose}
      >
        {hasTrigger ? (
          <>
            {triggers.map((trigger) => (
              <TriggerItem
                key={trigger.id}
                trigger={trigger}
                fetchData={() => {
                  fetchData();
                }}
              />
            ))}
          </>
        ) : (
          <>
            <Textarea
              label="When this milestone is reached, send the following text message (140
          characters max):"
              value={formValues.message}
              maxLength={140}
              rows={4}
              onChange={(e) =>
                setFormValues({ ...formValues, message: e.target.value })
              }
            />
            <Text size="xs">{formValues.message.length}/140 characters</Text>
            <Button
              loading={loading}
              fullWidth
              mt="xs"
              onClick={onSubmit}
              disabled={submitDisabled}
            >
              Submit
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
}

function TriggerItem({ trigger, fetchData }) {
  const [loading, setLoading] = useState(false);

  function onRemoveClick() {
    setLoading(true);
    axios
      .delete(`/triggers/${trigger.id}/`)
      .then(() => {
        toast.success("Deleted!");
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Text>
        When this milestone is reached, send the following text message:
      </Text>
      <Text mt="md">{trigger.message}</Text>
      <Flex mt="md" gap="xs" align="center">
        <ActionIcon
          loading={loading}
          size="xs"
          variant="light"
          radius="xl"
          color="red"
          onClick={onRemoveClick}
        >
          <IconTrash size={14} />
        </ActionIcon>
        <Text size="sm">remove trigger</Text>
      </Flex>
    </div>
  );
}
