import React, { useState, useEffect } from "react";
import { Flex, Button, Table, Pagination, Box } from "@mantine/core";
import { IconSortAscending, IconSortDescending } from "@tabler/icons-react";

import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Parser } from "@json2csv/plainjs";
import dayjs from "dayjs";

import RevBreakdown from "./RevBreakdown";
import { winnerStyles } from "./helpers";

const tableColumns = [
  {
    header: () => "Name",
    accessorKey: "full_name",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Email",
    accessorKey: "email",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Phone",
    accessorKey: "mobile_phone",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Total Miles",
    accessorKey: "total_tier_miles",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Tier Rev Score",
    accessorKey: "tier_rev_score",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Total Challenge Miles",
    accessorKey: "total_challenge_miles",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Keyword Engagements Tier Count",
    accessorKey: "keyword_engagements_tier_count",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Keyword Engagements Tier Rev",
    accessorKey: "keyword_engagements_tier_rev",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Readings Engagements Tier Count",
    accessorKey: "readings_engagements_tier_count",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Readings Engagements Tier Rev",
    accessorKey: "readings_engagements_tier_rev",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Locations Tier Count",
    accessorKey: "locations_tier_count",
    cell: (info) => info.getValue(),
  },
  {
    header: () => "Locations Tier Rev",
    accessorKey: "locations_tier_rev",
    cell: (info) => info.getValue(),
  },
].map((m) => ({
  ...m,
  id: m.accessorKey,
}));

const TierReport = ({ data, tierName, winnerUserIds = [] }) => {
  const headers = ["Name", "Mobile Phone", "Email"];
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    setTotal(data.length);
  }, [JSON.stringify(data)]);

  const table = useReactTable({
    data: data.sort((a, b) => b.tier_rev_score - a.tier_rev_score),
    columns: tableColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: false,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div style={{ paddingBottom: "20px" }}>
      {/* <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {table.getFlatHeaders().map((header) => (
              <Table.Th
                key={header.id}
                style={{
                  width: header.column.columnDef.width,
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                <div
                  {...{
                    onClick: header.column.getToggleSortingHandler(),
                    style: {
                      cursor: header.column.getCanSort() ? "pointer" : "",
                    },
                  }}
                >
                  <Group>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <IconSortAscending size={16} />,
                      desc: <IconSortDescending size={16} />,
                    }[header.column.getIsSorted()] ?? null}
                  </Group>
                </div>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!loading && table.getRowModel().rows.length === 0 && (
            <Table.Tr>
              <td colSpan={tableColumns.length}>Nothing to show</td>
            </Table.Tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Table.Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Td
                  key={cell.id}
                  style={{
                    // width: `${cell.column.columnDef.minSize}px`,
                    minWidth: "120px",
                    // maxWidth: `${cell.column.columnDef.minSize}px`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table> */}
      <div>
        <Box
          style={{
            padding: "10px",
          }}
        >
          <RevBreakdown isGuide />
        </Box>
        {table.getRowModel().rows.map((row, i) => {
          const isWinner = winnerUserIds.includes(row.original.user_id);
          return (
            <Box
              key={row.id}
              style={{
                marginBottom: "1px",
                background: isWinner
                  ? winnerStyles.background
                  : i % 2 === 0
                  ? "transparent"
                  : "var(--mantine-color-gray-0)",
                padding: "10px",
                border: isWinner ? winnerStyles.border : "none",
              }}
            >
              <RevBreakdown
                key={row.id}
                info={row.original}
                isWinner={isWinner}
              />
            </Box>
          );
        })}
      </div>
      <Flex align="center" gap="xs" mt="lg">
        <Pagination
          total={table.getPageCount()}
          onChange={(e) => table.setPageIndex(e - 1)}
          size="xs"
          disabled={loading}
          value={table.getState().pagination.pageIndex + 1}
        />
        <DownloadButton data={data} tierName={tierName} />
      </Flex>
    </div>
  );
};

export default TierReport;

const DownloadButton = ({ data, tierName }) => {
  function downloadData() {
    const today = dayjs().format("YYYY-MM-DDTHH:mm:ssZ[Z]");
    const opts = {
      fields: tableColumns.map((m) => ({
        label: m.header(),
        value: m.accessorKey,
      })),
    };
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${today}-${tierName
      .toLowerCase()
      .replaceAll(" ", "_")}-data.csv`;
    link.click();
  }
  return (
    <Button size="xs" onClick={downloadData}>
      Download CSV
    </Button>
  );
};
