import { create } from "zustand";

import { initialState } from "./helpers";

const useAppStore = create((set) => ({
  settings: { ...initialState },
  setSettings: (settings) =>
    set((state) => ({
      settings: {
        ...state.settings,
        ...settings,
      },
    })),
}));

export default useAppStore;
