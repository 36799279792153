import React, { useEffect } from "react";
import { Card, Tabs } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { shallow } from "zustand/shallow";

import Keywords from "./Keywords";
import Checkins from "./Checkins";
import GeneralSettings from "./GeneralSettings";
import InitialEntry from "./InitialEntry";
import WinnerRules from "./WinnerRules";

import useAppStore from "./effort-point-settings-store";

import { effortRecurringEngagement } from "@components/Effort/helpers";

const tabDefs = {
  general: "general",
  keywords: "keywords",
  initial_entry: "initial entry",
  check_ins: "check-ins",
  winner_rules: "winner rules",
};

export default function EffortPointSettings({
  beginDate,
  canEditWinnerRules,
  effortId,
  endDate,
  fetchEffort,
  pointsOnly,
  recurringEngagement,
  wizardSettings,
}) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/efforts/${effortId}/point-settings/`)
      .then(({ data }) => {
        setSettings({
          ...settings,
          ...data.response[0].settings,
          contestDates: {
            begin: beginDate,
            end: endDate,
          },
        });
      })
      .catch((err) => {
        // setSettings({});
      });
  }

  function updateSettings(newSettings) {
    const req = { campaign_effort_id: effortId, settings: newSettings };

    axios
      .post(`/efforts/${effortId}/point-settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchEffort())
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUpdate(newSettings) {
    setSettings({ ...settings, ...newSettings });
    updateSettings({ ...settings, ...newSettings });
  }

  if (pointsOnly) {
    return (
      <Card>
        <GeneralSettings
          onUpdate={(e) => onUpdate({ ...e })}
          pointsOnly={pointsOnly}
          wizardSettings={wizardSettings}
        />
      </Card>
    );
  }

  return (
    <Card>
      <Tabs
        onChange={(e) => onUpdate({ activeTab: e })}
        value={settings.activeTab}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.initial_entry}>Initial Entry</Tabs.Tab>
          {recurringEngagement === effortRecurringEngagement.true && (
            <React.Fragment>
              <Tabs.Tab value={tabDefs.keywords}>Keywords</Tabs.Tab>
              <Tabs.Tab value={tabDefs.check_ins}>Check-Ins</Tabs.Tab>
            </React.Fragment>
          )}
          <Tabs.Tab value={tabDefs.winner_rules}>Winner Rules</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.initial_entry}>
          <InitialEntry
            onUpdate={(e) =>
              onUpdate({ initial_entry: { ...settings.initial_entry, ...e } })
            }
          />
        </Tabs.Panel>
        {recurringEngagement === effortRecurringEngagement.true && (
          <React.Fragment>
            <Tabs.Panel value={tabDefs.check_ins}>
              <Checkins
                onUpdate={(e) =>
                  onUpdate({ check_ins: { ...settings.check_ins, ...e } })
                }
              />
            </Tabs.Panel>
            <Tabs.Panel value={tabDefs.keywords}>
              <Keywords
                onUpdate={(e) =>
                  onUpdate({ keywords: { ...settings.keywords, ...e } })
                }
              />
            </Tabs.Panel>
          </React.Fragment>
        )}
        <Tabs.Panel value={tabDefs.winner_rules}>
          <WinnerRules
            canEditWinnerRules={canEditWinnerRules}
            onUpdate={(e) =>
              onUpdate({ winner_rules: { ...settings.winner_rules, ...e } })
            }
          />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
}
