import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";
import styled from "styled-components";

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
`;

export default function IframeModal({ src, buttonText, buttonProps = {} }) {
  const [opened, setOpened] = useState(false);

  function onClose() {
    setOpened(false);
  }

  return (
    <div>
      <Button {...buttonProps} onClick={() => setOpened(true)}>
        {buttonText}
      </Button>
      <Modal
        withCloseButton={false}
        size="lg"
        onClose={onClose}
        opened={opened}
      >
        <StyledIframe src={src} />
      </Modal>
    </div>
  );
}
