import React, { useEffect } from "react";
import styled from "styled-components";
import { Group, PinInput } from "@mantine/core";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  height: 50px;
  width: 50px;
  font-size: 35px;
  font-weight: bold;
  font-family: inherit;
  padding: 10px;
  appearance: none;
  text-align: center;
  margin: 0;
  border: none;
  /* border: 2px solid var(--grey100); */
  border-radius: 2px;
  /* color: var(--textColor500); */
  outline: none;
  -moz-appearance: textfield;

  & + & {
    margin-left: 4px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const inputs = [0, 1, 2, 3, 4, 5];

export default function CodeInput({ value, onChange }) {
  return (
    <Group align="center" justify="center">
      <PinInput
        length={6}
        placeholder="-"
        size="sm"
        type="number"
        inputType="tel"
        value={value}
        autoFocus
        onChange={(e) => onChange(e)}
      />
    </Group>
  );

  useEffect(() => {
    if (!value.length && document.getElementById(`input${inputs[0]}`)) {
      document.getElementById(`input${inputs[0]}`).focus();
    }
  }, [value]);

  function onInputChange(val, idx) {
    let valArray = Array.from(value);
    valArray[idx] = val;
    onChange(valArray.join(""));

    const nextInput = document.getElementById(`input${idx + 1}`);

    if (nextInput) {
      nextInput.focus();
    }
  }

  return (
    <Wrapper>
      {inputs.map((input) => (
        <Input
          key={input}
          type="number"
          maxLength={1}
          id={`input${input}`}
          value={value[input] || ""}
          onChange={(e) => {
            onInputChange(e.target.value, input);
          }}
        />
      ))}
    </Wrapper>
  );
}
