import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  Button,
  Group,
  Center,
  MantineProvider,
  Text,
  Modal,
  Flex,
  useMantineTheme,
} from "@mantine/core";

import IntroModal from "@components/RideChallengeApp/IntroModal";
import { UserLockModal } from "@components/User";
import { TicketEntryForm } from "@components/Ticket";
import MetaPixel from "@util/metaPixel";
import { size } from "@util/breakpoints";
import RideChallenge from "@components/RideChallengeApp";

import { useTranslation } from "react-i18next";

import "@components/Microsite/locales";

export default function RideChallengeContainer({ preview }) {
  const [campaignId, setCampaignId] = useState(null);
  const [effortId, setEffortId] = useState(null);
  const [error, setError] = useState(null);
  const [entityInfo, setEntityInfo] = useState(null);
  const [appConfig, setAppConfig] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);

  const theme = useMantineTheme();

  const params = useParams();
  const { rideChallengeId } = params;

  const { t, i18n } = useTranslation();

  axios.interceptors.response.use(function (response) {
    const res = response.data;
    if (res.response_code === 3) {
      setModalInfo(res.response[0]);
      return response;
    } else {
      return response;
    }
  });

  useEffect(() => {
    fetchAppData();
  }, []);

  function fetchAppData() {
    const req = {
      code: rideChallengeId,
    };

    axios
      .post(`/ride-challenge/retrieve-by-url/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        setCampaignId(res.campaign_id);
        setEffortId(res.campaign_effort_id);
        setEntityInfo(res);
        setAppConfig({
          ...res.app_configuration,
          colors: {
            ...res.app_configuration.colors,
            icon: res.app_configuration?.colors?.icon || `#fff`,
          },
        });
      })
      .catch((err) => {
        setCampaignId(null);
        setEffortId(null);
        setError(err);
      });
  }

  if (error)
    return (
      <Center style={{ height: 500 }}>
        <Text>{error}</Text>
      </Center>
    );

  if (!entityInfo) return <div></div>;

  const colors = appConfig && appConfig.colors ? appConfig.colors : null;

  return (
    <Layout>
      <MantineProvider
        theme={{
          fontFamily: theme.fontFamily,
          fontFamilyMonospace: theme.fontFamilyMonospace,
          headings: {
            ...theme.headings,
            fontFamily: theme.fontFamily,
          },
          primaryColor: colors && colors.base ? colors.base : "orange",
        }}
      >
        {/* <UserLockModal data={modalInfo} /> */}
        <RideChallenge
          appConfigData={appConfig}
          campaignId={campaignId}
          effortId={effortId}
          entityInfoData={entityInfo}
          preview={preview}
        />
      </MantineProvider>
      <Flex gap="xs" mt="lg" justify="center">
        <IntroModal info={entityInfo} />
        {!preview && (
          <Group justify="center">
            <TroubleModal
              buttonText={t("trouble_ticket_button")}
              effortId={effortId}
              campaignId={campaignId}
            />
          </Group>
        )}
      </Flex>
    </Layout>
  );
}

const Layout = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 60px 0 20px 0;
  word-wrap: break-word;
  overflow-x: hidden;

  label {
    color: whitesmoke;
  }

  @media (max-width: ${size.tablet}) {
    width: 97%;
    padding-top: 10px;
  }
`;

export const TroubleModal = ({ buttonText, effortId, campaignId }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  const reqData = {
    url: window.location.href,
  };

  if (effortId) reqData.campaign_effort_id = effortId;
  if (campaignId) reqData.campaign_id = campaignId;

  return (
    <div>
      <Modal opened={isOpen} onClose={onClose}>
        <TicketEntryForm reqData={reqData} />
      </Modal>
      <Button
        size="xs"
        variant="filled"
        color="dark"
        radius="lg"
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </Button>
    </div>
  );
};
