export const rideChallengePath = `${
  import.meta.env.VITE_DOMAIN
}/ride-challenge-entry`;

export const rideChallengeSettings = {
  rev_score: {
    keywords_engagement_multiplier: 1,
    readings_engagement_multiplier: 1,
    locations_engagement_multiplier: 1,
  },
  ride_challenge_warnings: {
    max_daily_mileage: 800,
    vin_user_limit: 2,
  },
  ride_challenge_text: {
    keyword_claim_message: "",
  },
  ride_challenge_settings: {
    daily_mileage_limit: 800,
  },
};

export const winnerStyles = {
  background: "var(--mantine-color-yellow-0)",
  border: "1px solid var(--mantine-color-yellow-6)",
};
